import React, { CSSProperties } from "react";
import { useCss, k, a } from "kremling";

type Props = {
  className?: string;
  style?: CSSProperties;
  progress?: number; // any floating point from 0 to 1
};

export function ProgressBar(props: Props) {
  const { progress, className, style } = props;
  const scope = useCss(css);
  return (
    <div {...scope} className={a("progress-bar", className)} style={style}>
      <div style={{ width: `${progress * 100}%` }} />
    </div>
  );
}

const css = k`
  .progress-bar {
    width: 100%;
    height: .6rem;
    background-color: $grey-500;
    border-radius: .6rem;
    position: relative;
    overflow: hidden;

    div {
      height: .6rem;
      border-radius: .6rem;
      position: absolute;
      left: 0;
      background-color: $violet;
    }
  }
`;
