import { Options } from "qr-code-styling";

export function qrCodeOptions(qrCodeType: QrCodeTypes) {
  switch (qrCodeType) {
    case "black":
      return black;
    case "gradient":
      return gradient;
    case "colorLogo":
      return colorLogo;
  }
}

const baseOpts: Options = {
  type: "svg",
  width: 500,
  height: 500,
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 8,
  },
};

const black: Options = {
  ...baseOpts,
  image: window.window.showExtra ? "/imgs/bwlogo.svg" : undefined,
  dotsOptions: {
    color: "#000000",
    type: "rounded",
    gradient: null,
  },
  cornersSquareOptions: {
    color: "#000000",
    type: "extra-rounded",
    gradient: null,
  },
};

const gradient: Options = {
  ...baseOpts,
  image: "/imgs/logo.svg",
  dotsOptions: {
    color: "#000000",
    type: "rounded",
    gradient: {
      type: "linear",
      rotation: 45,
      colorStops: [
        { offset: 0, color: "#0014CB" },
        { offset: 1, color: "#FF0ED6" },
      ],
    },
  },
  cornersSquareOptions: {
    type: "extra-rounded",
    color: "#0014CB",
  },
};

const colorLogo: Options = {
  ...baseOpts,
  image: "/imgs/logo.svg",
  dotsOptions: {
    color: "#000000",
    type: "rounded",
    gradient: null,
  },
  cornersSquareOptions: {
    color: "#000000",
    type: "extra-rounded",
    gradient: null,
  },
};
