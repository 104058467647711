import React, { forwardRef } from "react";
import { useCss, k, a } from "kremling";

export type ToggleProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  activeBackgroundColor?: "primary" | "danger";
};

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(function Toggle(
  props,
  ref
) {
  const {
    checked = false,
    onChange,
    activeBackgroundColor = "primary",
  } = props;
  const scope = useCss(css);
  return (
    <div {...scope} className="toggle-container">
      <div
        className={a("toggle", `toggle--${activeBackgroundColor}`).m(
          "checked",
          checked
        )}
      />
      <label>
        <input
          ref={ref}
          type="checkbox"
          onChange={(e) => onChange(e.target.checked)}
          checked={checked}
        />
      </label>
    </div>
  );
});

const css = k`
  .toggle-container {
    position: relative;
    border-radius: 100vh;
    width: 58px;
    height: 32px;
    background-color: #efeff3;

    label {
      position: absolute;
      top: 0;
      left: 0;
      height: 32px;
      width: 58px;
      z-index: 100;
      border-radius: 100vh;
      opacity: 0;
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }
    
    @include focus-ring-within {
      border-radius: $border-radius-full;
      inset: -.4rem;
    }

    .toggle {
      position: relative;
      width: 58px;
      height: 32px;
      border-radius: 100vh;
      background-color: #efeff3;
      cursor: pointer;
      transition: background-color 200ms ease;

      &::after {
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        width: 26px;
        height: 26px;
        border-radius: 100vh;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 5px rgba(45, 0, 221, 0.06);
        transform: translateX(0);
        transition: transform 200ms ease;
      }

      &.checked {
        &.toggle--primary {
          background-color: $violet;
        }
        &.toggle--danger {
          background-color: $vermillion;
        }

        &::after {
          border-color: transparent;
          transform: translateX(26px);
        }
      }
    }
  }
`;
