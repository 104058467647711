import React, { ReactNode } from "react";

import { Icon } from "../icon/icon";
import { useCss, a, k } from "kremling";
import { MenuComponent, MenuComponentProps } from "./menu-component";

type MenuItemLinkProps = MenuComponentProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    label: string | ReactNode;
    iconLeft?: Icons | ReactNode;
    active?: boolean;
    block?: boolean;
    disabled?: boolean;
  };

export const MenuItemLink = React.forwardRef<
  HTMLAnchorElement,
  MenuItemLinkProps
>(({ label, iconLeft, active, block, ...props }, ref) => {
  const scope = useCss(css);
  const renderItem = ({ isSubMenu, ...refProps }: any) => (
    <a
      {...scope}
      {...props}
      {...refProps}
      role="menuitem"
      disabled={props.disabled}
      className={a("menu-item")
        .m("--disabled", props.disabled)
        .m("--block", block)}
    >
      <div className="menu-item__inner">
        {!!iconLeft && (
          <div className="menu-item__icon">
            {typeof iconLeft === "string" && (
              <Icon name={iconLeft as Icons} className="menu-item-icon-left" />
            )}
            {typeof iconLeft !== "string" && iconLeft}
          </div>
        )}
        {label}
      </div>
      {active && <Icon name="circle-check-solid" className="sub-menu-icon" />}
      {isSubMenu && (
        <Icon className="sub-menu-icon" name="angle-right-solid" size={14} />
      )}
    </a>
  );

  if (props.children) {
    return (
      <MenuComponent
        {...(props as any)}
        ref={ref as any}
        renderItem={renderItem}
      />
    );
  }
  return renderItem({ ref, isSubMenu: false });
});

MenuItemLink.displayName = "MenuItemLink";

const css = k`
  .menu-item {
    text-decoration: none;
    
    &.--disabled {
      color: var(--button-tertiary-disabled-color);
      background: var(--button-tertiary-disabled-bg);
    }
  }
`;
