import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { BackgroundCard } from "../../components/background-card";
import { InputField } from "../../common/input/input-field";
import { Button } from "../../common/button/button";
import { useNavigate } from "react-router-dom";
import { authTypeKey, emailKey, sessionEmailKey } from "./auth-utils";
import { appState } from "../../app-state";
import { checkUsername } from "../../services/api";
import { ShareBanner } from "../../components/share-banner";
import { useShareSearchParams } from "../../hooks/use-share-search-params";
import { getAuthType } from "../../utils/validation";

export function LoginOrSignup() {
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      emailOrPhoneNumber: localStorage.getItem(emailKey) || "",
    },
  });
  const navigate = useNavigate();
  const { shareParamsStr } = useShareSearchParams();

  const onSubmit = async ({
    emailOrPhoneNumber,
  }: {
    emailOrPhoneNumber: string;
  }) => {
    setIsLoading(true);
    localStorage.setItem(emailKey, emailOrPhoneNumber);
    localStorage.setItem(authTypeKey, getAuthType(emailOrPhoneNumber));
    sessionStorage.setItem(sessionEmailKey, emailOrPhoneNumber);
    appState.set({ email: emailOrPhoneNumber });

    try {
      const { code, status, verified } = await checkUsername(
        emailOrPhoneNumber
      );
      if (
        (code === "UserExists" || code === "Conflict") &&
        status === "FORCE_CHANGE_PASSWORD"
      ) {
        appState.set({ temporaryOldAuth: true });
        navigate(`/sign-up/confirm-email${shareParamsStr}`);
        return;
      }
      if ((code === "UserExists" || code === "Conflict") && verified) {
        navigate(`/login${shareParamsStr}`);
        return;
      } else {
        navigate(`/sign-up/confirm-email${shareParamsStr}`);
        return;
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        navigate(`/create-new-account${shareParamsStr}`);
      }
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <BackgroundCard showLogoMobile>
        <div className="text-title text-center mb-16">Log in or Sign up</div>
        <ShareBanner />
        <div className="mb-24">
          <InputField
            required
            control={control}
            fieldName="emailOrPhoneNumber"
            label="Email or Phone Number"
            placeholder="Email or Phone Number"
            type="emailOrPhoneNumber"
            autoFocus
            scale="lg"
          />
        </div>

        <Button
          block
          intent="primary"
          type="submit"
          isLoading={isLoading}
          size="lg"
        >
          Continue
        </Button>
      </BackgroundCard>
    </form>
  );
}
