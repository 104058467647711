import React, { ReactNode } from "react";
import { useCss, k } from "kremling";
import { Button } from "../common/button/button";

type Props = {
  children?: ReactNode;
  backCallback?: () => void;
  disableBackCallback?: boolean;
};

export function AuthTitle(props: Props) {
  const { children, backCallback, disableBackCallback } = props;
  const scope = useCss(css);
  return (
    <div {...scope} className="text-title text-center mb-20 relative">
      {children}
      {!!backCallback && (
        <div className="title-back-btn">
          <Button
            disabled={disableBackCallback}
            iconOnly="angle-left-solid"
            size="sm"
            onClick={backCallback}
          />
        </div>
      )}
    </div>
  );
}

const css = k`
  .title-back-btn {
    position: absolute;
    left: -1.2rem;
    top: -.2rem;
  }
`;
