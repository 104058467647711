import { useEffect, useRef, useState } from "react";

export function useBodyScrollBoolean(scrollElement?: HTMLElement) {
  const scrollTopRef = useRef(0);
  const [show, setShow] = useState(true);

  useEffect(() => {
    const el = scrollElement || document.body;
    el.addEventListener("scroll", handleScroll);
    el.addEventListener("touchmove", handleScroll);
    return () => {
      el.removeEventListener("scroll", handleScroll);
      el.removeEventListener("touchmove", handleScroll);
    };
  }, [scrollElement]);

  const handleScroll = (e: any) => {
    const body = e.target;
    const scrollTop = body.scrollTop;
    if (scrollTop === 0) return;
    const lastScrollTop = scrollTopRef.current;
    if (lastScrollTop < scrollTop) {
      setShow(false);
    } else if (lastScrollTop > scrollTop) {
      setShow(true);
    }
    scrollTopRef.current = scrollTop <= 0 ? 0 : scrollTop;
  };
  return show;
}
