import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "../../common/button/button";
import { checkApiResponse } from "../../utils/utils";
import {
  continueSignUpUser,
  resendAccountVerification,
} from "../../services/api";
import { BackgroundCard } from "../../components/background-card";
import { useForm } from "react-hook-form";
import { InputField } from "../../common/input/input-field";
import { appState, useAppState } from "../../app-state";
import { AuthTitle } from "../../components/auth-title";
import { useShareSearchParams } from "../../hooks/use-share-search-params";
import { ShareBanner } from "../../components/share-banner";
import toast from "react-hot-toast";
import { getAuthText } from "../../utils/validation";
import { authTypeKey, sessionEmailKey } from "./auth-utils";
import { loginUtil } from "../../utils/login-util";

export const ConfirmEmail = () => {
  const navigate = useNavigate();
  const { email: savedEmail } = useAppState(["email"]);
  const [topError, setTopError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { shareParamsStr } = useShareSearchParams();
  const authTypeRef = useRef(localStorage.getItem(authTypeKey));

  const { handleSubmit, control } = useForm({
    defaultValues: { verificationCode: "", emailOrPhoneNumber: "" },
  });

  const submit = async ({ verificationCode, emailOrPhoneNumber }) => {
    setTopError();

    setIsLoading(true);
    const myEmail = savedEmail || emailOrPhoneNumber;
    const myPassword = appState.get("temporaryAuth");
    try {
      await continueSignUpUser(myEmail, verificationCode.trim(), true);
      if (myPassword) {
        await loginUtil(myEmail, myPassword);
        navigate(`/sign-up/finish-account${shareParamsStr}`);
      } else {
        sessionStorage.setItem(sessionEmailKey, myEmail);
        toast.success("Verification successful!");
        navigate(`/login${shareParamsStr}`);
      }
    } catch (e) {
      setTopError(checkApiResponse(JSON.parse(e?.request?.response).code));
      setIsLoading(false);
    }
  };

  const resendVerificationCode = async () => {
    await resendAccountVerification(savedEmail);
    toast("Successfully resent verification code");
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <input
        role="presentation"
        autoComplete="off"
        style={{ display: "none" }}
      />
      <BackgroundCard>
        <AuthTitle
          backCallback={() => navigate(`/create-new-account${shareParamsStr}`)}
          disableBackCallback={isLoading}
        >
          {savedEmail
            ? `Confirm ${getAuthText(authTypeRef.current)}`
            : `Account Verification`}
        </AuthTitle>
        <ShareBanner />
        <div className="mb-24">
          {!!topError ? (
            <div className="text-error">{topError}</div>
          ) : (
            <div className="text-secondary">
              Enter the verification code sent to your{" "}
              {!!savedEmail
                ? `${getAuthText(authTypeRef.current)} ${
                    !!savedEmail && ", " + savedEmail
                  }`
                : `email or phone number`}
              .
            </div>
          )}
        </div>

        {!savedEmail && (
          <div className="mb-16">
            <InputField
              required
              control={control}
              fieldName="emailOrPhoneNumber"
              label="Email or Phone Number"
              placeholder="Email or Phone Number"
              type="emailOrPhoneNumber"
              autoFocus
              scale="lg"
            />
          </div>
        )}

        <div className="mb-24">
          <InputField
            autoComplete="new-password"
            label="Verification Code"
            placeholder="Verification Code"
            control={control}
            fieldName="verificationCode"
            type="password"
            seePassword
            required
            autoFocus={savedEmail}
            scale="lg"
          />
        </div>

        <div className="button-group">
          <Button
            as={Link}
            to={`/login-or-signup${shareParamsStr}`}
            intent="tertiary"
            block
            size="lg"
          >
            Cancel
          </Button>
          <Button
            intent="primary"
            block
            isLoading={isLoading}
            type="submit"
            size="lg"
          >
            Continue
          </Button>
        </div>
        <div className="pt-16 text-center font-medium">
          <a onClick={resendVerificationCode}>Resend verification code</a>
        </div>
      </BackgroundCard>
    </form>
  );
};
