import React, { useMemo } from "react";
import { useCss, k } from "kremling";
import { useLocation } from "react-router-dom";
import { useGetCollectionQuery } from "../queries";
import { Icon } from "../common/icon/icon";

export function ShareBanner() {
  const scope = useCss(css);

  const location = useLocation();

  const share = useMemo(() => {
    const search = new URLSearchParams(location.search);
    return {
      collectionId: search.get("a"),
      authorization: search.get("b"),
      referrerUserId: search.get("c"),
    };
  }, [location.search]);

  const collectionQuery = useGetCollectionQuery(
    share.collectionId,
    share.authorization
  );

  if (
    !collectionQuery.data ||
    !share.collectionId ||
    !share.authorization ||
    !share.referrerUserId
  ) {
    return null;
  }

  return (
    <div {...scope} className="share-banner">
      <Icon name="party-horn-regular" size={32} />
      <div>
        <div>You've been invited to the PicMe Collection:</div>
        <div className="collection-name">{collectionQuery.data?.title}</div>
        <div>Please log in or sign up to accept.</div>
      </div>
    </div>
  );
}

const css = k`
  .share-banner {
    border: solid .1rem $violet-50;
    border-radius: $border-radius-md;
    padding: 1.6rem;
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
    box-shadow: $shadow-xs;
    background-color: $violet-25;

    svg {
      margin: 1.6rem 2.4rem 1.6rem .8rem;
      flex-shrink: 0;
    }
  }

  .collection-name {
    font-size: 1.6rem;
    font-weight: 700;
  }
`;
