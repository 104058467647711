import { SpringOptions } from "framer-motion";
import { SlideType } from "./photo-viewer-types";

// spring opts
export let springOpts: SpringOptions = {
  stiffness: 1500,
  damping: 110,
  mass: 1,
};

export function getMaxImageDimensions(image: HTMLImageElement) {
  return {
    maxWidth: `min(${image.naturalWidth}, 100%)`,
    maxHeight: `min(${image.naturalHeight}, 100%)`,
  };
}

export function ratio(width: number, height: number): number {
  return width / height;
}

export function calcX(nextX: number, imgWidth: number): number {
  const winWidth = window.innerWidth;
  if (winWidth > imgWidth) return 0;

  const win = window.innerWidth / 2;
  const box = imgWidth / 2;

  const maxWest = box - win;
  const maxEast = win - box;

  if (nextX >= maxWest) return maxWest;
  if (nextX < maxEast) return maxEast;
  return nextX;
}

export function calcY(nextY: number, imgHeight: number): number {
  const winHeight = window.innerHeight;
  if (winHeight > imgHeight) return 0;

  const win = winHeight / 2;
  const box = imgHeight / 2;
  const maxSouth = box - win;
  const maxNorth = win - box;

  if (nextY >= maxSouth) return maxSouth;
  if (nextY < maxNorth) return maxNorth;
  return nextY;
}

/**
 * Get page X
 * @param relativeIndex {number} (-1, +1)
 */
export function getPageX(relativeIndex: number) {
  const gap = relativeIndex < 0 ? -100 : 100;
  return window.innerWidth * relativeIndex + (relativeIndex === 0 ? 0 : gap);
}

export function getNextIndex(
  index: number,
  indexChange: number,
  arrLength: number
): number {
  return (index + indexChange + arrLength) % arrLength;
}

export function getSurroundingIndexes(
  index: number,
  array: SlideType[],
  amount: number
) {
  const n = array.length; // get the length of the array
  let result: any[] = [];

  // If the array has less than 3 items, or if the surrounding items would result in more items than the array length, return the array itself.
  // if (array.length < amount * 2 + 1) return array;

  // For each surrounding item, add the element at the corresponding index to the result array.
  // Start the loop from -surroundingItems and end it at surroundingItems (both inclusive).
  for (let i = -amount; i <= amount; i++) {
    result.push(array[(index + i + n) % n]);
  }
  return result;
}
