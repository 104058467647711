import React, { ReactNode, CSSProperties } from "react";
import { useCss, k } from "kremling";

import { OverlayPage } from "../../components/overlay-page/overlay-page";
import { Button } from "../../common/button/button";
import { modalService } from "../../common/modal-service/modal-service";
import { DeleteAccountModal } from "../../components/delete-account-modal";
import { ChangePasswordModal } from "../../components/change-password-modal";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
};

export function AccountPasswordAndSecurity(props: Props) {
  const {} = props;
  const scope = useCss(css);
  const deleteAccount = async () => {
    const data = await modalService.render(DeleteAccountModal);
  };
  const handleChangePassword = () => {
    modalService.render(ChangePasswordModal, {}, { allowBackdropClose: false });
  };

  return (
    <div {...scope} className="account-password-and-security">
      <OverlayPage title="Password & Security">
        <div className="mb-36">
          <label className="text-label mb-8">Password</label>
          <Button block intent="tertiary" onClick={handleChangePassword}>
            Change password
          </Button>
        </div>

        <div className="mb-36">
          <label className="text-label mb-8">Delete Account</label>
          <Button block intent="tertiary" onClick={deleteAccount}>
            Delete Account
          </Button>
        </div>
      </OverlayPage>
    </div>
  );
}

const css = k`
  .account-password-and-security {
    max-width: 55rem;
    margin: 0 auto 3.2rem auto;
  }
`;
