import React, { useState } from "react";
import { Dropdown } from "../../common/dropdown/dropdown";
import { CollectionCoverBtn } from "../collection-cover/collection-cover-btn";
import { CollectionMenu } from "../../pages/collection/components/collection-menu";
import { useMediaQuery } from "../../hooks/use-media-query";
import { AnimatePresence, m } from "framer-motion";
import { createPortal } from "react-dom";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { useNoBodyScroll } from "../../hooks/use-no-body-scroll";

type Props = {
  collectionId: string;
  coverIcon?: string;
  coverColor?: string;
};

const parentVariants = {
  initial: {
    translateY: 68,
    opacity: 0,
  },
  animate: {
    translateY: 0,
    opacity: 1,
  },
  exit: { translateY: 68, opacity: 0 },
};

const transition = {
  duration: 0.4,
  ease: "easeInOut",
};

export function AppHeaderMenu(props: Props) {
  const { collectionId, coverColor, coverIcon } = props;
  useCssModules(css);
  const [showMenu, setShowMenu] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  useNoBodyScroll(!!showMenu);

  return (
    <>
      {isDesktop ? (
        <Dropdown
          allowContentClicks
          contentWidth={376}
          cover
          renderTrigger={({ toggle }) => (
            <CollectionCoverBtn
              onClick={toggle}
              collectionId={collectionId}
              coverIcon={coverIcon}
              coverColor={coverColor}
            />
          )}
          renderContent={({ close }) => (
            <CollectionMenu
              onChange={() => close()}
              bodyMaxHeight="calc(100vh - 18rem)"
            />
          )}
        />
      ) : (
        <CollectionCoverBtn
          onClick={() => setShowMenu(true)}
          collectionId={collectionId}
          coverIcon={coverIcon}
          coverColor={coverColor}
        />
      )}

      {!isDesktop &&
        createPortal(
          <AnimatePresence initial={false}>
            {showMenu && (
              <m.div
                className={s.collectionMenuContainer}
                variants={parentVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={transition}
              >
                <CollectionMenu
                  onChange={() => setShowMenu(false)}
                  showCloseBtn
                />
              </m.div>
            )}
          </AnimatePresence>,
          document.body
        )}
    </>
  );
}

const { s, css } = cssModules`
  .collectionMenuContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 800;
    overflow: hidden;
    background-color: $grey-100;
  }
  
  .collectionHamburgerBtn {
    border: none;
    margin: 0;
    border-radius: .2rem;
    background-color: transparent;
    vertical-align: middle;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: .8rem;
    position: relative;

    @include focus-ring {
      border-radius: .4rem;
    }

    svg {
      height: 2rem;
    }
  }
`;
