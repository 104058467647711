import React, { CSSProperties } from "react";
import { useCss, k, a } from "kremling";
import { Loader } from "../../common/loader/loader";
import { Icon } from "../../common/icon/icon";

type Props = {
  className?: string;
  style?: CSSProperties;
  saving?: boolean;
  loading?: boolean;
};

export function Saving(props: Props) {
  const { className, saving, style, loading } = props;
  const scope = useCss(css);
  return (
    <div
      {...scope}
      className={a("save", className).m("saving", saving || loading)}
      style={style}
    >
      <div>
        {saving || loading ? (
          <>
            <Loader size="xs" className="save-loader" />{" "}
            {loading ? "Loading" : "Saving"}
          </>
        ) : (
          <>
            <Icon name="circle-check-solid" fill="green" /> Saved
          </>
        )}
      </div>
    </div>
  );
}

const css = k`
  .save {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .8rem;
    cursor: default;
    user-select: none;

    > div {
      display: flex;
      gap: .8rem;
      align-items: center;
      justify-content: center;
      border-radius: .8rem;
      padding: .8rem 1.2rem;
      border: solid .2rem $grey-300;
      font-weight: 700;
      width: 9rem;
      overflow: hidden;
      transition: width 200ms ease;
    }

    &.saving > div {
      width: 11rem;
    }
  }
`;
