import React, { ReactNode, useState } from "react";

import { ModalHeader } from "../modal/modal-header";
import { Button } from "../button/button";
import { modalService } from "../modal-service/modal-service";
import { ModalBody } from "../modal/modal-body";
import { ModalFooter } from "../modal/modal-footer";

type DialogOpts = {
  title: string | ReactNode;
  description?: string | ReactNode;
  cancelText?: string;
  submitText?: string;
  onSubmitCallback?: () => Promise<any>;
};

export function dialog(dialogOpts: DialogOpts) {
  return modalService.render(DialogModal, dialogOpts);
}

type Props = DialogOpts & {
  modalResolve: (data?: any) => void;
};

function DialogModal(props: Props) {
  const {
    title,
    description,
    modalResolve,
    cancelText,
    submitText,
    onSubmitCallback,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (onSubmitCallback) {
      setIsLoading(true);
      modalService.disableModalExit(true);
      modalResolve(await onSubmitCallback());
    } else {
      modalResolve(true);
    }
  };

  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {typeof description === "string" ? (
          <div>{description}</div>
        ) : (
          description
        )}
      </ModalBody>
      <ModalFooter>
        <div className="button-group">
          <Button
            block
            intent="tertiary"
            onClick={() => modalResolve(false)}
            disabled={isLoading}
          >
            {cancelText || "Cancel"}
          </Button>
          <Button
            block
            intent="primary"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            {submitText || "Submit"}
          </Button>
        </div>
      </ModalFooter>
    </>
  );
}
