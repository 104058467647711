import { useEffect, useState } from "react";
import { collectionIdKey } from "../pages/auth/auth-utils";
import { useCollectionListQuery } from "../queries";

export function useGoBack() {
  const [collectionId, setCollectionId] = useState<string>();
  const [savedCollectionId] = useState(() =>
    localStorage.getItem(collectionIdKey)
  );
  const { data } = useCollectionListQuery();

  useEffect(() => {
    if (data) {
      let nextCollectionId;
      let first;
      for (let i = 0; i < data.length; i++) {
        if (i === 0) first = data[i].id;
        if (data[i].id === savedCollectionId) {
          nextCollectionId = savedCollectionId;
        }
      }
      if (!nextCollectionId) {
        nextCollectionId = first;
      }
      setCollectionId(nextCollectionId);
    }
  }, [data]);

  return collectionId ? `/collection/${collectionId}` : `/collection`;
}
