import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import toast, { Toaster } from "react-hot-toast";

import { ModalServiceOutput } from "./common/modal-service/modal-service-output";
import { AppRoutes } from "./app-routes";
import { queryClient } from "./query-client";
import { appState, useAppState } from "./app-state";
import { AppLoader } from "./components/app-loader/app-loader";
import { AddToHomeScreenToast } from "./components/add-to-home-screen-toast";

function ScrollToTop(): null {
  const location = useLocation();
  useLayoutEffect(() => {
    document.body.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}

export function Root() {
  useEffect(() => {
    if (appState.get("outage")) {
      toast.error(
        "Our service is temporarily unavailable. Please try again later.",
        { duration: Infinity }
      );
    }
  }, []);

  const { toastBottomOffset } = useAppState(["toastBottomOffset"]);

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AppLoader>
          <BrowserRouter>
            <ScrollToTop />
            <AppRoutes />
            <ModalServiceOutput />
            <Toaster
              position="bottom-right"
              containerStyle={{ bottom: toastBottomOffset }}
              toastOptions={{
                duration: 5000,
                position: "bottom-center",
                style: {
                  backgroundColor: "#1E1E24",
                  color: "#ffffff",
                  borderRadius: "1.2rem",
                  fontSize: "1.4rem",
                },
              }}
            />
            {/*<AddToHomeScreenToast />*/}
          </BrowserRouter>
        </AppLoader>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
