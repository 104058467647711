import React from "react";
import { useCss, k } from "kremling";
import { ModalHeader } from "../common/modal/modal-header";
import { useForm } from "react-hook-form";
import { InputField } from "../common/input/input-field";
import { Button } from "../common/button/button";
import { ModalBody } from "../common/modal/modal-body";
import { ModalFooter } from "../common/modal/modal-footer";
import { deleteUser } from "../services/api";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

type Props = {
  modalResolve?: () => any;
};

export function DeleteAccountModal(props: Props) {
  const { modalResolve } = props;
  const scope = useCss(css);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm({
    defaultValues: { areYouSure: "" },
  });

  async function handleDeleteAccount() {
    try {
      await deleteUser();
      navigate("/login");
      toast.success("Your account has been deleted.");
      modalResolve();
    } catch (err) {
      toast.error(
        "An error occurred while deleting your account. Please try again."
      );
    }
  }

  return (
    <form {...scope} onSubmit={handleSubmit(handleDeleteAccount)}>
      <ModalHeader>Delete Account</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to delete your account?</p>
        <div className="mb-4">
          To confirm, please type <strong>"delete my account"</strong> below:
        </div>
        <div className="mb-16">
          <InputField
            control={control}
            fieldName="areYouSure"
            required
            autoFocus
            rules={[
              {
                errorMessage: "Confirmation text is incorrect",
                validate: (v) => v === "delete my account",
              },
            ]}
          />
        </div>
        <div className="text-error">
          This action is not recoverable! Your account, and all associated
          photos, collections & referrals will be deleted!
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="button-group">
          <Button onClick={modalResolve} intent="tertiary" block>
            Cancel
          </Button>
          <Button isLoading={isSubmitting} intent="danger" block type="submit">
            Delete Account
          </Button>
        </div>
      </ModalFooter>
    </form>
  );
}

const css = k`

`;
