import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../common/button/button";
import { BackgroundCard } from "../../components/background-card";
import { useForm } from "react-hook-form";
import { InputField } from "../../common/input/input-field";
import { authTypeKey, sessionEmailKey } from "./auth-utils";
import { AuthTitle } from "../../components/auth-title";
import { useShareSearchParams } from "../../hooks/use-share-search-params";
import { continuePasswordReset } from "../../services/api";
import { loginUtil } from "../../utils/login-util";
import toast from "react-hot-toast";
import { getAuthText } from "../../utils/validation";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [topError, setTopError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { shareParamsStr } = useShareSearchParams();

  const { handleSubmit, control } = useForm({
    defaultValues: { newPassword: "", confirmationCode: "" },
  });

  useEffect(() => {
    const savedEmail = sessionStorage.getItem(sessionEmailKey);
    if (savedEmail) {
      setEmail(savedEmail);
    } else {
      navigate(`/login-or-signup${shareParamsStr}`);
    }
  }, []);

  const submit = ({
    newPassword,
    confirmationCode,
  }: {
    newPassword: string;
    confirmationCode: string;
  }) => {
    setTopError(null);
    setIsLoading(true);
    continuePasswordReset(email, confirmationCode, newPassword)
      .then(() => {
        return loginUtil(email, newPassword).then(() => {
          toast.success("Successfully reset password");
          navigate(`/collection${shareParamsStr}`);
        });
      })
      .catch(() => {
        setTopError("An unknown error occurred. We're working on it!");
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <BackgroundCard>
        <AuthTitle
          backCallback={() => navigate(`/login-or-signup${shareParamsStr}`)}
          disableBackCallback={isLoading}
        >
          Reset password
        </AuthTitle>

        <div className="mb-24">
          {!!topError ? (
            <div className="text-error">{topError}</div>
          ) : (
            <div className="text-secondary">
              Enter the verification code sent to your{" "}
              {getAuthText(localStorage.getItem(authTypeKey))} and the new
              password for your account.
            </div>
          )}
        </div>
        <div className="mb-16">
          <InputField
            required
            control={control}
            fieldName="confirmationCode"
            placeholder="Verification code"
            label="Verification code"
            type="verification code"
            autoComplete="new-password"
            scale="lg"
          />
        </div>
        <div className="mb-8">
          <InputField
            required
            control={control}
            fieldName="newPassword"
            placeholder="New password"
            label="New password"
            type="password"
            autoComplete="new-password"
            validatePasswordRules
            seePassword
            scale="lg"
          />
        </div>
        <div className="text-secondary text-sm mb-24">
          Your password must be at least 8 characters and contain at least 1
          number, 1 uppercase character, 1 lowercase character, and 1 special
          character
        </div>

        <div className="button-group">
          <Button
            intent="primary"
            isLoading={isLoading}
            type="submit"
            block
            alignText="center"
            size="lg"
          >
            Reset password
          </Button>
        </div>
      </BackgroundCard>
    </form>
  );
};
