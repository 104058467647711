import React, { ReactNode, useState, useEffect } from "react";
import isBoolean from "lodash/isBoolean";
import { Navigate } from "react-router-dom";

import { useAppState } from "../app-state";
import { refreshAccessToken } from "../services/api";

type Props = {
  children?: ReactNode;
};

export function ProtectedRoute({ children }: Props) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const { refreshToken, accessToken } = useAppState([
    "refreshToken",
    "accessToken",
  ]);

  useEffect(() => {
    if (!refreshToken) {
      return setIsLoggedIn(false);
    }
    if (!accessToken) {
      refreshAccessToken().then((hasAccessToken) => {
        setIsLoggedIn(hasAccessToken);
      });
    } else {
      setIsLoggedIn(true);
    }
  }, []);
  if (isBoolean(isLoggedIn)) {
    if (isLoggedIn) {
      return <>{children}</>;
    }
    return <Navigate to="/login-or-signup" />;
  }
  return null;
}
