import React, { forwardRef, useMemo } from "react";
import { Control, useController, UseControllerProps } from "react-hook-form";

import { Checkbox } from "./checkbox";
import type { CheckboxProps } from "./checkbox";
import { mergeRefs } from "../../utils/merge-refs";

type CheckboxFieldProps = CheckboxProps & {
  control: Control<any>;
  fieldName: string;
  required?: boolean;
  rules?: { errorMessage: string; validate: (v: any) => boolean }[];
};

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  function CheckboxField(props, ref) {
    const { fieldName, control, required, rules, ...restOfProps } = props;

    const mergedRules = useMemo(() => {
      const mRules: UseControllerProps["rules"] = {};
      if (required) {
        mRules.required = "Field is required";
      }

      mRules.validate = (v) => {
        if (rules) {
          for (let i = 0; i < rules.length; i++) {
            const rule = rules[i];
            if (!rule.validate(v)) {
              return rule.errorMessage;
            }
          }
        }
        return true;
      };
      return mRules;
    }, [JSON.stringify(rules), required]);

    const {
      field: { value, onChange, onBlur, ref: fieldRef },
      fieldState: { error },
    } = useController({ name: fieldName, control, rules: mergedRules });

    return (
      <Checkbox
        onChange={onChange}
        onBlur={onBlur}
        checked={value}
        error={error?.message}
        ref={mergeRefs([ref, fieldRef])}
        {...restOfProps}
      />
    );
  }
);
