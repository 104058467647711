import React from "react";
import { Outlet } from "react-router-dom";

export function Account() {
  return (
    <div className="account-body">
      <Outlet />
    </div>
  );
}
