import React, { ReactNode, CSSProperties, useMemo } from "react";
import { useCss, k, a } from "kremling";
import * as child_process from "child_process";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  size?: "xs" | "sm" | "md" | "lg" | number;
  shapeId?: string;
};

export function Squircle(props: Props) {
  const { size = "md", children } = props;
  const scope = useCss(css);

  const calcSize = useMemo(() => {
    switch (size) {
      case "xs":
        return 28;
      case "sm":
        return 40;
      case "md":
        return 100;
      case "lg":
        return 160;
      default:
        return size;
    }
  }, [size]);

  return (
    <div
      {...scope}
      className="squircle"
      style={{
        borderRadius: `${(calcSize * 0.25) / 10}rem`,
        width: `${calcSize / 10}rem`,
        height: `${calcSize / 10}rem`,
      }}
    >
      {children}
    </div>
  );
}

const css = k`
  .squircle {
    width: 0;
    height: 0;
    overflow: hidden;
    position: relative;
  }
`;
