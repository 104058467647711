import React, { ReactNode } from "react";
import { useCss, k, a } from "kremling";
import {
  CollectionCover,
  CollectionCoverProps,
} from "../collection-cover/collection-cover";
import { Button } from "../../common/button/button";
import { Menu } from "../../common/menu";
import {
  collectionColors,
  collectionIcons,
} from "../create-collection-modal/collection-assets";

type Props = Omit<CollectionCoverProps, "className"> & {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  onChooseImg: () => void;
  onChooseIcon: () => unknown;
  onReset: () => void;
};

export function CollectionDisplay(props: Props) {
  const {
    children,
    className,
    disabled,
    onChooseImg,
    onChooseIcon,
    onReset,
    ...rest
  } = props;
  const scope = useCss(css);

  return (
    <div {...scope} className={a("collection-display", className)}>
      <div className="text-label mb-16">Collection image</div>
      <div className="cd-form">
        <div className="cd-image">
          <CollectionCover {...rest} size="md" />
          {/*{!!children && (*/}
          {/*  <Menu*/}
          {/*    renderTrigger={(refProps) => (*/}
          {/*      <Button*/}
          {/*        {...refProps}*/}
          {/*        iconOnly="pen"*/}
          {/*        intent="secondary-grey"*/}
          {/*        className="cover-edit-btn"*/}
          {/*        disabled={disabled}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  >*/}
          {/*    {children}*/}
          {/*  </Menu>*/}
          {/*)}*/}
        </div>
        <div className="cd-content">
          <Button intent="tertiary-flat" size="sm" onClick={onChooseImg}>
            Upload Image
          </Button>
          <Button intent="tertiary-flat" size="sm" onClick={onChooseIcon}>
            Choose Icon
          </Button>
          {(rest.coverIcon !== collectionIcons[0] ||
            rest.coverColor !== collectionColors[0] ||
            !!rest.coverUrl) && (
            <Button intent="tertiary-flat" size="sm" onClick={onReset}>
              Reset
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

const css = k`
  .collection-display {
    margin-bottom: 1.6rem;
  }
  
  .cd-form {
    display: flex;
    gap: 1.6rem;
  }

  .cd-image {
    position: relative;
    display: inline-block;
  }
  
  .cd-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .editable-container {
    max-width: 40rem;
    width: 100%;
  }

  .cover-edit-btn {
    position: absolute;
    top: 0;
    right: -.8rem;
  }

  .editable {
    padding: .2rem .8rem;
    background-color: transparent;
    transition: background-color 200ms ease;
    position: relative;
    cursor: text;

    &:hover {
      background-color: $grey-200;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      background-color: $grey-400;
    }

    &::after {
      position: absolute;
      opacity: .5;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
  
  .title {
    font-size: 2.4rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: .8rem;
    border-radius: $border-radius-md;

    &::after {
      //content: 'Title';
    }
  }

  .message {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: $text-secondary;
    margin-bottom: .8rem;
    border-radius: $border-radius-sm;
    
    &::after {
      content: 'Message (optional)';
    }
  }
`;
