import React, { memo } from "react";
import { useCss, k, a } from "kremling";
import { Icon } from "../common/icon/icon";
import { isEmpty } from "lodash";
import { isVideoMimetype, selectionLimit } from "../utils/utils";
import { VideoTag } from "./video-tag";
import { getGradientByIndex } from "../utils/gradients-util";

type Props = {
  files: Upload[];
  gridSize: "sm" | "lg" | string;
  setLightboxIndex: (index: number) => void;
  selectedFileIds: string[];
  setSelectedFileIds: (fileIds: (state: string[]) => string[]) => void;
  isSelectionMode?: boolean;
};

export function CollectionGrid(props: Props) {
  const {
    files,
    setLightboxIndex,
    setSelectedFileIds,
    selectedFileIds,
    gridSize,
    isSelectionMode,
  } = props;
  const scope = useCss(css);

  return (
    <a {...scope} className={`collection-grid size-${gridSize}`}>
      <div className="grid">
        {files.map((file, i) => {
          const isSelected = selectedFileIds.includes(file.uploadId);
          const isVideo = isVideoMimetype(file.mimeType);
          const showSelectBtn =
            selectedFileIds.length < selectionLimit ||
            (selectedFileIds.length === selectionLimit && isSelected);
          return (
            <div
              key={file.uploadId}
              className={a("item").m("--selected", isSelected)}
              onClick={() => {
                if (selectedFileIds.length > 0 && !showSelectBtn) return;
                setLightboxIndex(i);
              }}
            >
              {showSelectBtn && isSelectionMode && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedFileIds((state: string[]) => {
                      if (state.includes(file.uploadId)) {
                        return state.filter(
                          (id: string) => id !== file.uploadId
                        );
                      } else {
                        return [...state, file.uploadId];
                      }
                    });
                  }}
                  className={a("select_btn").m(
                    "--large",
                    !isEmpty(selectedFileIds)
                  )}
                >
                  <div className="icon-gradient-background">
                    {/*This is to give the selected checkmark a white color. Alternative is to use "duotone" icon*/}
                    <div
                      className="icon-background"
                      style={{ visibility: isSelected ? "visible" : "hidden" }}
                    />
                    <Icon
                      className="select-icon"
                      name={
                        isSelected
                          ? "circle-check-solid"
                          : "circle-check-regular"
                      }
                      size={20}
                    />
                  </div>
                </button>
              )}
              <div
                className="thumbnail"
                style={{
                  background:
                    isVideo || !file.thumbnailUrl
                      ? getGradientByIndex(i)
                      : null,
                }}
              >
                <CollectionGridItem
                  thumbnailUrl={file.thumbnailUrl}
                  id={file.uploadId}
                />
              </div>
              {isVideo && <VideoTag />}
            </div>
          );
        })}
      </div>
    </a>
  );
}

const CollectionGridItem = memo(
  function CollectionGridItem({
    thumbnailUrl,
  }: {
    thumbnailUrl: string;
    id: string;
  }) {
    return <img className="thumbnail" src={thumbnailUrl} alt={"thumb"} />;
  },
  (prevProps, nextProps) => {
    return prevProps.id === nextProps.id;
  }
);

const css = k`
  .collection-grid {
    width: 100%;
    cursor: default;
    flex-grow: 1;
  }

  .title {
    font-weight: 700;
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  .grid {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: .4rem;
    grid-column-start: revert;
    width: 100%;
  }

  .size-lg .grid {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }

  .size-sm .grid {
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  }

  .item {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    background-color: $grey-400;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    
    .thumbnail {
      opacity: 1;
      transform: scale(1);
      transition: transform 100ms ease-out, filter 500ms ease, opacity 500ms ease;
      -webkit-touch-callout: none;
      //border-radius: $border-radius-sm;
      height: 100%;
      width: 100%;
      position: absolute;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      &.--blur {
        opacity: .5;
        filter: blur(2.8px);
        transform: scale(1.05);
      }
    }

    &.--selected > .thumbnail {
      transform: scale(.85);
    }

    .thumbnail_video {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;
      height: 3.2rem;
      width: 3.2rem;
      background-color: rgba($raisin-900, .2);
      border-radius: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .select_btn {
      all: unset;
      position: absolute;
      top: 0;
      left: 0;
      height: 35%;
      width: 35%;
      z-index: 1;
      &.--large {
        height: 100%;
        width: 100%;
      }
    }

    .select-icon {
      fill: #fff;
      opacity: .8;
      position: absolute;
    }
    
    .icon-gradient-background {
      position: absolute;
      height: 2rem;
      width: 2rem;
      top: .6rem;
      left: .6rem;
      border-radius: 100vh;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: -15px -13px 44px 10px rgb(0, 0, 0);
      transition: top 100ms ease-out, left 100ms ease-out;
    }
    
    .icon-background {
      position: absolute;
      left: 1px;
      top: 1px;
      background: #fff;
      height: 1.8rem;
      width: 1.8rem;
      border-radius: 100vh;
    }
    
    &.--selected > .select_btn > .icon-gradient-background {
      top: 1.4rem;
      left: 1.4rem;
    }
    
    &.--selected > .select_btn > .icon-gradient-background > .select-icon {
      fill: $violet-500;
      opacity: 1;
    }
    
    &.--selected > .select-btn > .icon-gradient-background {
      background: none;
    }
    
  }

  @include breakpoint(xs) {
    .size-sm .item .icon-gradient-background {
      top: 1.2rem;
      left: 1.2rem;
    }
    
    .size-lg .item .icon-gradient-background {
      top: 1.6rem;
      left: 1.6rem;
    }
    
    .size-sm .item.--selected > .select_btn > .icon-gradient-background {
      top: 1.6rem;
      left: 1.6rem;
    }
    
    .size-lg .item.--selected > .select_btn > .icon-gradient-background {
      top: 2.4rem;
      left: 2.4rem;
    }
  }

  @keyframes slideInDown {
    0% {
      opacity: 0;
      margin-top: -5rem;
    }
    100% {
      opacity: 1;
      margin-top: 0;
    }
  }
`;
