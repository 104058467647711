export const collectionIcons = [
  "camera-retro-solid",
  "rings-wedding-solid",
  "sparkles-solid",
  "balloon-solid",
  "cake-candles-regular",
  "champagne-glasses-regular",
  "party-horn-solid",
  "gift-regular",
  "calendar-star-solid",
  "briefcase-solid",
] as const;

export const collectionColors = [
  "linear-gradient(134deg, #FA00FF -9.07%, #0014CB 108.26%)",
  "linear-gradient(139.43deg, #6FEEFF 13.41%, #0382F8 86.88%)",
  "linear-gradient(141.14deg, #FFCC49 13.32%, #FF5C00 88.06%)",
  "linear-gradient(139.43deg, #9efe01 13.41%, #28ca0d 86.88%)",
  "linear-gradient(141.14deg, #ff9dd2 13.32%, #ee0482 88.06%)",
] as const;

export const deprecatedColorMap: {
  [key: string]: string;
} = {
  "#DFB7FF": collectionColors[0],
  "#FCD56F": collectionColors[1],
  "#B3F6A9": collectionColors[2],
  "#FFC6B9": collectionColors[3],
  "#B4F3FF": collectionColors[4],
};
