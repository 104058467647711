export const sessionEmailKey = "__picme_auth_email";
export const refreshTokenKey = "__picme_auth_refresh_token";
export const betaKey = "__picme_beta";
export const emailKey = "__picme_email";
export const authTypeKey = "__picme_auth_type";
export const zipKey = "__picme_zip";
export const gridSizeKey = "__picme_gridsize";
export const collectionIdKey = "__picme_collection_id";
export const lsDeviceIdKey = "__picme_device_id";
export const lsOwnershipTokenKey = "__picme_ownership_token";
export const lsAskForNameOnceKey = "__picme_ask_for_name_once";

export function saveRefreshToken(refreshToken: string) {
  localStorage.setItem(refreshTokenKey, refreshToken);
}
