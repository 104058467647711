import React, { useState } from "react";
import { Button } from "../../../common/button/button";
import { convertExtToMime } from "../../../utils/convert-ext-to-mime";
import { SlideType } from "../photo-viewer-types";

type Props = {
  slide: SlideType;
};

export function SlideShare(props: Props) {
  const { slide } = props;
  const [loadingShareImg, setLoadingShareImg] = useState(false);
  const canShare = navigator.canShare;

  const handleSharePhoto = async () => {
    setLoadingShareImg(true);
    const { url } = await slide.src();
    const id = slide.id;
    const fileBlob = await fetch(url).then((r) => r.blob());
    const fileName = id.slice(id.lastIndexOf("/") + 1);
    const fileExt = id.slice(id.lastIndexOf(".") + 1);
    const mimeType = convertExtToMime(fileExt);
    setLoadingShareImg(false);
    await navigator.share({
      files: [
        new File([fileBlob], fileName, { type: slide.mimetype || mimeType }),
      ],
    });
  };

  return (
    canShare && (
      <Button
        intent="tertiary-transparent"
        iconOnly="arrow-up-from-bracket-regular"
        onClick={handleSharePhoto}
        isLoading={loadingShareImg}
        loaderProps={{
          fill: "light",
        }}
      />
    )
  );
}
