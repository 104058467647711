import React, {
  ReactNode,
  useState,
  useRef,
  useLayoutEffect,
  CSSProperties,
} from "react";
import { useCss, k } from "kremling";

import { useBodyScrollBoolean } from "../../hooks/use-body-scroll-boolean";

type Props = {
  top: ReactNode;
  bottom?: ReactNode;
  scrollEl?: HTMLElement;
};

export function AppHeaderBlank(props: Props) {
  const { top, bottom, scrollEl } = props;
  const elRef = useRef<HTMLDivElement>();
  const scope = useCss(css);
  const show = useBodyScrollBoolean(scrollEl);
  const [spacing, setSpacing] = useState<CSSProperties["height"]>("auto");

  useLayoutEffect(() => {
    setSpacing(elRef.current.offsetHeight);
    const resizeObserver = new ResizeObserver(() => {
      setSpacing(elRef.current.offsetHeight);
    });
    resizeObserver.observe(elRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <div
      {...scope}
      className="app-header"
      style={{ top: show ? 0 : -spacing - 1 }}
    >
      <div className="app-header-top" ref={elRef}>
        {top}
      </div>
      {bottom}
    </div>
  );
}

const css = k`
  .app-header {
    position: sticky;
    z-index: 800;
    transition: top 200ms ease;
    left: 0;
    right: 0;
    width: 100%;
  }
  
  .app-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    
    @include breakpoint(lg) {
      &::after {
        display: none;
      }
    }
  }
`;
