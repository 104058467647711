import React from "react";

import { Button } from "../../../common/button/button";
import { SlideType } from "../photo-viewer-types";
import { restoreTrashedUploads } from "../../../services/api";
import { queryClient } from "../../../query-client";
import { collectionTrashQueryKey } from "../../../queries";


type Props = {
  collectionId: string;
  slide: SlideType;
  setNextIncrement: (index: number, arrayLengthDiff?: number) => void;
  fullAccess: boolean;
};

export function SlideRestore(props: Props) {
  const { collectionId, slide, setNextIncrement, fullAccess } = props;

  const handleRestorePhoto = async () => {
    try {
      await restoreTrashedUploads(collectionId, [slide.id])
      setNextIncrement(-1, -1);
      await queryClient.setQueryData(
        collectionTrashQueryKey(collectionId),
        (data: any) => ({
          pages: data.pages.map((next: any) => ({
            ...next,
            uploads: next.uploads.filter(
              (p: any) => p.uploadId !== slide.id
            ),
          })),
          pageParams: data.pageParams,
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  return fullAccess ? (
    <Button
      intent="tertiary-transparent"
      iconOnly="trash-arrow-up-solid"
      onClick={() => handleRestorePhoto()}
    />
  ) : null;
}
