import React, { useState } from "react";
import { useCss, k, a } from "kremling";
import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";
import { Logo } from "../../components/logo";
import { Button } from "../../common/button/button";
import { createPortal } from "react-dom";
import { CollectionCoverDisplay } from "../../components/collection-cover/collection-cover-display";
import {
  useGetEventHistories,
  useGetEventHistory,
  useGetUserInfoQuery,
} from "../../queries";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppState } from "../../app-state";
import { removeCollectionHistory } from "../../utils/user-state-utils";
import { CollectionCover } from "../../components/collection-cover/collection-cover";

const transition = {
  duration: 0.2,
  ease: "easeInOut",
};

export function UploaderInfo({ className }: { className?: string }) {
  const scope = useCss(css);
  const [open, setOpen] = useState(false);
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const userInfo = useGetUserInfoQuery();
  const { signedIn } = useAppState(["signedIn"]);
  const eventHistories = useGetEventHistories();

  return (
    <>
      <div {...scope} className={className}>
        <Button
          iconOnly="info"
          onClick={() => setOpen(true)}
          iconOnlyProps={{ fill: "#9C9CA6" }}
        />
      </div>
      {createPortal(
        <LazyMotion features={domAnimation}>
          <AnimatePresence>
            {open && (
              <>
                <m.div
                  {...scope}
                  className="backdrop"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={transition}
                  onClick={() => setOpen(false)}
                />
                <m.div
                  {...scope}
                  className="info"
                  initial={{ opacity: 0, translateX: "-100%" }}
                  animate={{ opacity: 1, translateX: "0%" }}
                  exit={{ opacity: 0, translateX: "-100%" }}
                  transition={transition}
                >
                  <div className="info-header">
                    <div style={{ width: 40 }} />
                    <Logo size={44} />
                    <Button
                      iconOnly="xmark-regular"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <div className="info-body">
                    <div className="info-section">
                      <div className="info-title">About PicMe</div>
                      <div className="info-paragraph">
                        Create lasting memories from shared life experiences and
                        events.
                      </div>
                    </div>
                    {!!eventHistories?.data?.length && (
                      <>
                        <div className="divider" />
                        <div className="info-section">
                          <div className="info-title">Your previous events</div>
                          <div className="info-paragraph">
                            Events where you've uploaded photos previously.
                          </div>
                        </div>
                        {eventHistories?.data?.map((collection) => (
                          <div className="prev-event" key={collection.id}>
                            <button
                              onClick={() => {
                                navigate(`/up/${collection.id}`);
                                setOpen(false);
                              }}
                              className={a("collection-item").m(
                                "active",
                                collection.id === collectionId
                              )}
                            >
                              <CollectionCover
                                coverUrl={collection.coverUrl}
                                coverIcon={collection.coverIcon}
                                coverColor={collection.coverColor}
                                size="xs"
                              />
                              <span>{collection.title || "[No Title]"}</span>
                            </button>
                            <Button
                              iconOnly="xmark-regular"
                              size="sm"
                              intent="tertiary-grey"
                              className="prev-event-btn"
                              onClick={() => {
                                removeCollectionHistory(collection.id);
                              }}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <div className="info-footer">
                    {signedIn ? (
                      <>
                        <div>Signed in as {userInfo?.data?.userData?.name}</div>
                        <Button
                          intent="tertiary"
                          iconRight="arrow-up-right-from-square"
                          as="a"
                          href="/"
                          target="_blank"
                        >
                          Go to app
                        </Button>
                      </>
                    ) : (
                      <Button
                        intent="tertiary"
                        block
                        as={Link}
                        to={`/login-or-signup?uploader-collection-id=${collectionId}`}
                      >
                        Sign in
                      </Button>
                    )}
                  </div>
                </m.div>
              </>
            )}
          </AnimatePresence>
        </LazyMotion>,
        document.body
      )}
    </>
  );
}

const css = k`
  .backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba($raisin-500, .5);
    z-index: 10;
  }

  .info {
    background-color: $grey-100;
    box-shadow: $shadow-xl;
    position: fixed;
    inset: 0;
    z-index: 11;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.6rem 2.4rem;
  }
  
  .info-body {
    padding: 3.2rem;
    overflow: auto;
    flex-grow: 1;
  }

  .info-section {
    margin-bottom: 1.6rem;
  }

  .info-title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: .8rem;
  }

  .info-paragraph {
    color: #72737B;
    font-size: 1.4rem;
    font-weight: 500;
  }

  .divider {
    border-bottom: solid .1rem #d3d3d3;
    padding-top: .8rem;
    margin-bottom: 2.4rem;
  }

  .collection-item {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    padding: .6rem;
    gap: 1.6rem;
    border-radius: $border-radius-md;
    cursor: pointer;
    overflow: hidden;
    border: none;
    background-color: transparent;
    transition: background-color 100ms ease;
    flex-shrink: 0;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: .4rem;

    @include focus-ring {
      border-radius: 1.2rem;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.active {
      background: rgba($raisin-900, .1);
    }

    &.paused {
      justify-content: space-between;
    }

    .collection-shared {
      font-weight: 100;
      color: $raisin-75;
      font-size: 1.4rem;
    }
  }
  
  .prev-event {
    position: relative;
  }
  
  .prev-event-btn {
    position: absolute;
    top: .4rem;
    right: .4rem;
  }
  
  .info-footer {
    padding: 3.2rem;
    display: flex;
    flex-direction: column;
    gap: .8rem;
    align-items: center;
    width: 100%;
    justify-content: center;
    
    > * {
      text-align: center;
      width: 100%;
    }
  }
`;
