import { useEffect } from "react";

export function useNoBodyScroll(noScroll: boolean) {
  useEffect(() => {
    if (noScroll) {
      document.body.classList.add("body-no-scroll");
    }
    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, [noScroll]);
}
