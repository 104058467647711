import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  MouseEvent,
} from "react";
import { useCss, k, a } from "kremling";
import { motion, useSpring } from "framer-motion";
import { useGesture } from "@use-gesture/react";
import { calcX, calcY, springOpts } from "./photo-viewer-utils";
import { useDebounceValue } from "../../hooks/use-debounce-value";
import { useIsTouchscreen } from "../../hooks/use-is-touchscreen";

type Props = {
  isDrag: boolean;
  src: string;
  isZoom: boolean;
  setIsZoom: (isZoom: boolean) => void;
  imgIsLoading: boolean;
  setImgIsLoading: (imgIsLoading: boolean) => void;
  active: boolean;
};

export type PhotoViewerSlideRef = {
  resetZoom: () => void;
};

export const SlideImage = forwardRef<PhotoViewerSlideRef, Props>(function (
  props,
  ref
) {
  const isTouchscreen = useIsTouchscreen();
  const { src, setIsZoom, isZoom, isDrag, setImgIsLoading, imgIsLoading } =
    props;
  const springScale = useSpring(1, springOpts);
  const springX = useSpring(0, springOpts);
  const springY = useSpring(0, springOpts);
  const [[naturalWidth, naturalHeight], setNaturalSize] = useState([0, 0]);
  const [[winWidth, winHeight], setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const imageContainerRef = useRef(null);
  const imageRef = useRef(null);
  const scope = useCss(css);

  const isZoomDebounced = useDebounceValue(isZoom, 200);

  useEffect(() => {
    function resize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useGesture(
    {
      onDrag: ({
        first,
        memo,
        pinching,
        cancel,
        offset: [offsetX, offsetY],
      }) => {
        if (pinching || !isZoom) return cancel();
        if (first) {
          const rect = imageRef.current!.getBoundingClientRect();
          memo = [rect.width, rect.height];
        }
        const [width, height] = memo || [0, 0];

        springX.set(calcX(offsetX, width));
        springY.set(calcY(offsetY, height));
        return memo;
      },
      onPinch: ({
        origin: [ox, oy],
        first,
        movement: [ms],
        offset: [s],
        cancel,
        memo,
      }) => {
        if (isDrag) cancel();
        if (first) {
          const rect = imageRef.current!.getBoundingClientRect();
          const tx = ox - (rect.x + rect.width / 2);
          const ty = oy - (rect.y + rect.height / 2);
          memo = [
            springX.get(),
            springY.get(),
            tx,
            ty,
            rect.width,
            rect.height,
          ];
        }
        const [currentX, currentY, x, y, width, height] = memo;
        const nextX = currentX - (ms - 1) * x;
        const nextY = currentY - (ms - 1) * y;

        springX.set(calcX(nextX, width * ms));
        springY.set(calcY(nextY, height * ms));
        springScale.set(s);
        return memo;
      },
      onPinchStart: () => {
        setIsZoom(true);
      },
      onPinchEnd: ({ offset: [s] }) => {
        setIsZoom(s > 1);
      },
    },
    {
      target: imageContainerRef,
      drag: {
        from: () => [springX.get(), springY.get()],
      },
      eventOptions: { passive: false },
      pinch: {
        from: () => [springScale.get(), null],
        angleBounds: { min: 1, max: 2 },
        scaleBounds: { min: 1, max: naturalWidth / imageRef.current?.width },
      },
    }
  );

  const onImgLoad = () => {
    setImgIsLoading(false);
    setNaturalSize([
      imageRef.current.naturalWidth,
      imageRef.current.naturalHeight,
    ]);
  };

  const onDoubleClick = (e: MouseEvent) => {
    const staticWidth = imageRef.current.width;
    const imgRatio = staticWidth / naturalWidth;
    const mScale = springScale.get();
    const currentScale = Math.ceil(mScale * imgRatio * 10) / 10;
    const { width, height, x, y } = imageRef.current.getBoundingClientRect();
    const ox = e.clientX;
    const oy = e.clientY;

    const transformOriginX = width / 2;
    const transformOriginY = height / 2;

    const clickOriginX = ox - x;
    const clickOriginY = oy - y;

    const displacementX = (transformOriginX - clickOriginX) / mScale;
    const displacementY = (transformOriginY - clickOriginY) / mScale;

    let scale;

    if (mScale === 1 && currentScale < 1) {
      const nextScale = (currentScale * (width * 2)) / width;
      scale = nextScale / imgRatio;
      springScale.set(scale);
      springX.set(calcX(displacementX * (scale - 1), width * scale));
      springY.set(calcY(displacementY * (scale - 1), width * scale));
      setIsZoom(true);
    } else {
      springScale.set(1);
      springX.set(0);
      springY.set(0);
      setIsZoom(false);
    }
  };

  const resetZoom = () => {
    springX.set(0);
    springY.set(0);
    springScale.set(1);
    setIsZoom(false);
  };

  useImperativeHandle(ref, () => ({
    resetZoom,
  }));

  return (
    <>
      <motion.div
        {...scope}
        className="photo-viewer-slide__image-container"
        draggable="false"
        ref={imageContainerRef}
        transformTemplate={({ scale, x, y }) =>
          `translateX(${x}) translateY(${y}) scale(${scale})`
        }
        style={{
          scale: springScale,
          x: springX,
          y: springY,
        }}
      >
        <img
          onDoubleClick={onDoubleClick}
          draggable="false"
          onLoad={onImgLoad}
          src={src}
          ref={imageRef}
          className={imgIsLoading ? "is-loading" : null}
          style={
            {
              // maxWidth: `min(${naturalWidth}px, 100%)`,
              // maxHeight: `min(${naturalHeight}px, 100%)`,
            }
          }
          onContextMenu={() => {
            if (isTouchscreen) {
              resetZoom();
            }
          }}
        />
      </motion.div>
      {isTouchscreen && (
        <div
          className={a("tap-and-hold-text").m(
            "tap-and-hold-text--hide",
            isZoomDebounced
          )}
        >
          Tap and hold photo to save
        </div>
      )}
    </>
  );
});

const css = k`
  .photo-viewer-slide__image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    touch-action: none;
    z-index: 0;
    
    img {
      height: 100%;
      width: 100%;
      touch-action: none;
      object-fit: contain;
      
      img.is-loading {
        opacity: 0;
      }
    }
  }
`;
