import { Lipid } from "lipid";

import { stateHookGenerator } from "./utils/state-hook-generator";
import { sessionEmailKey, refreshTokenKey } from "./pages/auth/auth-utils";
import { queryClient } from "./query-client";
import {
  getZipLS,
  updateZipLS,
  getGridSizeLS,
  updateGridSizeLS,
} from "./utils/utils";
import cloneDeep from "lodash/cloneDeep";

type FileUpload = {
  progress: number;
  error: string;
  status: "queue" | "upload" | "error" | "success";
};

export type UploadFileType = {
  id: string;
  ownershipToken?: string; // todo use this when we delete an upload
  collectionId: string;
  file: File;
  thumbnail?: string;
  upload: FileUpload;
  markedAsDeleted?: boolean;
};

type ZipStateType = {
  zipUrl: string;
  thumbnails: string[];
};

type AppStateType = {
  appIsLoading?: boolean;
  accessToken?: string;
  refreshToken?: string;
  tokenExpiration?: string;
  session?: string;
  savedEmail?: string;
  temporaryCode?: string;
  temporaryAuth?: string;
  temporaryOldAuth?: boolean;
  challengeParameter?: string;
  menuOpen?: boolean;
  gridSize?: string;
  toastBottomOffset?: number;
  uploadFilesCollectionId?: string;
  uploadFiles?: UploadFileType[];
  uploadFilesInMemory?: { [collectionId: string]: UploadFileType[] };
  uploadFilesConfirmation?: UploadFileType[];
  uploadTotalProgress?: number;
  uploaderStop?: boolean;
  uploaderSignedInToast: boolean;
  signedIn: boolean;
  zip?: Record<string, ZipStateType>;
  uploaderHasUploaded?: Record<string, boolean>;
};

const defaultState: AppStateType = {
  appIsLoading: true,
  savedEmail: sessionStorage.getItem(sessionEmailKey),
  refreshToken: localStorage.getItem(refreshTokenKey),
  menuOpen: false,
  gridSize: getGridSizeLS(),
  toastBottomOffset: 20,
  uploadFiles: [],
  uploadFilesInMemory: {},
  uploadFilesConfirmation: [],
  uploadTotalProgress: 0,
  uploaderStop: false,
  uploaderSignedInToast: false,
  signedIn: false,
  zip: getZipLS(),
  uploaderHasUploaded: {},
};

class AppState extends Lipid {
  updateZip = (collectionId: string, zipUrl: string, thumbnails: string[]) => {
    const zip = cloneDeep(this.get("zip"));
    const nextZip = {
      ...zip,
      [collectionId]: { zipUrl, thumbnails: thumbnails || [] },
    };
    this.set({
      zip: nextZip,
    });
    updateZipLS(nextZip);
  };

  updateGridSize = (gridSize: string) => {
    this.set({ gridSize });
    updateGridSizeLS(gridSize);
  };

  deleteZip = (collectionId: string) => {
    const nextZip = cloneDeep(this.get("zip"));
    delete nextZip[collectionId];
    this.set({
      zip: nextZip,
    });
    updateZipLS(nextZip);
  };

  logOut() {
    queryClient.clear();
    localStorage.removeItem(refreshTokenKey);
    this.set({
      signedIn: false,
      refreshToken: null,
      accessToken: null,
    });
  }
}

export const appState = new AppState(defaultState);
export const useAppState = stateHookGenerator<AppStateType>(appState);
