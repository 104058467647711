import React, {
  CSSProperties,
  useState,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import { useCss, k } from "kremling";
import { useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion, useSpring } from "framer-motion";

import { SlideType } from "./photo-viewer-types";
import { Loader } from "../../common/loader/loader";
import { PhotoViewerSlideRef, SlideImage } from "./slide-image";
import { springOpts } from "./photo-viewer-utils";
import { SlideVideo } from "./slide-video";
import { useGetSlideQuery } from "../../queries";

type Props = {
  style: CSSProperties;
  slide: SlideType;
  isDrag: boolean;
  setIsZoom: (zoom: boolean) => void;
  isZoom: boolean;
  active: boolean;
};

export const Slide = forwardRef<PhotoViewerSlideRef, Props>(function Slide(
  props,
  ref
) {
  const { style, isDrag, slide, setIsZoom, isZoom, active } = props;
  const [mediaLoading, setMediaLoading] = useState(true);
  const scope = useCss(css);
  const springOpacity = useSpring(0, springOpts);
  const { isLoading, data, error } = useGetSlideQuery(slide.id, slide.src);
  const [[naturalThumbWidth, naturalThumbHeight], setThumbNaturalSize] =
    useState([0, 0]);
  const thumbRef = useRef<HTMLImageElement>();

  useEffect(() => {
    springOpacity.set(isLoading || mediaLoading ? 1 : 0);
  }, [isLoading, mediaLoading]);

  const onThumbLoad = () => {
    setThumbNaturalSize([
      thumbRef.current.naturalWidth,
      thumbRef.current.naturalHeight,
    ]);
  };

  return (
    <div
      {...scope}
      className="photo-viewer-center"
      style={style}
      data-key={slide.uid}
    >
      <AnimatePresence>
        {(isLoading || mediaLoading) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="photo-viewer-center"
          >
            <img
              onLoad={onThumbLoad}
              ref={thumbRef}
              style={
                {
                  // maxWidth: `min(${naturalThumbWidth}px, 100%)`,
                  // maxHeight: `min(${naturalThumbHeight}px, 100%)`,
                }
              }
              src={slide.thumbnailSrc}
              className="slide-thumbnail"
              draggable="false"
            />
            <Loader fill="light" style={{ zIndex: 1500, position: "relative" }} />
          </motion.div>
        )}
      </AnimatePresence>
      {!isLoading && (
        <>
          {slide.type === "image" ? (
            <SlideImage
              ref={ref}
              setIsZoom={setIsZoom}
              isZoom={isZoom}
              src={data?.url as string}
              isDrag={isDrag}
              setImgIsLoading={setMediaLoading}
              imgIsLoading={mediaLoading}
              active={active}
            />
          ) : (
            <SlideVideo
              src={data?.url}
              setVideoIsLoading={setMediaLoading}
              ref={ref}
              active={active}
            />
          )}
        </>
      )}

      {!!error && <div>got an error</div>}
    </div>
  );
});

const css = k`
  .photo-viewer-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    touch-action: none;
  }

  .tap-and-hold-text {
    color: $grey-100;
    position: fixed;
    bottom: 0;
    padding: 1.6rem;
    z-index: 1;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0, .6) 100%);
    opacity: 1;
    transition: opacity 200ms ease;
  }
  
  .tap-and-hold-text--hide {
    opacity: 0;
  }
  
  .slide-thumbnail {
    object-fit: contain;
    height: 100%;
    width: 100%;
    //max-height: 100%;
    //max-width: 100%;
    position: absolute;
    filter: blur(2px);
    z-index: 1;
  }

`;
