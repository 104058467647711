import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAppState } from "../../app-state";
import { useShareSearchParams } from "../../hooks/use-share-search-params";

export function ShareRedirect() {
  const { signedIn } = useAppState(["signedIn"]);
  const { collectionId, authorization, referrerUserId } = useParams();
  let maybeSearchParams = "";
  let route = "";
  if (authorization && collectionId && referrerUserId) {
    const sp = new URLSearchParams();
    sp.append("a", collectionId);
    sp.append("b", authorization);
    sp.append("c", referrerUserId);
    maybeSearchParams = `?${sp.toString()}`;
  }
  if (signedIn) {
    route = `/collection${maybeSearchParams}`;
  } else {
    route = `/login-or-signup${maybeSearchParams}`;
  }
  return <Navigate to={route} replace />;
}
