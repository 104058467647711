import React, { useRef, useState, useEffect, ReactElement } from "react";
import { a, k, useCss } from "kremling";

import { isVideoMimetype } from "../utils/utils";
import { UploadFileType } from "../app-state";
import { getGradientByIndex } from "../utils/gradients-util";

type Props = {
  className?: string;
  file: UploadFileType;
  index: number;
  typeIcon?: ReactElement;
};

export function MediaPreview(props: Props) {
  const { file, className, index, typeIcon = null } = props;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const isVideo = isVideoMimetype(file.file.type);
  const scope = useCss(css);

  // photo processing
  useEffect(() => {
    if (!file) return;
    const isPhoto = !isVideoMimetype(file.file.type);
    if (isPhoto) {
      setImgSrc(file.thumbnail || URL.createObjectURL(file.file));
    }
    return () => {
      if (isPhoto) {
        URL.revokeObjectURL(imgSrc);
      }
    };
  }, [file.id]);

  // video processing
  useEffect(() => {
    if (!file) return;
    let seeked = false;
    let canPlay = false;
    if (!file || !isVideoMimetype(file.file.type)) return;

    const video = document.createElement("video");

    const handleMetadataLoad = () => {
      video.currentTime = 0.1;
    };

    function handleSeek() {
      seeked = true;
      if (seeked && canPlay) ready();
    }

    function handleCanPlay() {
      canPlay = true;
      if (seeked && canPlay) ready();
    }

    function ready() {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      videoRef.current.poster = canvas.toDataURL("image/jpeg");
      URL.revokeObjectURL(video.src);
    }

    video.src = URL.createObjectURL(file.file);

    video.addEventListener("loadedmetadata", handleMetadataLoad);
    video.addEventListener("seeked", handleSeek);
    video.addEventListener("canplaythrough", handleCanPlay);

    return () => {
      if (!file) return;
      if (isVideoMimetype(file.file.type)) {
        video.removeEventListener("loadedmetadata", handleMetadataLoad);
        video.removeEventListener("seeked", handleSeek);
        video.removeEventListener("canplay", handleCanPlay);
      }
    };
  }, [file]);

  if (!file) return null;

  return (
    <>
      {isVideo ? (
        <div {...scope} className={a("media-preview", className)}>
          <div
            className="media-preview-gradient"
            style={{ background: isVideo ? getGradientByIndex(index) : null }}
          >
            {typeIcon}
          </div>
          <video ref={videoRef} />
          <canvas ref={canvasRef} style={{ display: "none" }} />
        </div>
      ) : !!imgSrc ? (
        <div {...scope} className={a("media-preview", className)}>
          <img src={imgSrc} alt="thumb" />
        </div>
      ) : null}
    </>
  );
}

const css = k`
  .media-preview {
    position: relative;
    
    video, img {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  
  .media-preview-gradient {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;
