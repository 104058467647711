import React from "react";
import { useCss, k, a } from "kremling";
import { Toggle } from "../../../common/toggle/toggle";
import { Input } from "../../../common/input/input";
import { Button } from "../../../common/button/button";
import {
  copyToClipboard,
  shareUrl as onShareUrl,
  updateCollectionMetadata,
} from "../../../utils/url";
import { Loader } from "../../../common/loader/loader";
import { Checkbox } from "../../../common/checkbox/checkbox";
import { Avatar } from "../../../common/avatar/avatar";
import { Menu, MenuItem } from "../../../common/menu";
import {
  disconnectSharedCollection,
  updateCollection,
  updateCollectionSharing,
} from "../../../services/api";
import { dialog } from "../../../common/dialog/dialog";
import toast from "react-hot-toast";
import { niceToastError } from "../../../utils/utils";
import { modalService } from "../../../common/modal-service/modal-service";
import { QrModal } from "../../../components/qr-modal";
import {
  AnimatePresence,
  domAnimation,
  LazyMotion,
  motion,
} from "framer-motion";
import { useCollection } from "../collection";

type Props = {
  sharingInfo: SharingInfo;
  share: boolean;
  setShare: (share: boolean) => void;
  sharees: Sharee[];
  metadata: CollectionMetadata;
  userInfo: UserInfo;
  collectionId: string;
  shareUrl: string;
  refetch: () => Promise<any>;
};

export function CollectionShareForm(props: Props) {
  const {
    sharingInfo,
    sharees,
    share,
    setShare,
    metadata,
    userInfo,
    collectionId,
    shareUrl,
    refetch,
  } = props;
  const scope = useCss(css);
  const { collection } = useCollection();

  const handleShareToggle = (toggle: boolean) => {
    setShare(toggle);
    updateCollectionSharing(collectionId, { sharingEnabled: toggle });
  };

  const handleRemoveSharee = async (sharee: Sharee) => {
    await dialog({
      title: "Disconnect Collection",
      submitText: "Disconnect",
      description: (
        <>
          <p>
            Are you sure you want to disconnect this collection from this shared
            user?
          </p>
          <p>
            <strong>{sharee.name}</strong> will lose access to this collection.
          </p>
          <p className="text-error">This action cannot be undone.</p>
        </>
      ),
      onSubmitCallback: async () => {
        try {
          await disconnectSharedCollection(collectionId, sharee.userId);
          toast.success("Successfully disconnected shared collection");
          await refetch();
        } catch (e) {
          niceToastError("Unable to disconnect shared collection", e);
        }
      },
    });
  };

  const openQrModal = () => {
    modalService.render(QrModal, {
      url: shareUrl,
      title: "Share Link QR Code",
      collection,
    });
  };

  const handleShare = async () => {
    onShareUrl(
      shareUrl,
      `PicMe Invitation`,
      `You're invited to the PicMe collection: ${metadata.title}`
    );
  };

  const handle = async (delta: CollectionMetadata) => {
    const [metadata] = updateCollectionMetadata(collectionId, delta);
    await updateCollection(collectionId, {
      ...metadata,
      ...delta,
    });
  };

  const canShare = !!navigator.share;
  const pauseShareeUpload = !!metadata.pauseShareeUpload;

  const isLoading = !sharingInfo?.referrerUserId || !sharingInfo?.authorization;
  return (
    <div {...scope} className="share-container">
      <LazyMotion features={domAnimation}>
        <AnimatePresence>
          <motion.div
            initial={{
              height: "8.4rem",
              backgroundColor: "#ffffff",
              borderColor: "transparent",
            }}
            animate={{
              height: share ? "17rem" : "8.4rem",
              backgroundColor: share ? "#E9E9ED" : "#ffffff",
              borderColor: share ? "transparent" : "#e3e3e3",
            }}
            className="enable-box"
          >
            <div className="enable-box__top">
              <motion.div
                initial={{ translateY: ".7rem" }}
                animate={{ translateY: share ? 0 : ".7rem" }}
                className="enable-box__text"
              >
                <div>
                  <strong>Enable share link</strong>
                </div>
                {share && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="share-toggle-description"
                  >
                    Anyone who receives this link will be able to view and
                    upload photos
                  </motion.div>
                )}
              </motion.div>
              <motion.div>
                <Toggle checked={share} onChange={handleShareToggle} />
              </motion.div>
            </div>

            {share && isLoading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "4.8rem",
                }}
              >
                <Loader />
              </div>
            )}

            {share && !isLoading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="enable-box__bottom"
              >
                <div>
                  <Button
                    block
                    iconRight={canShare ? "share-arrow-up" : "copy"}
                    iconRightProps={{
                      name: canShare ? "share-arrow-up" : "copy",
                      style: { marginRight: ".6rem" },
                    }}
                    size="lg"
                    intent="secondary-grey"
                    onClick={() => {
                      if (canShare) {
                        handleShare();
                      } else {
                        copyToClipboard(shareUrl);
                      }
                    }}
                  >
                    {isLoading ? "" : shareUrl}
                  </Button>
                </div>
                <div>
                  <Button
                    iconOnly="qrcode-regular"
                    intent="secondary-grey"
                    size="lg"
                    onClick={openQrModal}
                  />
                </div>
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>
      </LazyMotion>

      <div className="pt-24">
        <Checkbox
          checked={pauseShareeUpload}
          onChange={(checked) => handle({ pauseShareeUpload: checked })}
          label="Pause uploads to this collection"
          inline
        />
      </div>

      <div className="pt-16">
        <div className="shared-with">
          <div className="shared-with-item">
            {!!userInfo?.userData.name && (
              <Avatar className="name-badge" user={userInfo} size="md" />
            )}
            <div className="ellipsis">{userInfo?.userData.name}</div>
            <div className="shared-with-item__right">
              <div className="text-secondary text-sm">Owner</div>
              <Menu
                placementSide="end"
                renderTrigger={(refProps, controlProps) => (
                  <Button
                    {...refProps}
                    disabled
                    iconOnly="ellipsis-vertical-regular"
                    active={controlProps.open}
                  />
                )}
              >
                <MenuItem label="Disconnect" />
              </Menu>
            </div>
          </div>
          {!!sharees?.length &&
            sharees?.map((sharee) => {
              const name = sharee?.name || "Unknown";
              const abbr = name.slice(0, 1);
              return (
                <div className="shared-with-item" key={sharee.userId}>
                  <div className="name-badge">{abbr}</div>
                  <div className="ellipsis">{name}</div>
                  <Menu
                    placementSide="end"
                    renderTrigger={(refProps, controlProps) => (
                      <Button
                        {...refProps}
                        iconOnly="ellipsis-vertical-regular"
                        active={controlProps.open}
                      />
                    )}
                  >
                    <MenuItem
                      label="Disconnect"
                      onClick={() => handleRemoveSharee(sharee)}
                    />
                  </Menu>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

const css = k`
  .share-container {
    padding-top: 1.6rem;
    max-width: 55rem;
    margin: 0 auto 3.2rem auto;
    padding-left: .8rem;
    padding-right: .8rem;
  }
  .share-toggle {
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.--on {
      padding-bottom: 1.6rem;
    }
  }

  .share-toggle-text {
    margin-right: 1.6rem;
  }

  .code-loading {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    position: absolute;
    height: 4.4rem;
    top: 0;
    left: 4.8rem;
    right: 4.8rem;
  }

  .share-toggle-description {
    font-weight: 500;
    color: $text-secondary;
    font-size: 1.2rem;
  }

  .share-on {
    position: relative;
    overflow: hidden;
  }

  .shared-with {
    > .shared-with-item {
      gap: 1.6rem;
      padding: .8rem 0;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      justify-content: space-between;
      font-weight: 700;

      .ellipsis {
        flex-grow: 1;
      }
    }

    > .shared-with-item:not(:last-child) {
      border-bottom: none;
    }

    > .shared-with-item:first-child {
      border-top-left-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;
    }

    > .shared-with-item:last-child {
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
    }
  }

  .shared-with-item__right {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  .name-badge {
    height: 3.6rem;
    width: 3.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $raisin-100;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: $border-radius-full;
    margin-right: .8rem;
  }

  .qr {
    width: 100%;
    border: solid .1rem var(--app-border);
    border-radius: $border-radius-md;
    padding: 2.4rem 2.4rem;
    margin-bottom: 2.4rem;

    .qr-container {
      padding: 0 4rem;
      margin-bottom: 1.6rem;
    }

    img {
      object-fit: contain;
      width: 100%;
    }
  }
  
  .enable-box {
    background-color: $grey-300;
    border-radius: $border-radius-xxl;
    padding: 2.4rem;
    border-width: .2rem;
    border-style: solid;
  }
  
  .enable-box__top {
    display: flex;
    gap: 3.2rem;
    margin-bottom: 1.6rem;
  }
  
  .enable-box__text {
    flex-grow: 1;
  }
  
  .enable-box__bottom {
    display: flex;
    gap: 1.6rem;
    width: 100%;
    max-width: 100%;
    
    > div:first-child {
      flex-grow: 1;
      overflow: hidden;
    }
  }
`;
