import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../common/button/button";
import { BackgroundCard } from "../../components/background-card";
import { useForm } from "react-hook-form";
import { InputField } from "../../common/input/input-field";
import { sessionEmailKey } from "./auth-utils";
import { AuthTitle } from "../../components/auth-title";
import { useShareSearchParams } from "../../hooks/use-share-search-params";
import { requestPasswordReset } from "../../services/api";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [topError, setTopError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { shareParamsStr } = useShareSearchParams();

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: { isEmailOrPhoneNumber: "" },
  });

  useEffect(() => {
    const savedEmail = sessionStorage.getItem(sessionEmailKey);
    if (savedEmail) {
      setValue("isEmailOrPhoneNumber", savedEmail);
    }
  }, []);

  const submit = ({
    isEmailOrPhoneNumber,
  }: {
    isEmailOrPhoneNumber: string;
  }) => {
    setTopError(null);
    setIsLoading(true);
    requestPasswordReset(isEmailOrPhoneNumber)
      .then(() => {
        navigate(`/reset-password${shareParamsStr}`);
      })
      .catch(() => {
        setTopError("An unknown error occurred. We're working on it!");
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <BackgroundCard>
        <AuthTitle
          backCallback={() => navigate(`/login${shareParamsStr}`)}
          disableBackCallback={isLoading}
        >
          Forgot password
        </AuthTitle>

        <div className="mb-24">
          {!!topError ? (
            <div className="text-error">{topError}</div>
          ) : (
            <div className="text-secondary">
              Enter the email address associated with your account and we'll
              send you a new verification code that will allow you to reset your
              password.
            </div>
          )}
        </div>

        <div className="mb-24">
          <InputField
            label="Email or Phone Number"
            placeholder="Email or Phone Number"
            required
            control={control}
            fieldName="isEmailOrPhoneNumber"
            type="emailOrPhoneNumber"
            scale="lg"
          />
        </div>
        <div className="button-group">
          <Button
            intent="primary"
            isLoading={isLoading}
            type="submit"
            block
            size="lg"
            alignText="center"
          >
            Send verification code
          </Button>
        </div>
      </BackgroundCard>
    </form>
  );
};
