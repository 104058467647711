import React from "react";
import { useCss, k } from "kremling";
import { OverlayPage } from "../../components/overlay-page/overlay-page";

export function AccountPlan() {
  const scope = useCss(css);
  return (
    <div {...scope} className="plan">
      <OverlayPage title="Plan">
        <div className="plan-box">
          <div className="plan-box__inside">
            <div className="plan-box__title">Free Plan</div>
            {/*<div className="text-sm text-secondary">*/}
            {/*  Store up to 100 photos. Photos stored for 3 months. Support and hugs*/}
            {/*  from the PicMe team ❤️.*/}
            {/*</div>*/}
          </div>
          <div>Free</div>
        </div>
      </OverlayPage>
    </div>
  );
}

const css = k`
  .plan {
    max-width: 55rem;
    margin: 0 auto 3.2rem auto;
  }
  
  .plan-box {
    display: flex;
    border-radius: $border-radius-lg;
    @include ring;
    background-color: $side-bar;
    padding: 2.4rem;
    align-items: center;
    gap: 6rem;
    user-select: none;
    cursor: pointer;
  }
  
  .plan-box__inside {
    flex-grow: 1;
  }
  
  .plan-box__title {
    font-weight: 700;
  }

`;
