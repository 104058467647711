import React, { CSSProperties, forwardRef } from "react";
import { useCss, k, a } from "kremling";
import { ToggleCheckbox } from "./toggle-checkbox";

export type CheckboxProps = {
  className?: string;
  style?: CSSProperties;
  onChange?: (checked: boolean) => void;
  onBlur?: () => void;
  checked?: boolean;
  inline?: boolean;
  label?: string;
  disabled?: boolean;
  error?: string;
  id?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(props, ref) {
    const {
      className,
      style,
      onChange,
      checked,
      inline,
      label,
      disabled,
      error,
      onBlur,
      id,
    } = props;
    const scope = useCss(css);

    return (
      <label
        {...scope}
        style={style}
        className={a("checkbox", className)
          .m("checkbox--inline", inline)
          .m("checkbox--checked", checked)
          .m("checkbox--disabled", disabled)}
      >
        <div className="checkbox__input">
          <input
            ref={ref}
            id={id}
            onBlur={onBlur}
            type="checkbox"
            onChange={() => onChange?.(!checked)}
            disabled={disabled}
          />
          <ToggleCheckbox checked={checked} disabled={disabled} />
        </div>
        <div className="checkbox__label">
          <div className="text-secondary">{label}</div>
          {!inline && <div className="pt-4 text-form-error">{error}</div>}
        </div>
      </label>
    );
  }
);

const css = k`
  .checkbox {
    display: flex;
    user-select: none;
    margin: 0;
    padding: 0;

    .checkbox__label {
      margin-left: 1.2rem;
      display: flex;
      align-items: center;
    }

    &.checkbox--inline {
      display: inline-flex;
      align-items: center;
      
      .checkbox__label {
        margin-right: 2rem;
        display: inline-block;
      }
    }
    
    &:not(.checkbox--disabled) {
      cursor: pointer;
    }
  }
  
  .checkbox__input {
    display: inline-flex;
    position: relative;
    height: 2.4rem;
    width: 2.4rem;
    
    input {
      appearance: none;
      -webkit-appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
    }

    @include focus-ring-within {
      border-radius: $border-radius-sm;
    }
  }
  
  .checkbox.checkbox--checked .checkbox__input {
    @include focus-ring-within-extend {
      inset: -.4rem;
      border-radius: $border-radius-md;
    }
  }
`;
