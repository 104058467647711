import { useEffect, useState } from "react";

export function useDebounceValue<T>(
  value: T,
  delay: number,
  opts: { disabled?: boolean } = {}
): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    // if (opts.disabled) {
    //   setDebouncedValue(value);
    // }
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
