import { useEffect, useMemo, useState } from "react";

const windowSize = () => {
  return window.innerHeight.toString() + window.outerHeight.toString();
};

export function useIsTouchscreen() {
  const [size, setSize] = useState(windowSize());

  useEffect(() => {
    const resize = () => {
      setSize(windowSize());
    };
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  return useMemo(
    () => window.matchMedia("(any-pointer:coarse)").matches,
    [size]
  );
}
