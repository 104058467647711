import { PhoneNumberUtil } from "google-libphonenumber";

export const validatePassword = (str: string): boolean =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d^$*.[\]{}()?"!@#%&/,\\><':;|_~`=+-]{8,}$/.test(
    str
  );

export const validateEmail = (str: string): boolean => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    str
  );
};

const pn = PhoneNumberUtil.getInstance();

export const validatePhoneNumber = (input: string) => {
  try {
    const number = pn.parse(input, "US");
    return pn.isValidNumberForRegion(number, "US");
  } catch (e) {
    return false;
  }
};

export const isEmailOrPhoneNumber = (input: string) => {
  const isPhoneNumber = validatePhoneNumber(input);
  if (isPhoneNumber) return true;
  return validateEmail(input);
};

export const getAuthType = (input: string) => {
  if (validatePhoneNumber(input)) return "phoneNumber";
  if (validateEmail(input)) return "email";
};

export const getAuthText = (input: string) => {
  if (input === "phoneNumber") {
    return "phone number";
  }
  return "email";
};
