import React from "react";
import { useCss, k } from "kremling";

import { Button } from "../../../common/button/button";
import { modalService } from "../../../common/modal-service/modal-service";
import { CreateCollectionModal } from "../../../components/create-collection-modal/create-collection-modal";
import { AppHeader } from "../../../components/app-header/app-header";
import { Logo } from "../../../components/logo";

export function NoCollections() {
  const scope = useCss(css);

  const createCollection = async () => {
    await modalService.render(CreateCollectionModal);
  };

  return (
    <div {...scope} className="no-collections">
      <AppHeader />
      <div className="no-collections-inner">
        <div className="no-collections__content">
          <div className="text-secondary text-center mb-24">
            <div className="no-collections__logo">
              <Logo />
            </div>
            <div className="text-sub-title mb-8">
              Your fastest way to
              <br />
              request, collect, and share photos.
            </div>
            Create a new photo collection to get started.
          </div>
          <Button
            intent="primary"
            block
            onClick={createCollection}
            iconLeft="plus-regular"
          >
            Create a collection
          </Button>
        </div>
      </div>
    </div>
  );
}

const css = k`
  .no-collections {
    height: var(--app-height);
    background-color: $side-bar;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .no-collections-inner {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  
  .logo {
    text-align: center;
    padding: 3.2rem;
  }
  
  .no-collections__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3.2rem;
    max-width: 42rem;
    width: 100%;
  }
  
  .no-collections__logo {
    width: 5rem;
    margin: 0 auto 3.2rem auto;
  }
  
  .no-collections__settings {
    padding: 3.2rem;
    width: 100%;
  }
`;
