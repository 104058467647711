import React, { useEffect, useState } from "react";
import { useController, useForm } from "react-hook-form";

import { checkApiResponse } from "../../utils/utils";
import { createCollection } from "../../services/api";
import { ModalHeader } from "../../common/modal/modal-header";
import { Button } from "../../common/button/button";
import { ModalFooter } from "../../common/modal/modal-footer";
import { ModalBody } from "../../common/modal/modal-body";
import { addCollectionToList } from "../../utils/url";
import { CollectionDisplay } from "../collection-display/collection-display";
import { ContentSwitcher } from "../../common/content-switcher/content-switcher";
import { collectionIcons, collectionColors } from "./collection-assets";
import { useImgForm } from "../../hooks/use-image-form";
import { MenuItem } from "../../common/menu";
import { TextareaField } from "../../common/textarea/textarea-field";
import { modalService } from "../../common/modal-service/modal-service";
import { useNavigate } from "react-router-dom";
import { CoverIconColorChooser } from "../cover-icon-color-chooser";
import { Input } from "../../common/input/input";
import { InputField } from "../../common/input/input-field";

export const CreateCollectionModal = ({
  modalResolve,
}: {
  modalResolve: (collectionId?: string) => void;
}) => {
  const [topError, setTopError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [coverIcon, setCoverIcon] = useState<string>(collectionIcons[0]);
  const [coverColor, setCoverColor] = useState<string>(collectionColors[0]);
  const [content, setContent] = useState("parent");
  const navigate = useNavigate();

  // form
  const { handleSubmit, control } = useForm({
    defaultValues: {
      title: "",
      message: "",
      coverImg: "",
    },
  });

  const { imgUrl, imgChoose, imgSave, imgClearLocal, imgRenderInput } =
    useImgForm(undefined, "cover");

  const { field: coverImgField } = useController({
    control,
    defaultValue: undefined,
    name: "coverImg",
  });

  useEffect(() => {
    if (imgUrl) {
      coverImgField.onChange(imgUrl);
    }
  }, [imgUrl]);

  const submit = async ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }) => {
    setTopError(null);
    setIsLoading(true);
    modalService.disableModalExit(true);
    try {
      const { collectionId } = await createCollection({
        title,
        message,
        coverIcon,
        coverColor,
      });
      await addCollectionToList(collectionId);
      if (imgUrl) {
        await imgSave(collectionId);
      }
      await new Promise((resolve) => setTimeout(resolve, 6000));
      if (collectionId) {
        navigate(`/collection/${collectionId}${location.search}`);
      }
      return modalResolve(collectionId);
    } catch (e) {
      modalService.disableModalExit(false);
      if (e?.request?.response) {
        setTopError(checkApiResponse(JSON.parse(e?.request?.response).code));
      } else {
        setTopError(JSON.stringify(e));
      }
      setIsLoading(false);
    }
  };

  return (
    <ContentSwitcher
      activeId={content}
      onChange={setContent}
      structure={[
        {
          id: "parent",
          renderContent: ({ onNext }) => (
            <form onSubmit={handleSubmit(submit)} noValidate>
              <ModalHeader>Create a Collection</ModalHeader>
              <ModalBody className="pv-32">
                <CollectionDisplay
                  coverUrl={imgUrl as string}
                  coverIcon={coverIcon}
                  coverColor={coverColor}
                  disabled={isLoading}
                  onChooseImg={imgChoose}
                  onChooseIcon={onNext}
                  onReset={() => {
                    imgClearLocal();
                    setCoverIcon(collectionIcons[0]);
                    setCoverColor(collectionColors[0]);
                  }}
                />
                {imgRenderInput}

                <div className="mb-24">
                  <label className="text-label">
                    Collection title<span className="text-error">*</span>
                  </label>
                  <InputField
                    control={control}
                    fieldName="title"
                    placeholder="Collection Title"
                    required
                    disabled={isLoading}
                  />
                </div>
                <div className="mb-24">
                  <label className="text-label">
                    Message to Guest uploader
                  </label>
                  <InputField
                    control={control}
                    fieldName="message"
                    placeholder="Message (optional)"
                    disabled={isLoading}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="button-group">
                  <Button
                    intent="tertiary"
                    block
                    onClick={() => modalResolve()}
                  >
                    Cancel
                  </Button>
                  <Button
                    intent="primary"
                    block
                    type="submit"
                    isLoading={isLoading}
                  >
                    Create Collection
                  </Button>
                </div>
              </ModalFooter>
            </form>
          ),
        },
        {
          id: "two",
          renderContent: ({ onNext, onBack }) => (
            <>
              <ModalHeader onBack={onBack}>Choose icon</ModalHeader>
              <ModalBody>
                <CoverIconColorChooser
                  onChange={(next) => {
                    imgClearLocal();
                    setCoverColor(next.coverColor);
                    setCoverIcon(next.coverIcon);
                  }}
                  coverColor={coverColor}
                  coverIcon={coverIcon}
                />
              </ModalBody>
              <ModalFooter>
                <div className="button-group">
                  <Button block intent="primary" onClick={onBack}>
                    Done
                  </Button>
                </div>
              </ModalFooter>
            </>
          ),
        },
      ]}
    />
  );
};
