import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { a } from "kremling";
import isEmpty from "lodash/isEmpty";

import { CollectionCover } from "../../components/collection-cover/collection-cover";
import { Logo } from "../../components/logo";
import { CollectionGrid } from "./components/collection-grid";
import { deleteUpload, getCollectionInfo } from "../../services/api";
import { Link, useParams } from "react-router-dom";
import { Terms } from "./components/terms";
import { Button } from "../../common/button/button";
import { appState, UploadFileType, useAppState } from "../../app-state";
import { ulid } from "ulid";
import { uploaderService } from "../../utils/uploader-service";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { UploadPersistentToast } from "../../components/upload-persistent-toast/upload-persistent-toast";
import { Loader } from "../../common/loader/loader";
import { modalService } from "../../common/modal-service/modal-service";
import { AskNameModal } from "./ask-name-modal";
import { uploaderInit, uploadFilesInMemory_delete } from "./utils/utils";
import { Icon } from "../../common/icon/icon";
import { niceToastError } from "../../utils/utils";
import { useImgForm } from "../../hooks/use-image-form";
import { Avatar } from "../../common/avatar/avatar";
import { useGetUserInfoQuery } from "../../queries";
import { UploaderInfo } from "./uploader-info";
import toast from "react-hot-toast";
import { AppHeaderBlank } from "../../components/app-header/app-header-blank";
import { addCollectionHistory } from "../../utils/user-state-utils";

export function Uploader() {
  useCssModules(css);
  const cameraRef = useRef<HTMLInputElement>();
  const filesRef = useRef<HTMLInputElement>();
  const { uploadFilesInMemory: _uploadFilesInMemory } = useAppState([
    "uploadFilesInMemory",
  ]);
  const { uploadFiles } = useAppState(["uploadFiles"]);
  const { signedIn } = useAppState(["signedIn"]);
  const { uploaderSignedInToast, uploaderHasUploaded } = useAppState([
    "uploaderSignedInToast",
    "uploaderHasUploaded",
  ]);
  const [userId, setUserId] = useState<string>();
  const [userName, setUserName] = useState<string>();
  const [deviceId, setDeviceId] = useState<string>();
  const [collection, setCollection] = useState(null);
  const [loadingCollection, setLoadingCollection] = useState(true);
  const { collectionId } = useParams();
  const [isInit, setIsInit] = useState(true);
  const [askName, setAskName] = useState(false);
  const userInfo = useGetUserInfoQuery();
  const uploadFilesInMemory = _uploadFilesInMemory[collectionId] || [];
  const hasUploaded = useMemo(() => {
    return !!uploaderHasUploaded?.[collectionId];
  }, [collectionId, JSON.stringify(uploaderHasUploaded)]);

  useEffect(() => {
    if (userInfo?.data && !uploaderSignedInToast) {
      appState.set({ uploaderSignedInToast: true });
      toast(
        <div>
          Signed in as <strong>{userInfo.data.userData.name}</strong>
        </div>,
        { icon: <Avatar user={userInfo.data} /> }
      );
    }
  }, [userInfo?.data, uploaderSignedInToast]);

  useEffect(() => {
    if (hasUploaded && isInit) {
      setIsInit(false);
      if (signedIn) {
        addCollectionHistory(collectionId);
      }
      uploaderInit().then((res) => {
        setUserId(res.id);
        setUserName(res.name);
        setDeviceId(res.deviceId);

        if (!res.name) {
          setAskName(true);
        }
      });
    }
  }, [hasUploaded]);

  // useEffect(() => {
  //   if (!hasUploaded) {
  //     setIsUploading(!!uploadFilesInMemory.length);
  //   }
  // }, [uploadFilesInMemory.length]);

  useEffect(() => {
    if (window.showExtra) {
      if (!uploadFiles.length && uploadFilesInMemory.length && askName) {
        modalService
          .render(AskNameModal)
          .then((userName) => {
            if (userName) {
              setUserName(userName);
            }
          })
          .catch(() => {});
        setAskName(false);
      }
    }
  }, [uploadFiles.length]);

  useEffect(() => {
    document.body?.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }, [uploadFilesInMemory]);

  useEffect(() => {
    getCollectionInfo(collectionId)
      .then((collection) => {
        // if (collection?.pause) {
        //   location.href = "https://www.picme.com";
        // } else {
        setCollection(collection);
        // }
      })
      .catch((e) => {
        console.error("here", e);
      })
      .finally(() => {
        setLoadingCollection(false);
      });
  }, [collectionId]);

  const { imgUrl, imgUrlLoading } = useImgForm(collectionId, "cover");

  const uploadPhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const files = [...e.target.files];
    const filteredFiles = files.filter(({ name }) =>
      name.match(
        /\.(jpg|jpeg|png|gif|bmp|tiff|webp|heif|heic|mov|mp4|m4a|webm)$/i
      )
    );

    if (files.length !== filteredFiles.length) {
      // todo toast: only images are allowed to be uploaded
    }

    appState.set(({ uploaderHasUploaded }) => ({
      uploaderHasUploaded: { ...uploaderHasUploaded, [collectionId]: true },
    }));
    uploaderService.addFiles(
      files.map((file) => {
        const id = ulid();
        return {
          id,
          collectionId,
          thumbnail: null,
          file,
          upload: {
            progress: 0,
            error: null,
            status: "queue",
          },
        } as UploadFileType;
      }),
      collectionId,
      {
        generateThumbnails: true,
        keepInMemory: true,
        successToast: false,
      }
    );

    if (cameraRef.current?.value) {
      cameraRef.current.value = "";
    }
    if (filesRef.current?.value) {
      filesRef.current.value = "";
    }
  };

  const handleDelete = async (upload: UploadFileType) => {
    try {
      await deleteUpload(collectionId, upload.id, upload.ownershipToken);
      uploadFilesInMemory_delete(upload);
    } catch (e) {
      niceToastError("unable to delete this file", e);
    }
  };

  const handleAskName = async () => {
    modalService
      .render(AskNameModal)
      .then((userName) => {
        if (userName) {
          setUserName(userName);
        }
      })
      .catch(() => {});
  };

  const hasUploads = !isEmpty(uploadFilesInMemory);
  const isLoading = loadingCollection;

  if (isLoading) {
    return (
      <div className={s.loading}>
        <Loader />
      </div>
    );
  }

  if (!isLoading && !collection) {
    return (
      <div className={s.upErrorContainer}>
        <div className={s.upError}>
          <div>
            <Icon name="hexagon-exlamation" size={24} className="vermillion" />
          </div>
          <div>
            Collection doesn't exist, or you don't have the right permissions.
          </div>
        </div>
        <a href="https://picme.com" target="_blank" className="mb-16">
          <Logo size={40} />
        </a>
        {!hasUploaded && <UploaderInfo className={s.uploaderInfoBtn} />}
      </div>
    );
  }

  return (
    <>
      <div className={a(s.uploader).m(s.uploading, hasUploaded)}>
        {hasUploaded && (
          <AppHeaderBlank
            top={
              <div className={s.smallCoverImg}>
                {hasUploaded && (
                  <UploaderInfo className={s.uploaderInfoBtnSmall} />
                )}
                <CollectionCover
                  coverUrl={imgUrl as string}
                  loadingCoverUrl={imgUrlLoading}
                  size="sm"
                  coverIcon={collection?.coverIcon}
                  coverColor={collection?.coverColor}
                />
                <div className={s.smallTitle}>{collection.title}</div>
              </div>
            }
            scrollEl={document.body}
          />
        )}
        <div className={s.uploaderDisplay}>
          {!hasUploaded && <div className={s.blankSpace} />}

          {/*HEADER*/}
          <div className={s.uploaderBody}>
            {!hasUploaded && (
              <>
                <div className={s.coverImg}>
                  <CollectionCover
                    coverUrl={imgUrl as string}
                    loadingCoverUrl={imgUrlLoading}
                    size="lg"
                    coverIcon={collection?.coverIcon}
                    coverColor={collection?.coverColor}
                  />
                </div>
                <div className={s.title}>{collection.title}</div>
                {hasUploaded && (
                  <>
                    {signedIn ? (
                      <div style={{ flexShrink: 0 }}>
                        <Avatar user={userInfo.data} />
                      </div>
                    ) : (
                      <Button
                        as={Link}
                        to={`/login-or-signup?uploader-collection-id=${collectionId}`}
                        intent="tertiary"
                      >
                        Login
                      </Button>
                    )}
                  </>
                )}
                {collection?.message && !hasUploaded && (
                  <div className={s.message}>{collection?.message}</div>
                )}
              </>
            )}

            {/*GRID*/}
            <div className={s.grid}>
              {hasUploads && <CollectionGrid onDelete={handleDelete} />}
            </div>

            {!collection.pause ? (
              <>
                {/*UPLOAD BTN*/}
                <div className={s.uploadPhotosBtn}>
                  <Button
                    intent="primary-disco"
                    onClick={() => filesRef.current.click()}
                    size="lg"
                    block
                  >
                    Upload photos
                  </Button>
                </div>

                {/*TERMS*/}
                {!hasUploaded && (
                  <Terms className="pt-16">By uploading photos</Terms>
                )}
              </>
            ) : (
              <div style={{ fontSize: 16 }}>Uploading is paused</div>
            )}
          </div>

          {/*LOGO*/}
          {!hasUploaded && (
            <a href="https://picme.com" target="_blank" className="pb-20">
              <Logo size={40} />
            </a>
          )}
        </div>
      </div>
      <UploadPersistentToast />
      <input
        className={s.actionInput}
        ref={filesRef}
        type="file"
        multiple
        accept="image/*,video/*"
        onChange={uploadPhoto}
      />

      {!hasUploaded && <UploaderInfo className={s.uploaderInfoBtn} />}
    </>
  );
}

const { s, css } = cssModules`
  @use "sass:map";

  :global html {
    overflow: hidden;
    height: 100%;
  }

  :global body {
    overflow: auto;
    height: 100%;
    background: $grey-100;
  }
  
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploader {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100svh;
    transition: background 400ms ease;
  }

  .uploaderDisplay {
    padding: .8rem 2.4rem 2.4rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 40rem;
  }
  
  .uploaderInfoBtn {
    position: fixed;
    top: 1.6rem;
    left: 1.6rem;
  }
  
  .uploaderInfoBtnSmall {
    position: absolute;
    top: 1.6rem;
    left: 1.6rem;
  }

  .uploaderBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
  
  .smallTitle {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: .8rem;
  }
  
  .title {
    font-size: 2.4rem;
    font-weight: 700;
    text-align: center;
    border-radius: $border-radius-xs;
    display: inline-block;
    margin-bottom: .8rem;
  }

  .message {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: $text-secondary;
    max-width: 22rem;
    margin: 0 auto .8rem auto;
  }

  .actionInput {
    width: 0;
    height: 0;
    position: absolute;
    display: none;
  }

  .uploadPhotosBtn {
    width: 100%;
    padding-top: 2rem;
    max-width: 40rem;
    display: flex;
    align-items: center;
    gap: .8rem;
  }

  .coverImg {
    margin-bottom: 1.6rem;
  }
  
  .smallCoverImg {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    flex-direction: column;
    padding-top: 2.4rem;
    padding-bottom: .8rem;
    width: 100%;
    background-color: white;
    position: relative;
  }

  .uploading {
    &.uploader {
      padding: 0;
      display: block;
    }

    .uploaderDisplay {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 100%;
      height: auto;
    }

    .uploaderBody {
      display: flex;
      align-items: flex-start;
      max-width: 40rem;
    }

    .headerContainer {
      border-bottom: solid .1rem var(--app-border);
      padding: 1.2rem 2rem;
      min-height: 7.2rem;
      background-color: $grey-100;
      width: 100%;
    }

    .coverImg {
      margin-bottom: 0;
      flex-shrink: 0;
    }

    .uploadPhotosBtn {
      position: fixed;
      bottom: 2.4rem;
      right: 2.4rem;
      width: calc(100% - 4.8rem);
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.6rem;
    }

    .signedInAs {
      border-radius: $border-radius-full;
      background-color: $grey-100;
      padding: .4rem 1.2rem;
    }

    .extraActions {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .8rem;
    }

    .grid {
      padding: 0 0 7.6rem 0;
      width: 100%;
    }
  }

  .upErrorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.6rem;
  }

  .upError {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid .1rem var(--app-border);
    max-width: 36rem;
    padding: 2.4rem;
    border-radius: $border-radius-lg;
    text-align: center;
    gap: 1.6rem;
    font-size: 1.6rem;
  }
`;
