import React, { CSSProperties } from "react";
import { useCss, k, a } from "kremling";

import LogoSvg from "../assets/logo.svg";
import LogoSvgBw from "../assets/bwlogo.svg";

type Props = {
  className?: string;
  style?: CSSProperties;
  size?: number;
  bw?: boolean;
};

export function Logo(props: Props) {
  const { className, style, size, bw = false } = props;
  const scope = useCss(css);
  const Img = bw ? LogoSvgBw : LogoSvg;
  return (
    <div {...scope} className={a("app-logo", className)} style={style}>
      <Img style={size ? { width: size, height: size } : undefined} />
    </div>
  );
}

const css = k`
  .app-logo {
    display: inline-block;
  }

  svg {
    width: 100%;
  }
`;
