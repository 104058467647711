import React from "react";
import { createRoot } from "react-dom/client";

import "./styles/main.scss";
import "./utils/keyboard-intent";
import { Root } from "./root";
import { appState } from "./app-state";
import { refreshAccessToken } from "./services/api";

const root = createRoot(document.getElementById("app"));
window.showExtra = false;

async function initRefreshAccessToken() {
  try {
    await refreshAccessToken();
    appState.set({ appIsLoading: false });
  } catch (e) {}
}

(async () => {
  const refreshToken = appState.get("refreshToken");
  if (refreshToken) {
    initRefreshAccessToken();
  } else {
    appState.set({ appIsLoading: false });
  }
  root.render(<Root />);
})();
