import React, { CSSProperties, useState, useRef, useEffect } from "react";
import { useCss, k, a } from "kremling";
import ProfileImg from "../../assets/profile.svg";
import { Button } from "../../common/button/button";
import { Loader } from "../../common/loader/loader";
import { DefaultProfileImg } from "../default-profile-img";

type Props = {
  className?: string;
  style?: CSSProperties;
  label: string;
  onRemove: () => void;
  imageUrl: string;
  onChange: (file: File | undefined, rawFile: String | ArrayBuffer) => void;
  isLoading?: boolean;
  roundImg?: boolean;
};

export function ImageForm(props: Props) {
  const {
    className,
    style,
    label,
    onRemove,
    imageUrl,
    onChange,
    isLoading,
    roundImg,
  } = props;
  const scope = useCss(css);
  const imgElRef = useRef<HTMLInputElement>();
  const fileReaderRef = useRef(new FileReader());
  const [localImg, setLocalImg] = useState(null);

  useEffect(() => {
    if (imageUrl) {
      // // On initial page load, check if we already have an image
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          if (blob.type !== "application/xml") {
            // If it's an application/xml then the collection doesn't have an image (AWS returns an error message in the XML)
            // Also note that AWS seems to return the non-standard "binary/octet-stream" if the content type was not set.
            // Set image information into state.
            setLocalImg(imageUrl);
          }
        })
        .catch((e) => {
          setLocalImg(imageUrl);
        });
    }
  }, [imageUrl]);

  const addImage = () => {
    if (imgElRef?.current) {
      imgElRef.current.click();
    }
  };

  const handleNextImg = (nextImg: File) => {
    fileReaderRef.current.abort();
    fileReaderRef.current.readAsDataURL(nextImg);
    fileReaderRef.current.onloadend = () => {
      setLocalImg(fileReaderRef.current.result);
      onChange(nextImg, fileReaderRef.current.result);
    };
  };

  const handleDelete = async () => {
    setLocalImg(null);
    imgElRef.current.value = "";
    onRemove();
  };

  return (
    <div {...scope} style={style} className={className}>
      <label className="text-label">{label}</label>
      <div className="upload-collection-img">
        {localImg ? (
          <div className="collection-img-container">
            <div
              className={a("collection-img")
                .m("has-img", localImg)
                .m("round-img", roundImg)}
              style={{ marginRight: 34, backgroundImage: `url("${localImg}")` }}
            />
            {isLoading && (
              <div className="collection-img-loader">
                <Loader size="sm" fill="light" />
              </div>
            )}
          </div>
        ) : (
          <div className="collection-img-profile">
            <DefaultProfileImg />
          </div>
        )}
        <div className="collection-image-action-container">
          <Button
            intent="tertiary-flat"
            size="sm"
            onClick={addImage}
            isLoading={isLoading}
          >
            Upload image
          </Button>
          {!!localImg && (
            <Button intent="tertiary-flat" size="sm" onClick={handleDelete}>
              Reset
            </Button>
          )}
          <input
            ref={imgElRef}
            className="collection-image-upload"
            type="file"
            onChange={(e) => handleNextImg(e.target.files[0])}
          />
        </div>
      </div>
    </div>
  );
}

const css = k`
  .upload-collection-img {
    display: flex;
    margin-bottom: 2.4rem;
    gap: 1.6rem;
    padding-top: .4rem;

    .collection-image-action-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: .4rem;
      text-align: left;
    }

    .collection-image-upload {
      display: none;
    }
  }

  .collection-img-container {
    width: 9.6rem;
    height: 9.6rem;
    position: relative;
  }
  
  .collection-img-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($raisin-500, .5);
    border-radius: 1.2rem;
  }
  
  .collection-img {
    width: 9.6rem;
    height: 9.6rem;
    border: solid .1rem #d3d3d3;
    border-radius: 1.2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.has-img {
      border: none;
    }
    
    &.round-img {
      border-radius: $border-radius-full;
    }
  }
  
  .collection-img-profile {
    width: 9.6rem;
    height: 9.6rem;
  }
`;
