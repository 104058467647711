import React, { ReactNode, CSSProperties } from "react";
import { useCss, k, a } from "kremling";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
};

export function ModalBody(props: Props) {
  const { children, className, style } = props;
  const scope = useCss(css);
  return (
    <div {...scope} className={a("modal-body", className)} style={style}>
      {children}
    </div>
  );
}

const css = k`
  .modal-body {
    margin-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    overflow: auto;
  }
`;
