import React, {
  ElementType,
  forwardRef,
  ReactNode,
  ComponentProps,
  useMemo,
} from "react";
import { a, k, useCss } from "kremling";
import { Icon, IconProps } from "../icon/icon";
import { Loader, LoaderProps } from "../loader/loader";
import { LinkProps, To } from "react-router-dom";

interface IconOnlyProps extends Omit<IconProps, "name"> {
  name?: Icons;
}

export type ButtonProps = ComponentProps<"button"> &
  ComponentProps<"a"> &
  Omit<LinkProps, "to"> & {
    active?: boolean;
    alignText?: "left" | "center";
    as?: ElementType | string;
    asProps?: Record<string, any>;
    block?: boolean | "spread";
    blockOnMobile?: boolean;
    blockIndent?: number;
    children?: ReactNode;
    dropdown?: boolean;
    iconLeft?: Icons | ReactNode;
    iconLeftPadding?: "sm" | "md";
    iconLeftProps?: IconProps;
    iconRight?: Icons;
    iconRightPadding?: "sm" | "md";
    iconRightProps?: IconProps;
    iconOnly?: Icons | (() => ReactNode);
    iconCustom?: boolean;
    iconOnlyProps?: IconOnlyProps;
    isLoading?: boolean;
    intent?:
      | "primary"
      | "primary-disco"
      | "secondary"
      | "secondary-grey"
      | "tertiary"
      | "tertiary-flat"
      | "tertiary-grey"
      | "tertiary-transparent"
      | "flat"
      | "danger";
    loaderProps?: LoaderProps;
    size?: "sm" | "md" | "lg";
    to?: To;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function ButtonComponent(props, ref) {
    const {
      active,
      alignText = "center",
      as,
      asProps,
      block,
      blockOnMobile,
      blockIndent,
      children,
      dropdown,
      className,
      disabled,
      iconLeft,
      iconLeftPadding = "md",
      iconLeftProps,
      iconRight,
      iconRightPadding = "md",
      iconRightProps,
      iconOnly,
      iconCustom,
      iconOnlyProps,
      intent = "tertiary-grey",
      isLoading,
      loaderProps,
      size = "md",
      type,
      ...btnProps
    } = props;
    const Component = as || "button";
    const scope = useCss(css);

    const calculatedLoaderFill = useMemo<LoaderProps["fill"]>(() => {
      if (loaderProps?.fill) return loaderProps.fill;
      switch (intent) {
        case "primary":
        case "primary-disco":
        case "danger":
          return "light";
        case "secondary":
        case "secondary-grey":
        case "tertiary":
        case "tertiary-grey":
          return "darkLight";
      }
    }, [intent]);

    return (
      <Component
        {...scope}
        role="button"
        type={type || "button"}
        className={a("button", className)
          .m("button__active", active)
          .m("button__align-left", alignText === "left")
          .m("button__block", block)
          .m("button__block-on-mobile", blockOnMobile)
          .m("button__block-spread", block === "spread")
          .m("button__disabled", disabled)
          .m("button__dropdown", dropdown)
          .m("button__icon-only", iconOnly)
          .m("button__icon-custom", iconCustom)
          .m("button__icon-left-md", iconLeft && iconLeftPadding === "md")
          .m("button__icon-right-md", iconRight && iconRightPadding === "md")
          .m("button__icon-left-sm", iconLeft && iconLeftPadding === "sm")
          .m("button__icon-right-sm", iconRight && iconRightPadding === "sm")
          .m("button__loading", isLoading)
          .m("button__primary", intent === "primary")
          .m("button__primary-disco", intent === "primary-disco")
          .m("button__secondary", intent === "secondary")
          .m("button__secondary-grey", intent === "secondary-grey")
          .m("button__flat", intent === "flat")
          .m("button__tertiary", intent === "tertiary")
          .m("button__tertiary-flat", intent === "tertiary-flat")
          .m("button__tertiary-grey", intent === "tertiary-grey")
          .m("button__tertiary-transparent", intent === "tertiary-transparent")
          .m("button__danger", intent === "danger")
          .m("button__small", size === "sm")
          .m("button__medium", size === "md")
          .m("button__large", size === "lg")}
        disabled={disabled || isLoading}
        {...btnProps}
        {...asProps}
        ref={ref}
      >
        {isLoading && (
          <span className="button__loading">
            <Loader
              size={size === "md" ? "sm" : "xs"}
              {...loaderProps}
              fill={calculatedLoaderFill}
            />
          </span>
        )}
        {iconLeft || iconRight || isLoading ? (
          <span className="btn-content">
            {block && blockIndent && (
              <div
                style={{ width: `${blockIndent * 1.2}rem`, flexShrink: 0 }}
              />
            )}
            {typeof iconLeft === "string" && (
              <Icon
                name={iconLeft}
                size={20}
                {...iconLeftProps}
                fill={intent === "secondary-grey" ? "#7519e3" : undefined}
              />
            )}
            {typeof iconLeft !== "string" && iconLeft}
            <span className="ellipsis">{children}</span>
            {iconRight && (
              <Icon
                name={iconRight}
                size={20}
                fill={intent === "secondary-grey" ? "#7519e3" : undefined}
                {...iconRightProps}
              />
            )}
          </span>
        ) : iconOnly ? (
          typeof iconOnly === "function" ? (
            iconOnly()
          ) : (
            <Icon
              name={iconOnly}
              size={size === "sm" ? 14 : 20}
              fill={intent === "secondary-grey" ? "#7519e3" : undefined}
              {...iconOnlyProps}
            />
          )
        ) : (
          <>{block ? <span className="ellipsis">{children}</span> : children}</>
        )}
        {dropdown && (
          <Icon
            name="caret-down-solid"
            size={14}
            fill="#7519e3"
            style={{ marginLeft: 8, marginTop: -2 }}
          />
        )}
      </Component>
    );
  }
);

const css = k`
  .button {
    color: var(--app-text);
    font-family: $base-font-family;
    font-weight: 700;
    background-color: inherit;
    border: 0;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    transition: box-shadow $form-transition-duration ease,
    background-color $form-transition-duration ease,
    color $form-transition-duration ease;
    -webkit-font-smoothing: antialiased;
    
    &.button__loading {
      position: relative;
    }
    
    .icon {
      flex-shrink: 0;
    }

    &:hover {
      text-decoration: none;
    }

    &:disabled {
      cursor: default;
    }

    .btn-content {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .button__loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // icon-only
  // ---------------------------
  .button__icon-only {
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  // ALIGN LEFT
  // ---------------------------
  .button__align-left {
    text-align: left;
    justify-content: flex-start;
  }

  // BLOCK
  // ---------------------------
  .button__block {
    display: flex;
    width: 100%;
    
    &.button__block-spread .btn-content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }
  
  // BLOCK ON MOBILE
  .button__block-on-mobile {
    display: flex;
    width: 100%;
    @include breakpoint(lg) {
      display: inline-flex;
      width: auto;
    }
  }
  
  // DROPDOWN
  // ---------------------------
  .button__dropdown {
    justify-content: space-between;
  }

  // LOADER
  // ---------------------------
  .button__loading .btn-content {
    opacity: 0;
  }

  // size = "sm"
  .button__small {
    height: $form-size-sm;
    padding: 0 $form-padding-sm;
    font-size: $form-text-sm;
    border-radius: $border-radius-full;

    @include focus-ring {
      border-radius: $border-radius-full;
    }

    &.button__icon-left-md {
      padding-left: .8rem;
      svg {
        margin-right: .8rem;
      }
    }
    &.button__icon-right-md {
      padding-right: .8rem;
      svg {
        margin-left: .8rem;
      }
    }
    &.button__icon-left-sm {
      padding-left: .8rem;
      svg {
        margin-right: .4rem;
      }
    }
    &.button__icon-right-sm {
      padding-right: .8rem;
      svg {
        margin-left: .4rem;
      }
    }
    &.button__icon-only, &.button__icon-custom {
      height: $form-size-sm;
      width: $form-size-sm;
      padding: 0;
    }
  }

  // size = "md"
  // ---------------------------
  .button__medium {
    height: $form-size-md;
    padding: 0 $form-padding-md;
    font-size: $form-text-md;
    border-radius: $border-radius-full;

    @include focus-ring {
      border-radius: $border-radius-full;
    }

    &.button__icon-left-md {
      padding-left: 1.2rem;

      > .btn-content > span {
        margin-left: 1rem;
      }
    }
    &.button__icon-right-md {
      padding-right: 1.2rem;

      > .btn-content > span {
        margin-right: 1rem;
      }
    }
    &.button__icon-left-sm {
      padding-left: 1.2rem;

      > .btn-content > span {
        margin-left: .6rem;
      }
    }
    &.button__icon-right-sm {
      padding-right: 1.2rem;

      > .btn-content > span {
        margin-right: .6rem;
      }
    }
    &.button__icon-only, &.button__icon-custom {
      height: $form-size-md;
      width: $form-size-md;
      padding: 0;
    }
  }

  // size = "lg"
  // ---------------------------
  .button__large {
    height: $form-size-lg;
    padding: 0 $form-padding-lg;
    font-size: $form-text-lg;
    border-radius: $border-radius-full;

    @include focus-ring {
      border-radius: $border-radius-full;
    }

    &.button__icon-left-md {
      padding-left: 1.2rem;

      > .btn-content > span {
        margin-left: 1rem;
      }
    }
    &.button__icon-right-md {
      padding-right: 1.2rem;

      > .btn-content > span {
        margin-right: 1rem;
      }
    }
    &.button__icon-left-sm {
      padding-left: 1.2rem;

      > .btn-content > span {
        margin-left: .6rem;
      }
    }
    &.button__icon-right-sm {
      padding-right: 1.2rem;

      > .btn-content > span {
        margin-right: .6rem;
      }
    }
    &.button__icon-only, &.button__icon-custom {
      height: $form-size-lg;
      width: $form-size-lg;
      padding: 0;
    }
  }

  // ROUND
  // ---------------------------
  .button__round {
    border-radius: $border-radius-full;
  }


  // intent="primary"
  // ---------------------------
  @include generateThemeVariables(
    (
      // primary
      button-primary-bg: $violet,
      button-primary-color: $grey-100,

      button-primary-hover-bg: $violet-600,
      button-primary-active-bg: $violet-700,
      button-primary-disabled-bg: (
        light: rgba($violet, .5),
        dark: desaturate($violet, 50%),
      ),
      button-primary-disabled-color: (
        light: rgba($grey-100, .7),
        dark: rgba($grey-100, .5),
      ),
    )
  );

  .button__primary {
    box-shadow: $shadow-btn;
    background-color: var(--button-primary-bg);
    color: var(--button-primary-color);

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover {
        background-color: var(--button-primary-hover-bg);
      }

      &:active {
        background-color: var(--button-primary-active-bg);
      }
    }

    &:disabled {
      background-color: var(--button-primary-disabled-bg);
      color: var(--button-primary-disabled-color);
    }

    &.button__active {
      background-color: var(--button-primary-active-bg);
    }
    
    @include focus-ring-extend {
      border-color: $grey-100;
      inset: .3rem;
      border-radius: $border-radius-full;
    }
  }

  @include generateThemeVariables(
    (
      // primary-disco
      button-primary-disco-bg: linear-gradient(134deg, #0014CB -9.07%, #FA00FF 108.26%),
      button-primary-disco-color: $grey-100,

      button-primary-disco-hover-bg: linear-gradient(134deg, #0014CB -9.07%, #FA00FF 108.26%),
      button-primary-disco-active-bg: linear-gradient(134deg, #0014CB -9.07%, #FA00FF 108.26%),
      button-primary-disco-disabled-bg: (
        light: linear-gradient(134deg, #0014CB -9.07%, #FA00FF 108.26%),
        dark: linear-gradient(134deg, #0014CB -9.07%, #FA00FF 108.26%),
      ),
      button-primary-disco-disabled-color: (
        light: rgba($grey-100, .7),
        dark: rgba($grey-100, .5),
      ),
    )
  );
  
  .button__primary-disco {
    box-shadow: $shadow-btn;
    background: var(--button-primary-disco-bg);
    color: var(--button-primary-disco-color);

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover {
        background-color: var(--button-primary-disco-hover-bg);
      }

      &:active {
        background-color: var(--button-primary-disco-active-bg);
      }
    }

    &:disabled {
      background-color: var(--button-primary-disco-disabled-bg);
      color: var(--button-primary-disco-disabled-color);
    }

    &.button__active {
      background-color: var(--button-primary-disco-active-bg);
    }

    @include focus-ring-extend {
      border-color: $grey-100;
      inset: .3rem;
      border-radius: $border-radius-full;
    }
  }

  // intent="secondary"
  // ---------------------------
  @include generateThemeVariables(
    (
      // secondary
      button-secondary-bg: (
        light: rgba($violet, .1),
        dark: rgba($violet, .2),
      ),
      button-secondary-color: (
        light: $violet,
        dark: $grey-100,
      ),
      button-secondary-hover-bg: (
        light: rgba($violet, .2),
        dark: rgba($violet, .3),
      ),
      button-secondary-active-bg: (
        light: rgba($violet, .3),
        dark: rgba($violet, .3),
      ),
      button-secondary-disabled-color: (
        light: rgba($violet, .5),
        dark: rgba($grey-100, .5),
      ),
    )
  );
  .button__secondary {
    background-color: var(--button-secondary-bg);
    color: var(--button-secondary-color);

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover {
        background-color: var(--button-secondary-hover-bg);
      }

      &:active {
        background-color: var(--button-secondary-active-bg);
      }
    }

    &:disabled {
      color: var(--button-secondary-disabled-color);
    }

    &.button__active {
      background-color: var(--button-secondary-active-bg);
    }
  }

  // intent="secondary-grey"
  // ---------------------------
  @include generateThemeVariables(
    (
      // secondary grey
      button-secondary-grey-color: (
        light: $raisin-100,
        dark: $grey-100,
      ),
      button-secondary-grey-border: $purple-50,
      button-secondary-grey-bg: #ffffff,
      button-secondary-grey-hover-bg: $purple-10,
      button-secondary-grey-active-bg: $purple-25,
      button-secondary-grey-disabled-color: rgba($raisin-700, .3),
    )
  );
  .button__secondary-grey {
    color: var(--button-secondary-grey-color);
    border: solid .1rem var(--button-secondary-grey-border);
    background-color: var(--button-secondary-grey-bg);

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover {
        background-color: var(--button-secondary-grey-hover-bg);
      }

      &:active {
        background-color: var(--button-secondary-grey-active-bg);
      }
    }

    &:disabled {
      color: var(--button-secondary-grey-disabled-color);
    }

    &.button__active {
      background-color: var(--button-secondary-grey-active-bg);
    }
    
    @include focus-ring-extend {
      inset: -.1rem;
    }
  }

  // intent="flat"
  // ---------------------------
  @include generateThemeVariables(
      (
        // flat
        button-flat-bg: transparent,
        button-flat-color: $violet,
        button-flat-hover-bg: rgba($raisin-500, .1),
        button-flat-active-bg: rgba($raisin-500, .2),
        button-flat-disabled-color: rgba($violet, .5),
      )
  );
  .button__flat {
    background-color: var(--button-flat-bg);
    border: solid .1rem var(--button-flat-border);
    color: var(--button-flat-color);

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover {
        background-color: var(--button-flat-hover-bg);
      }

      &:active {
        background-color: var(--button-flat-active-bg);
      }
    }

    &:disabled {
      color: var(--button-flat-disabled-color);
    }

    &.button__active {
      background-color: var(--button-flat-active-bg);
    }

    @include focus-ring-extend {
      inset: -.1rem;
    }
  }


  // intent="tertiary"
  // ---------------------------
  @include generateThemeVariables(
    (
      // tertiary
      button-tertiary-bg: $grey-100,
      button-tertiary-color: $violet,
      button-tertiary-border: var(--app-border),
      button-tertiary-hover-bg: (
        light: $grey-200,
        dark: rgba($violet, .2),
      ),
      button-tertiary-active-bg: (
        light: $grey-300,
        dark: rgba($violet, .3),
      ),
      button-tertiary-disabled-color: (
        light: rgba($violet, .5),
        dark: rgba($violet, .5),
      ),
    )
  );
  .button__tertiary {
    box-shadow: $shadow-btn;
    background-color: var(--button-tertiary-bg);
    border: solid .1rem var(--button-tertiary-border);
    color: var(--button-tertiary-color);

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover {
        background-color: var(--button-tertiary-hover-bg);
      }

      &:active {
        background-color: var(--button-tertiary-active-bg);
      }
    }

    &:disabled {
      color: var(--button-tertiary-disabled-color);
    }

    &.button__active {
      background-color: var(--button-tertiary-active-bg);
    }

    @include focus-ring-extend {
      inset: -.1rem;
    }
  }

  // intent="tertiary-flat"
  // ---------------------------
  @include generateThemeVariables(
      (
        // tertiary-flat
        button-tertiary-flat-bg: $grey-100,
        button-tertiary-flat-color: $violet,
        button-tertiary-flat-hover-bg: (
          light: $grey-200,
          dark: rgba($violet, .2),
        ),
        button-tertiary-flat-active-bg: (
          light: $grey-300,
          dark: rgba($violet, .3),
        ),
        button-tertiary-flat-disabled-color: (
          light: rgba($violet, .5),
          dark: rgba($violet, .5),
        ),
      )
  );
  .button__tertiary-flat {
    background-color: var(--button-tertiary-flat-bg);
    color: var(--button-tertiary-flat-color);

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover {
        background-color: var(--button-tertiary-flat-hover-bg);
      }

      &:active {
        background-color: var(--button-tertiary-flat-active-bg);
      }
    }

    &:disabled {
      color: var(--button-tertiary-flat-disabled-color);
    }

    &.button__active {
      background-color: var(--button-tertiary-flat-active-bg);
    }

    @include focus-ring-extend {
      inset: -.1rem;
    }
  }


  // intent="tertiary-grey"
  // ---------------------------
  @include generateThemeVariables(
    (
      button-tertiary-grey-bg: transparent,
      button-tertiary-grey-color: $text,
      button-tertiary-grey-hover-bg: rgba(#151566, .05),
      button-tertiary-grey-active-bg: rgba(#171753, .06),
      button-tertiary-grey-disabled-color: rgba($raisin-100, .5),
    )
  );
  .button__tertiary-grey {
    color: var(--button-tertiary-grey-color);

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover:not(:active):not(.active):not(.button__active) {
        background-color: var(--button-tertiary-grey-hover-bg);
      }

      &:active {
        background-color: var(--button-tertiary-grey-active-bg);
      }
    }

    &:disabled {
      color: var(--button-tertiary-grey-disabled-color);
    }

    &.button__active, &.active {
      background-color: var(--button-tertiary-grey-active-bg);
    }
  }

  // intent="tertiary-transparent"
  // ---------------------------
  @include generateThemeVariables(
      (
        button-tertiary-transparent-bg: rgba($raisin-900, .3),
        button-tertiary-transparent-color: $grey-100,
        button-tertiary-transparent-hover-bg: rgba($raisin-100, .1),
        button-tertiary-transparent-active-bg: rgba($raisin-100, .2),
        button-tertiary-transparent-disabled-color: rgba($raisin-100, .5),
      )
  );
  .button__tertiary-transparent {
    background-color: var(--button-tertiary-transparent-bg);
    color: var(--button-tertiary-transparent-color);

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover {
        background-color: var(--button-tertiary-transparent-hover-bg);
      }

      &:active {
        background-color: var(--button-tertiary-transparent-active-bg);
      }
    }

    &:disabled {
      color: var(--button-tertiary-transparent-disabled-color);
    }

    &.button__active {
      background-color: var(--button-tertiary-transparent-active-bg);
    }
  }

  // intent="danger"
  // ---------------------------
  @include generateThemeVariables(
    (
      button-danger-bg: $vermillion,
      button-danger-color: $grey-100,
      button-danger-hover-bg: $vermillion-600,
      button-danger-active-bg: $vermillion-700,
      button-danger-disabled-bg: (
        light: lighten($vermillion, 15%),
        dark: desaturate($vermillion, 50%),
      ),
      button-danger-disabled-color: rgba($grey-100, .6),
    )
  );
  .button__danger {
    box-shadow: $shadow-btn;
    background-color: var(--button-danger-bg);
    color: var(--button-danger-color);

    @include focus-ring-extend {
      border-color: $vermillion-700;
    }

    &:not(:disabled):not(.button__active):not(.button__loading) {
      &:hover {
        background-color: var(--button-danger-hover-bg);
      }

      &:active {
        background-color: var(--button-danger-active-bg);
      }
    }

    &:disabled {
      background-color: var(--button-danger-disabled-bg);
      color: var(--button-danger-disabled-color);
    }

    &.button__active {
      background-color: var(--button-danger-active-bg);
    }
  }

`;
