import React, {
  ReactNode,
  CSSProperties,
  useMemo,
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import { useCss, k } from "kremling";
import { AnimatePresence, motion, useSpring } from "framer-motion";
import { forEach } from "lodash";
import { appState } from "../../app-state";

const transition = {
  duration: 0.2,
};

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  structure: {
    id: string;
    renderContent: ({
      onNext,
      onBack,
    }: {
      onNext: () => void;
      onBack: () => void;
    }) => ReactNode;
  }[];
  activeId: string;
  onChange: (nextActiveId: string) => void;
};

export function ContentSwitcher(props: Props) {
  const { structure, activeId, onChange } = props;
  const [height, setHeight] = useState(100);
  const itemRefs = useRef([]);
  const scope = useCss(css);

  const index = useMemo(() => {
    return structure.findIndex((s) => s.id === activeId);
  }, [structure, activeId]);

  useLayoutEffect(() => {
    const rect = itemRefs.current[index]?.getBoundingClientRect();
    if (rect?.height) {
      setHeight(rect.height);
    }
  }, [index]);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { height } = entry.contentRect;
        itemRefs.current.forEach((item, i) => {
          if (item === entry.target && i === index) {
            setHeight(height);
          }
        });
      }
    });

    if (itemRefs.current.length) {
      itemRefs.current.forEach((item) => {
        if (item) {
          observer.observe(item);
        }
      });
    }

    return () => {
      if (itemRefs.current) {
        itemRefs.current.forEach((item) => {
          if (item) {
            observer.unobserve(item);
          }
        });
      }
    };
  }, [index]);

  const next = () => {
    if (index + 1 <= structure.length - 1) {
      onChange(structure[index + 1].id);
    }
  };

  const back = () => {
    if (index > 0) {
      onChange(structure[index - 1].id);
    }
  };

  return (
    <AnimatePresence initial={false}>
      <div {...scope} style={{}}>
        <motion.div
          style={{
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
          animate={{ height }}
          transition={transition}
        >
          {structure.map((s, i) => {
            const isActive = i === index;
            return (
              isActive && (
                <motion.div
                  key={s.id}
                  ref={(el) => (itemRefs.current[i] = el)}
                  style={{
                    width: "100%",
                    position: "absolute",
                  }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    top: isActive ? 0 : "50%",
                    x: 0,
                    y: isActive ? 0 : "-50%",
                    opacity: isActive ? 1 : 0,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  transition={transition}
                >
                  {s.renderContent({ onNext: next, onBack: back })}
                </motion.div>
              )
            );
          })}
        </motion.div>
      </div>
    </AnimatePresence>
  );
}

const css = k``;
