import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useState,
} from "react";
import { useCss, k } from "kremling";

export type PhotoViewerSlideRef = {};

type SlideVideoProps = {
  src: string;
  setVideoIsLoading: (videoIsLoading: boolean) => void;
  active: boolean;
};

export const SlideVideo = forwardRef<PhotoViewerSlideRef, SlideVideoProps>(
  (props, ref) => {
    const { src, setVideoIsLoading, active } = props;
    const [[naturalWidth, naturalHeight], setNaturalSize] = useState([0, 0]);
    const videoRef = useRef<HTMLVideoElement>();
    const scope = useCss(css);

    const onLoadedMetadata = () => {
      setNaturalSize([
        videoRef.current.videoWidth,
        videoRef.current.videoHeight,
      ]);
      setVideoIsLoading(false);
    };

    useImperativeHandle(ref, () => ({}));

    useEffect(() => {
      if (active) {
        videoRef.current.play().catch(() => {});
      } else {
        videoRef.current.pause();
      }
    }, [active]);

    return (
      <div {...scope} className="photo-viewer-video">
        <video
          ref={videoRef}
          src={src}
          controls
          autoPlay
          onLoadedMetadata={onLoadedMetadata}
          style={{
            maxWidth: `min(${naturalWidth}px, 100%)`,
            maxHeight: `min(${naturalHeight}px, 100%)`,
          }}
        />
      </div>
    );
  }
);

const css = k`
  .photo-viewer-video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    
    video {
      //height: 100%;
      //width: 100%;
      object-fit: contain;
    }
  }
`;
