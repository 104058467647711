import React, { useState, ChangeEvent } from "react";
import { useCss, k } from "kremling";
import { Icon } from "../../common/icon/icon";
import { Button } from "../../common/button/button";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { uploaderService } from "../../utils/uploader-service";
import { ulid } from "ulid";

type Props = {
  collectionId: string;
  onUpload: () => void;
};

export const PlusMenu = ({ collectionId, onUpload }: Props) => {
  const scope = useCss(css);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const uploadInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div {...scope} className="plus-menu-wrapper">
      <AnimatePresence>
        {showMenu && (
          <motion.div
            initial={{ opacity: 0, y: 10, x: 10 }}
            animate={{ opacity: 1, y: 0, x: 0 }}
            exit={{ opacity: 0, y: 10, x: 10 }}
            transition={{ duration: 0.2, ease: "easeIn" }}
            className="plus-menu-backdrop"
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showMenu && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2, ease: "easeIn" }}
            className="plus-menu"
          >
            <PlusMenuButton
              onClick={() => {
                navigate(`/collection/${collectionId}/request`);
              }}
              label="Request photos"
              icon="qrcode-regular"
            />
            <PlusMenuButton
              onClick={() => {
                onUpload();
                setShowMenu(false);
              }}
              label="Upload photos"
              icon="circle-arrow-up-regular"
              delay={0.1}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <Button
        intent="primary-disco"
        onClick={() => setShowMenu((prevState) => !prevState)}
        className="plus-menu-btn"
      >
        <motion.div
          animate={{ rotate: showMenu ? 45 : 0 }}
          transition={{ duration: 0.2, ease: "easeIn" }}
        >
          <Icon name="plus-regular" size={30} />
        </motion.div>
      </Button>
    </div>
  );
};

type BtnProps = {
  label: string;
  icon: Icons;
  onClick?: () => void;
  delay?: number;
};

const PlusMenuButton = ({ label, icon, onClick, delay }: BtnProps) => {
  return (
    <motion.div
      className="menu-btn"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2, y: 10, delay, ease: "easeIn" }}
    >
      <Button onClick={onClick} block iconLeft={icon} intent="secondary-grey">
        {label}
      </Button>
    </motion.div>
  );
};

const css = k`
  
  .plus-menu-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    padding: 2.4rem;
    z-index: 300
  }
  
  .plus-menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    border-radius: 100vh;
  }
  
  .plus-menu {
    display: flex;
    flex-direction: column;
    padding: 0 1.2rem;
  }
  
  .menu-btn {
    width: 18.4rem;
    margin-top: 1.2rem;
  }

  .plus-menu-backdrop {
    background: linear-gradient(160deg, rgba(187, 78, 253, 0.00) 23.7%, rgba(164, 86, 253, 0.64) 49.32%, rgba(49, 119, 255, 0.90) 91.72%);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 452px;
    flex-shrink: 0;
  }
  
  @include breakpoint(sm) {
    
    .plus-menu-backdrop {
      //Bigger
      //background: linear-gradient(160deg, rgba(187, 78, 253, 0) 53.7%, rgba(164, 86, 253, 0.64) 79.32%, rgba(49, 119, 255, 0.9) 101.72%);
      
      //Smaller
      background: linear-gradient(138deg, rgba(187, 78, 253, 0) 63.7%, rgba(164, 86, 253, 0.64) 89.32%, rgba(49, 119, 255, 0.9) 101.72%);

      width: 100vh;
      height: 50dvh;
    }
  }

`;
