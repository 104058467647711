import React, { useEffect, useRef, useState } from "react";
import { useCss, k } from "kremling";
import { useNavigate, useParams } from "react-router-dom";
import { ulid } from "ulid";

import { CollectionDisplay } from "../../../components/collection-display/collection-display";
import { MenuItem } from "../../../common/menu";
import {
  collectionColors,
  collectionIcons,
} from "../../../components/create-collection-modal/collection-assets";
import { Textarea } from "../../../common/textarea/textarea";
import { Toggle } from "../../../common/toggle/toggle";
import { Button } from "../../../common/button/button";
import { dialog } from "../../../common/dialog/dialog";
import { deleteCollection, updateCollection } from "../../../services/api";
import {
  removeCollectionFromList,
  updateCollectionMeta,
} from "../../../utils/url";
import { niceToastError } from "../../../utils/utils";
import { collectionListQueryKey } from "../../../queries";
import { queryClient } from "../../../query-client";
import { useImgForm } from "../../../hooks/use-image-form";
import { modalService } from "../../../common/modal-service/modal-service";
import { CollectionSettingsChooserModal } from "./collection-settings-chooser-modal";
import { useDebounceValue } from "../../../hooks/use-debounce-value";
import { Saving } from "../../../components/saving/saving";
import { RefetchQueryFilters } from "@tanstack/react-query";
import { Input } from "../../../common/input/input";

type Props = {
  title?: string;
  message?: string;
  coverIcon?: string;
  coverColor?: string;
  pause?: boolean;
};

export function CollectionSettingsForm(props: Props) {
  const scope = useCss(css);
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const {
    title: _title = "",
    message: _message = "",
    coverIcon: _coverIcon = "",
    coverColor: _coverColor = "",
    pause: _pause = false,
  } = props;

  const [metaSaving, setMetaSaving] = useState(false);
  const [title, setTitle] = useState(_title);
  const [message, setMessage] = useState(_message);
  const [coverIcon, setCoverIcon] = useState(_coverIcon);
  const [coverColor, setCoverColor] = useState(_coverColor);
  const [pause, setPause] = useState(_pause);

  const textSerialized =
    title + message + coverIcon + coverColor + JSON.stringify(pause);
  const textSerializedRef = useRef(textSerialized);

  const debouncedValues = useDebounceValue(textSerialized, 1000);

  useEffect(() => {
    if (textSerialized !== textSerializedRef.current) {
      updateMeta();
    }
    textSerializedRef.current = textSerialized;
  }, [debouncedValues]);

  const {
    imgUrl,
    imgUrlLoading,
    imgDelete,
    imgChoose,
    isImgSaving,
    imgRenderInput,
    imgClearLocal,
  } = useImgForm(collectionId, "cover", { saveOnChange: true });

  const resetIconColor = () => {
    setCoverIcon(collectionIcons[0]);
    setCoverColor(collectionColors[0]);
  };

  const updateMeta = async () => {
    if (!title.length) return;
    setMetaSaving(true);
    await updateCollection(collectionId, {
      title,
      message,
      pause,
      coverIcon,
      coverColor,
    });
    await updateCollectionMeta(collectionId, {
      title,
      message,
      pause,
      coverIcon,
      coverColor,
    });
    setMetaSaving(false);
  };

  const handleDeleteCollection = async () => {
    dialog({
      title: "Delete Collection",
      submitText: "Delete Collection",
      description: (
        <>
          <p>Are you sure you want to delete this collection?</p>
          <p className="text-error">This action cannot be undone.</p>
        </>
      ),
      onSubmitCallback: async () => {
        try {
          await deleteCollection(collectionId);
          await removeCollectionFromList(collectionId);
          navigate("/collection");
        } catch (e) {
          niceToastError(null, e);
        }
        await queryClient.refetchQueries(
          collectionListQueryKey() as RefetchQueryFilters
        );
      },
    });
  };

  const renderIconColorChooser = async () => {
    const response = await modalService.render(CollectionSettingsChooserModal, {
      coverIcon,
      coverColor,
    });

    if (response) {
      if (imgUrl) {
        imgClearLocal();
        imgDelete();
      }
      setCoverIcon(response.coverIcon);
      setCoverColor(response.coverColor);
    }
  };

  const saving = metaSaving || isImgSaving;

  return (
    <div {...scope}>
      <div className="mb-32">
        <Saving saving={saving} />
      </div>

      <div className="mb-24">
        <label className="text-label">
          Collection title<span className="text-error">*</span>
        </label>
        <Input value={title} onChange={setTitle} required error={!title} />
      </div>

      <div className="mb-24">
        <CollectionDisplay
          coverUrl={imgUrl as string}
          coverIcon={coverIcon}
          coverColor={coverColor}
          loadingCoverUrl={imgUrlLoading}
          onChooseImg={imgChoose}
          onChooseIcon={renderIconColorChooser}
          onReset={() => {
            if (imgUrl) {
              imgClearLocal();
              imgDelete();
            }
            resetIconColor();
          }}
        />
        {imgRenderInput}
      </div>

      <div className="mb-24">
        <label className="text-label">Message to Guest uploader</label>
        <Input value={message} onChange={setMessage} />
      </div>

      <div className="setting">
        <div className="setting-content">
          <div className="setting-title">Pause uploads</div>
          <div className="setting-description">
            Don't allow photos to be uploaded to this collection
          </div>
        </div>
        <div className="setting-action">
          <Toggle
            checked={pause}
            onChange={setPause}
            activeBackgroundColor="danger"
          />
        </div>
      </div>
      <div className="setting">
        <div className="setting-content">
          <div className="setting-title">Delete collection</div>
          <div className="setting-description">
            Delete this collection with all its photos
          </div>
        </div>
        <div className="setting-action">
          <Button intent="tertiary" onClick={handleDeleteCollection} size="lg">
            Delete collection
          </Button>
        </div>
      </div>
    </div>
  );
}

const css = k`

`;
