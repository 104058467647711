import React, { useEffect, useState } from "react";
import { useCss, k } from "kremling";
import { ModalHeader } from "../common/modal/modal-header";
import { Button } from "../common/button/button";
import { Icon } from "../common/icon/icon";
import { useGetCollectionQuery } from "../queries";
import { useShareSearchParams } from "../hooks/use-share-search-params";
import { Loader } from "../common/loader/loader";
import { connectSharedCollection } from "../services/api";
import toast from "react-hot-toast";
import { modalService } from "../common/modal-service/modal-service";
import { useNavigate } from "react-router-dom";
import { ModalBody } from "../common/modal/modal-body";
import { ModalFooter } from "../common/modal/modal-footer";

type Props = {
  modalResolve?: (data?: any) => void;
};

export function InvitationModal(props: Props) {
  const { modalResolve } = props;
  const scope = useCss(css);
  const { shareParamsObj } = useShareSearchParams();
  const [shareParams, setShareParams] = useState(null);
  const [sharingLoading, setSharingLoading] = useState(false);
  const navigate = useNavigate();

  const collectionQuery = useGetCollectionQuery(shareParams?.collectionId);

  useEffect(() => {
    setShareParams(shareParamsObj);
    navigate(`${location.pathname}`);
  }, []);

  const handleShare = async () => {
    try {
      setSharingLoading(true);
      await connectSharedCollection(shareParams?.authorization);
      setSharingLoading(false);
      toast.success("Successfully accepted invitation!");
      return modalResolve(shareParams.collectionId);
    } catch (e) {
      toast.error(
        "An error occurred trying to accept the invitation. Please try again later."
      );
    }
    modalResolve();
  };

  useEffect(() => {
    modalService.disableModalExit(sharingLoading);
  }, [sharingLoading]);

  return (
    <>
      <ModalHeader>Accept Invitation</ModalHeader>
      <ModalBody>
        <div {...scope} className="text-center">
          {collectionQuery.isLoading ? (
            <div className="mb-16">
              <Loader />
            </div>
          ) : (
            <>
              <div>You've been invited to the PicMe Collection:</div>
              <div className="collection-name">
                {collectionQuery.data?.title}
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="button-group">
          <Button
            block
            intent="secondary-grey"
            onClick={() => modalResolve()}
            disabled={sharingLoading}
          >
            Decline
          </Button>
          <Button
            block
            intent="primary"
            onClick={handleShare}
            isLoading={sharingLoading}
          >
            Accept
          </Button>
        </div>
      </ModalFooter>
    </>
  );
}

const css = k`
  .collection-name {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2.4rem;
  }
`;
