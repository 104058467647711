import React, { ReactNode, useState, useEffect } from "react";
import { useCss, k } from "kremling";
import AppLogo from "../../assets/logo.svg";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { useAppState } from "../../app-state";

type Props = {
  children?: ReactNode;
};

export function AppLoader(props: Props) {
  const { children } = props;
  const scope = useCss(css);
  const [scale, setScale] = useState(1);
  const { appIsLoading } = useAppState(["appIsLoading"]);

  useEffect(() => {
    const pulse = () => {
      setScale(1.2);
      setTimeout(() => setScale(1), 800);
      setTimeout(pulse, 1600);
    };
    pulse();
  }, []);

  if (appIsLoading) {
    return (
      <LazyMotion features={domAnimation}>
        <div {...scope} className="app-loader-container">
          <m.div
            className="app-loader"
            animate={{ scale }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
          >
            <AppLogo className="app-loader-logo" />
            <div className="app-loader-caption">capture experiences</div>
          </m.div>
        </div>
      </LazyMotion>
    );
  }

  return <>{children}</>;
}

const css = k`
  .app-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .app-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .app-loader-logo {
    width: 6.2rem;
    margin-bottom: 1rem;
  }
  
  .app-loader-caption {
    color: #8E0DE8;
    font-weight: 700;
  }
`;
