import React, { useEffect, useMemo, useState } from "react";
import { useCss, k, a } from "kremling";

import { Icon } from "../../../common/icon/icon";
import { modalService } from "../../../common/modal-service/modal-service";
import { RetryModal } from "./retry-modal";
import { isVideo } from "../utils/utils";
import { MediaPreview } from "../../../components/media-preview";
import { getGradientByIndex } from "../utils/gradients-util";
import { UploadFileType, useAppState } from "../../../app-state";
import { uploaderService } from "../../../utils/uploader-service";
import { useIsTouchscreen } from "../../../hooks/use-is-touchscreen";
import { dialog } from "../../../common/dialog/dialog";
import { collectionIdKey } from "../../auth/auth-utils";
import { useParams } from "react-router-dom";

type Props = {
  className?: string;
  onDelete?: (upload: UploadFileType) => Promise<void>;
};

export function CollectionGrid(props: Props) {
  const { className, onDelete } = props;
  const { uploadFilesInMemory: _files } = useAppState(["uploadFilesInMemory"]);
  const scope = useCss(css);
  const isTouchScreen = useIsTouchscreen();
  const [deleteId, setDeleteId] = useState<string>();
  const { collectionId } = useParams();

  const files = _files[collectionId];

  const retry = async (id: string) => {
    await modalService.render(RetryModal);
    uploaderService.upload(id);
  };

  useEffect(() => {
    function removeId() {
      setDeleteId(null);
    }
    document.body.addEventListener("click", removeId);
    return () => {
      document.body.removeEventListener("click", removeId);
    };
  }, [deleteId]);

  const deletePhoto = async (file: UploadFileType) => {
    setDeleteId(null);
    dialog({
      title: "Delete photo",
      description: "Are you sure you want to delete this photo?",
      submitText: "Delete",
      onSubmitCallback: async () => {
        return await onDelete(file);
      },
    });
  };

  const hideMarkedAsDeleted = useMemo(() => {
    return files.filter((f) => !f.markedAsDeleted);
  }, [files]);

  return (
    <div {...scope} className={a("collection-grid", className)}>
      <div className="grid">
        {hideMarkedAsDeleted.map((file, index) => {
          const isUploading = file.upload.status === "upload";
          const isQueued = file.upload.status === "queue";
          const isError = file.upload.status === "error";
          return (
            <div
              key={file.id}
              className="item"
              style={{
                background: isVideo(file.file.name)
                  ? getGradientByIndex(index)
                  : null,
              }}
            >
              {/*Icon is only visible if there is no preview image*/}
              {isVideo(file.file.name) && (
                <Icon
                  name="video-regular"
                  className="default-thumbnail__image"
                  size={50}
                />
              )}
              <div
                className={a("thumbnail")
                  .m("--blur", isError || isQueued || isUploading)
                  .m("thumbnail-hover", !isTouchScreen)}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isTouchScreen) {
                    if (deleteId === file.id) {
                      deletePhoto(file);
                    } else {
                      setDeleteId(file.id);
                    }
                  } else {
                    deletePhoto(file);
                  }
                }}
              >
                <MediaPreview index={0} file={file} />
                <div
                  className="thumbnail-trash-overlay"
                  style={deleteId === file.id ? { opacity: 1 } : null}
                >
                  <Icon name="trash" size={24} />
                </div>
              </div>
              {isUploading && (
                <div className="item-progress">
                  <div style={{ width: `${file.upload.progress * 100}%` }} />
                </div>
              )}
              {isError && (
                <div className="item-error" onClick={() => retry(file.id)}>
                  <Icon name="triangle-exlamation-solid" size={30} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const css = k`
  .collection-grid {
    width: 100%;
  }
  .collection-grid__title {
    font-weight: 700;
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: .4rem;
    grid-column-start: revert;
    width: 100%;
  }
  
  .item {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    background: $grey-400;
    // border-radius: $border-radius-sm;
    animation: slideInDown 300ms linear;
    overflow: hidden;
    user-select: none;
    
    .default-thumbnail__image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: rgba(255, 255, 255, .5);
    }
    
    img, video {
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    
    .thumbnail {
      opacity: 1;
      transform: scale(1);
      transition: transform 500ms ease, filter 500ms ease, opacity 500ms ease;
      
      > * {
        padding-bottom: 100%;
      }
      
      &.--blur {
        opacity: .5;
        filter: blur(2.8px);
        transform: scale(1.05);
      }
    }
    
    .thumbnail-trash-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: -100%;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($raisin-400, .7);
      color: $grey-100;
      opacity: 0;
      transition: opacity 200ms ease;
    }
    
    .thumbnail-hover:hover {
      cursor: pointer;
      .thumbnail-trash-overlay {
        opacity: 1;
      }
    }
  }
  
  .item-progress {
    position: absolute;
    height: .6rem;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($grey-500, .5);
    
    > div {
      position: absolute;
      left: 0;
      width: 0;
      height: .6rem;
      background-color: $violet;
      border-radius: 0 0.6rem 0.6rem;
    }
  }
  
  .item-error {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $vermillion-500;
  }

  @keyframes slideInDown {
    0% {
      opacity: 0;
      margin-top: -5rem;
    }
    100% {
      opacity: 1;
      margin-top: 0;
    }
  }

`;
