import React, {
  forwardRef,
  ReactNode,
  useMemo,
  useRef,
  useState,
  InputHTMLAttributes,
} from "react";
import { useCss, k, a } from "kremling";

import { Icon } from "../icon/icon";
import { Button } from "../button/button";
import { mergeRefs } from "../../utils/merge-refs";

export type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange"
> & {
  error?: string | boolean;
  iconLeft?: Icons;
  iconRight?: Icons;
  actions?: ReactNode;
  label?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  value?: string;
  buttonRight?: ReactNode;
  seePassword?: boolean;
  rounded?: boolean;
  scale?: "md" | "lg";
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  function InputComponent(props, ref) {
    const {
      label,
      className,
      onChange,
      error,
      iconLeft,
      iconRight,
      actions,
      onClear,
      value,
      required,
      buttonRight,
      seePassword,
      rounded,
      type,
      size,
      scale,
      ...inputProps
    } = props;
    const innerRef = useRef<HTMLInputElement>();
    const scope = useCss(css);
    const [see, setSee] = useState(false);

    const handleClear = () => {
      onClear();
      innerRef?.current?.focus();
    };

    const generatedType = useMemo(() => {
      if (type === "password" && seePassword) {
        return see ? "text" : "password";
      }
      return type;
    }, [type, seePassword, see]);

    return (
      <div
        {...scope}
        className={a("input", className)
          .m("input--error", error)
          .m("input--icon-left", iconLeft)
          .m("input--icon-right", iconRight)
          .m("input--can-clear", onClear)
          .m("input--button-right", buttonRight)
          .m("input--see-password", seePassword)
          .m("input--rounded", rounded)
          .m("input--lg", scale === "lg")}
      >
        {label && <label className="text-label">{label}</label>}
        <div className="input-container">
          <input
            ref={mergeRefs([ref, innerRef])}
            onChange={(e) => onChange?.(e.target.value)}
            value={value || ""}
            type={generatedType}
            {...inputProps}
          />
          {iconLeft && (
            <div className="input__icon-left">
              <Icon name={iconLeft} size={16} />
            </div>
          )}
          {actions && <div className="input__right-actions">{actions}</div>}
          {seePassword && (
            <div className="input__right-btn">
              <Button
                iconOnly={see ? "eye-solid" : "eye-slash-solid"}
                onClick={() => setSee(!see)}
                size="sm"
              />
            </div>
          )}
          {!!onClear ? (
            <div className="input__right-btn">
              <Button
                iconOnly="xmark-regular"
                onClick={handleClear}
                size="sm"
              />
            </div>
          ) : (
            iconRight && (
              <div className="input__icon-right">
                <Icon name={iconRight} size={16} />
              </div>
            )
          )}
        </div>
        {buttonRight && (
          <div className="input__button-right">{buttonRight}</div>
        )}
        {error && <div className="text-error input__error">{error}</div>}
      </div>
    );
  }
);

const css = k`
  @include generateThemeVariables((
    input-bg: $grey-100,
    input-icon-fill: (
      light: $grey-900,
      dark: $grey-900,
    ),
  ));

  ::placeholder {
    color: $grey-700;
  }

  .input {
    position: relative;

    input {
      border: solid 1px var(--app-border);
      border-radius: $border-radius-md;
      height: $form-size-md;
      box-sizing: border-box;
      padding: 0 $form-padding-md;
      display: block;
      width: 100%;
      transition: box-shadow $form-transition-duration ease;
      background-color: var(--input-bg);
      color: var(--app-text);
      font-size: inherit;
      
      &:focus {
        outline: none;
        box-shadow: none;
      }
      
      &:disabled, &:read-only {
        cursor: default;
      }
    }

    &.input--rounded {
      input {
        border-radius: $border-radius-full;
      }
      .input-container {
        @include focus-ring-within-extend {
          border-radius: $border-radius-full;
        }
      }
    }


    &.input--error {
      label {
        color: $vermillion;
      }
      .input-container {
        @include focus-ring-within-extend {
          border-color: $vermillion;
        }
      }
    }

    &.input--icon-left input {
      padding-left: 36px;
    }
    
    &.input--icon-right input {
      padding-right: 36px;
    }

    &.input--can-clear input {
      padding-right: 36px;
    }
    
    &.input--button-right {
      display: flex;
      align-items: center;
      
      .input-container {
        flex-grow: 1;

        @include focus-ring-within-extend {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          inset: 0 -.1rem 0 0;
        };
      }
      input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .input-container {
    position: relative;
    @include focus-ring-within {
      border-radius: $border-radius-md;
    };
  }
  
  .input__label {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .input__extra-info {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
    background-color: $grey-300;
    padding: .8rem;
    border-radius: $border-radius-md;
    line-height: 1.5;
  }

  .input__error {
    font-size: 12px;
    padding-top: 4px;
  }

  .input__icon-left {
    position: absolute;
    top: 0;
    height: $form-size-md;
    width: $form-size-md;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--input-icon-fill);
  }
  
  .input--see-password {
    input {
      padding-right: 4.8rem;
    }
  }
  
  .input__right-actions {
    position: absolute;
    height: $form-size-md;
    top: 0;
    right: .4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $violet;
  }

  .input__right-btn {
    position: absolute;
    height: 100%;
    width: $form-size-md;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--input-icon-fill);
  }
  
  .input__button-right {
    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      
      @include focus-ring-extend {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  
  .input__icon-right {
    position: absolute;
    top: 0;
    right: 0;
    height: $form-size-md;
    width: $form-size-md;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--input-icon-fill);
  }
  
  .input--lg input {
    height: $form-size-lg;
  }
`;
