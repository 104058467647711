import React from "react";

import { useAppState } from "../../app-state";
import { PersistentToast } from "../persistent-toast";
import { ToastOptions } from "react-hot-toast";
import { UploadPersistentToastInner } from "./upload-persistent-toast-inner";

type UploadPersistentToastProps = {
  toastProps?: ToastOptions;
};

export function UploadPersistentToast(props: UploadPersistentToastProps) {
  const { uploadFiles } = useAppState(["uploadFiles"]);

  return (
    !!uploadFiles.length && (
      <PersistentToast id="upload-box" {...props.toastProps}>
        <UploadPersistentToastInner />
      </PersistentToast>
    )
  );
}
