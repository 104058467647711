import React, { useEffect, useState } from "react";

import {
  useGetCollectionInfoQuery,
  useCollectionShareAuthQuery,
  useGetCollectionSharingMetadataQuery,
  useGetUserInfoQuery,
} from "../../../queries";
import { useCollection } from "../collection";
import { CollectionShareForm } from "./collection-share-form";
import { OverlayPage } from "../../../components/overlay-page/overlay-page";
import { Loader } from "../../../common/loader/loader";

export const CollectionShare = () => {
  const { collection } = useCollection();
  const collectionInfoQuery = useGetCollectionInfoQuery(collection.id);
  const userInfoQuery = useGetUserInfoQuery();

  const [share, setShare] = useState(false);
  const collectionShareAuthQuery = useCollectionShareAuthQuery(
    collection.id,
    share
  );
  const getCollectionSharingMetadataQuery =
    useGetCollectionSharingMetadataQuery(
      collection.id,
      collectionInfoQuery?.data?.sharingMetadataUri
    );

  useEffect(() => {
    setShare(getCollectionSharingMetadataQuery?.data);
  }, [getCollectionSharingMetadataQuery?.data]);

  const encodeMore = (str: string) => {
    return str
      ? encodeURIComponent(str).replace(/\(/g, "%28").replace(/\)/g, "%29")
      : "";
  };

  const shareUrl = `${location.origin}/share/${collection.id}/${encodeMore(
    collectionShareAuthQuery?.data?.authorization
  )}/${collectionShareAuthQuery?.data?.referrerUserId}`;

  const isLoading =
    collectionShareAuthQuery?.isLoading ||
    !collectionInfoQuery?.data?.sharingMetadataUri;

  return (
    <OverlayPage title="Share Collection">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 50,
          }}
        >
          <Loader />
        </div>
      ) : (
        <CollectionShareForm
          sharingInfo={collectionShareAuthQuery?.data}
          sharees={collectionShareAuthQuery?.data?.sharees}
          metadata={collection.metadata}
          userInfo={userInfoQuery.data}
          share={share}
          setShare={setShare}
          collectionId={collection.id}
          shareUrl={shareUrl}
          refetch={collectionShareAuthQuery.refetch}
        />
      )}
    </OverlayPage>
  );
};
