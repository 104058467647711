import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { LogIn } from "./pages/auth/log-in";
import { ConfirmEmail } from "./pages/auth/confirm-email";
import { FinishAccount } from "./pages/auth/finish-account";
import { Collection } from "./pages/collection/collection";
import { CollectionSettings } from "./pages/collection/collection-settings/collection-settings";
import { CollectionRequest } from "./pages/collection/collection-request";
import { CheckAuth } from "./pages/check-auth";
import { LoginOrSignup } from "./pages/auth/login-or-signup";
import { Account } from "./pages/account/account";
import { AccountPlan } from "./pages/account/account-plan";
import { AccountReferrals } from "./pages/account/account-referrals";
import { ProtectedRoute } from "./components/protected-route";
import { CreateNewAccount } from "./pages/auth/create-new-account";
import { ShareRedirect } from "./pages/auth/share-redirect";
import { ReferRedirect } from "./pages/auth/refer-redirect";
import { ForgotPassword } from "./pages/auth/forgot-password";
import { ResetPassword } from "./pages/auth/reset-password";
import { useGetUserInfoQuery } from "./queries";
import { CollectionShare } from "./pages/collection/collection-share/collection-share";
import { useAppState } from "./app-state";
import { routerEventObs } from "./utils/route-utils";
import { AccountProfile } from "./pages/account/account-profile";
import { CollectionTrashList } from "./pages/collection/photos/collection-trash-list";
import { CollectionPhotosList } from "./pages/collection/photos/collection-photos-list";
import { AccountPasswordAndSecurity } from "./pages/account/account-password-and-security";
import { Uploader } from "./pages/up/uploader";

export const AppRoutes = () => {
  const { signedIn } = useAppState(["signedIn"]);
  useGetUserInfoQuery(signedIn);
  const navigate = useNavigate();

  useEffect(() => {
    routerEventObs.subscribe((routerEvent) => {
      if (routerEvent.type === "navigate") {
        navigate(...routerEvent.arguments);
      }
    });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<CheckAuth />} />
      <Route
        path="/share/:collectionId?/:authorization?/:referrerUserId?"
        element={<ShareRedirect />}
      />
      <Route path="/refer/:referrerUserId?" element={<ReferRedirect />} />
      <Route path="/login-or-signup" element={<LoginOrSignup />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/create-new-account" element={<CreateNewAccount />} />
      <Route path="/sign-up/confirm-email" element={<ConfirmEmail />} />
      <Route path="/sign-up/finish-account" element={<FinishAccount />} />

      {/* up */}
      <Route path="/up/:collectionId" element={<Uploader />} />

      {/* collection */}
      <Route
        path="/collection"
        element={
          <ProtectedRoute>
            <Collection />
          </ProtectedRoute>
        }
      >
        <Route path=":collectionId" element={<CollectionPhotosList />} />
        <Route path=":collectionId/settings" element={<CollectionSettings />} />
        <Route path=":collectionId/request" element={<CollectionRequest />} />
        <Route path=":collectionId/share" element={<CollectionShare />} />
        <Route path=":collectionId/trash" element={<CollectionTrashList />} />
      </Route>
      <Route
        path="/account"
        element={
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        }
      >
        <Route path="profile" element={<AccountProfile />} />
        <Route
          path="password-and-security"
          element={<AccountPasswordAndSecurity />}
        />
        <Route path="plan" element={<AccountPlan />} />
        <Route path="referrals" element={<AccountReferrals />} />
      </Route>
    </Routes>
  );
};
