import React, { useEffect, useMemo, useRef, useState } from "react";
import { a } from "kremling";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { Squircle } from "../squircle";
import { ulid } from "ulid";
import {
  collectionColors,
  deprecatedColorMap,
} from "../create-collection-modal/collection-assets";
import { Icon } from "../../common/icon/icon";

export type CollectionCoverProps = {
  className?: string;
  coverUrl?: string;
  size?: "xs" | "sm" | "md" | "lg" | number;
  coverIcon?: string;
  coverColor?: string;
  loadingCoverUrl?: boolean;
};

export function CollectionCover(props: CollectionCoverProps) {
  const {
    className,
    coverUrl,
    size = "md",
    coverColor,
    coverIcon,
    loadingCoverUrl,
  } = props;
  useCssModules(css);

  const [urlError, setUrlError] = useState(false);
  const shapeIdRef = useRef(null);

  useEffect(() => {
    setUrlError(false);
  }, [coverUrl]);

  useEffect(() => {
    if (!shapeIdRef.current) {
      shapeIdRef.current = ulid();
    }
  }, []);

  const calcSize = useMemo(() => {
    switch (size) {
      case "lg":
        return 160;
      case "md":
        return 100;
      case "sm":
        return 40;
      case "xs":
        return 28;
      default:
        return size;
    }
  }, [size]);

  const iconSize = calcSize / 2.2;

  if (loadingCoverUrl)
    return (
      <Squircle size={size} shapeId={shapeIdRef.current}>
        <div
          className={a(s.collectionCoverEmpty)}
          style={{
            borderRadius: `${(calcSize * 0.25) / 10}rem`,
            width: `${calcSize / 10}rem`,
            height: `${calcSize / 10}rem`,
          }}
        />
      </Squircle>
    );

  return (
    <Squircle size={size} shapeId={shapeIdRef.current}>
      {coverUrl && !urlError ? (
        <img
          alt=""
          className={a(className, s.collectionCover)}
          style={{
            borderRadius: `${(calcSize * 0.25) / 10}rem`,
            width: `${calcSize / 10}rem`,
            height: `${calcSize / 10}rem`,
          }}
          src={coverUrl}
          onError={(e) => {
            (e.target as HTMLImageElement).src =
              "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
            setUrlError(true);
          }}
        />
      ) : (
        <div
          className={a(className, s.collectionCover)}
          style={{
            borderRadius: `${(calcSize * 0.25) / 10}rem`,
            width: `${calcSize / 10}rem`,
            height: `${calcSize / 10}rem`,
            background:
              (coverColor?.startsWith("#")
                ? deprecatedColorMap[coverColor]
                : coverColor) || collectionColors[0],
          }}
        >
          <Icon size={iconSize} name="img-stack" fill="#fff" />
        </div>
      )}
    </Squircle>
  );
}

const { s, css } = cssModules`
  .collectionCover {
    overflow: hidden;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .collectionCoverEmpty {
    background-color: $grey-300;
    flex-shrink: 0;
    border-radius: .8rem;
  }
`;
