import React, { ReactNode, CSSProperties } from "react";
import { useCss, k, a, m } from "kremling";
import { CollectionCover } from "./collection-cover/collection-cover";
import {
  collectionColors,
  collectionIcons,
} from "./create-collection-modal/collection-assets";
import { Button } from "../common/button/button";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  coverIcon?: (typeof collectionIcons)[number];
  coverColor?: (typeof collectionColors)[number];
  onChange: (opts: { coverIcon: string; coverColor: string }) => void;
};

export function CoverIconColorChooser(props: Props) {
  const { className, style, coverIcon, coverColor, onChange } = props;
  const scope = useCss(css);
  return (
    <div {...scope} className={className} style={style}>
      <div className="icon-preview">
        <CollectionCover
          coverIcon={coverIcon}
          coverColor={coverColor}
          size="md"
        />
      </div>
      {/*<div className="collection-icon-grid mb-32">*/}
      {/*  {collectionIcons.map((icon) => (*/}
      {/*    <div key={icon} className="collection-icon-grid-item">*/}
      {/*      <Button*/}
      {/*        intent="secondary-grey"*/}
      {/*        iconOnly={icon}*/}
      {/*        size="lg"*/}
      {/*        className={m("active-ring", coverIcon === icon)}*/}
      {/*        onClick={() => {*/}
      {/*          onChange({ coverIcon: icon, coverColor });*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}

      <div className="collection-icon-grid mb-4">
        {collectionColors.map((color, index) => (
          <div key={color} className="collection-icon-grid-item">
            <button
              onClick={() => {
                onChange({ coverIcon, coverColor: color });
              }}
              className={a("collection-icon-color").m(
                "active-ring",
                coverColor === color ||
                  (!collectionColors.includes(coverColor) && index === 0)
              )}
              style={{ background: color }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const css = k`
  .icon-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 3.2rem auto;
  }
  .collection-cover {
    padding: 0 3.2rem 3.2rem 3.2rem;
  }

  .collection-icon-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    row-gap: 2rem;
  }

  .collection-icon-grid-item {
    display: flex;
    justify-content: center;
  }

  .collection-icon-color {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 1.6rem;
    position: relative;
    border: none;

    @include focus-ring {
      border-radius: $border-radius-full;
    }
  }
  
  .active-ring {
    border-color: transparent;
    box-shadow: 0 0 0 .3rem rgba($violet, .5);
  }

`;
