import React from "react";
import { Button } from "../../../common/button/button";
import { SlideType } from "../photo-viewer-types";
import { useGetSlideQuery } from "../../../queries";

type Props = {
  slide: SlideType;
};

export function SlideDownload(props: Props) {
  const { slide } = props;
  const { isLoading, data } = useGetSlideQuery(slide?.id, slide?.src);

  const handleDownloadPhoto = async () => {
    const link = document.createElement("a");
    link.href = data.downloadUrl;
    link.download = data.downloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      intent="tertiary-transparent"
      iconOnly="arrow-down-to-line-regular"
      onClick={handleDownloadPhoto}
      disabled={isLoading}
    />
  );
}
