import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  MouseEvent,
} from "react";
import { useCss, k, a } from "kremling";
import { CollectionCover } from "./collection-cover";
import { Icon } from "../../common/icon/icon";
import { useImgForm } from "../../hooks/use-image-form";
import { Button } from "../../common/button/button";
import PicmeHamburger from "../../assets/picme-hamburger.svg";

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children"> & {
  collectionId?: string;
  coverColor?: string;
  coverIcon?: string;
};

export const CollectionCoverBtn = forwardRef<HTMLButtonElement, Props>(
  function CollectionCoverBtn(props, ref) {
    const { className, collectionId, coverColor, coverIcon, ...rest } = props;
    const scope = useCss(css);
    const { imgUrl, imgUrlLoading } = useImgForm(collectionId, "cover");

    if (!collectionId)
      return (
        <Button
          ref={ref}
          onClick={(e) => rest.onClick(e as MouseEvent<HTMLButtonElement>)}
          iconOnly={() => <PicmeHamburger style={{ width: 18 }} />}
        />
      );

    return (
      <button
        {...scope}
        {...rest}
        className={a("collection-cover-btn-img", className)}
        ref={ref}
      >
        <CollectionCover
          coverUrl={imgUrl}
          loadingCoverUrl={imgUrlLoading}
          size="sm"
          coverColor={coverColor}
          coverIcon={coverIcon}
        />
        <div className="collection-cover-btn-img-indicator">
          <Icon name="hamburger-menu" fill="#fff" size={10} />
        </div>
      </button>
    );
  }
);

const css = k`
  .collection-cover-btn-img {
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    position: relative;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    @include focus-ring {
      border-radius: $border-radius-xl;
      inset: -.4rem;

    }
  }

  .collection-cover-btn-img-indicator {
    width: 1.8rem;
    height: 1.8rem;
    background-color: $raisin-75;
    border-radius: $border-radius-full;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid .1rem $grey-100;
    position: absolute;
    bottom: -.2rem;
    right: -.2rem;
  }

`;
