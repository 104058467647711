import { Subject } from "rxjs";
import { NavigateOptions, To, useNavigate } from "react-router-dom";

type RouterEventNavigate = {
  type: "navigate";
  arguments: [To, NavigateOptions];
};

const routerEvent$ = new Subject<RouterEventNavigate>();
export const routerEventObs = routerEvent$.asObservable();

export function routerNavigate(to: To, options?: NavigateOptions) {
  routerEvent$.next({ type: "navigate", arguments: [to, options] });
}
