import React, { CSSProperties, useMemo } from "react";
import { copyToClipboard, shareUrl } from "../../utils/url";
import { Button } from "../../common/button/button";
import { k, maybe, toggle, useCss } from "kremling";

type Props = {
  className?: string;
  style?: CSSProperties;
  url?: string;
  title?: string;
  text?: string;
};

export function UrlBox(props: Props) {
  const { url, title, text } = props;
  const share = navigator.share;
  const scope = useCss(css);

  const displayUrl = useMemo(() => {
    return url.slice(url.includes("localhost") ? 7 : 8);
  }, [url]);

  return (
    <div {...scope}>
      <div className="url-box">
        <div className="url-box__url">{displayUrl}</div>
      </div>

      <div className="url-box__actions">
        <Button
          intent="secondary-grey"
          className={maybe("url-box__desktop-only", share)}
          iconOnly="copy"
          onClick={() => copyToClipboard(url)}
        />
        {share && (
          <Button
            intent="secondary-grey"
            onClick={() => {
              shareUrl(url, title, text);
            }}
            iconOnly="share-arrow-up"
          />
        )}
      </div>

      {/*<div>*/}
      {/*  <Button*/}
      {/*    intent="tertiary-grey"*/}
      {/*    block*/}
      {/*    onClick={() => {*/}
      {/*      if (share) {*/}
      {/*        if (onShare) {*/}
      {/*          onShare();*/}
      {/*        } else {*/}
      {/*          shareUrl(url, title, text);*/}
      {/*        }*/}
      {/*      } else {*/}
      {/*        copyToClipboard(url);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*    iconRight={share ? "share-arrow-up" : "copy"}*/}
      {/*    iconRightProps={{ name: share ? "share-arrow-up" : "copy" }}*/}
      {/*  >*/}
      {/*    {url}*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </div>
  );
}

const css = k`
  .url-box {
    display: flex;
    gap: .8rem;
    align-items: center;
    font-weight: 600;
    padding: 0 1.6rem;
    border: solid .1rem var(--app-border);
    border-radius: .8rem;
    height: 4rem;
    margin-bottom: .8rem;
  }

  .url-box__url {
    overflow: hidden;
    text-overflow: ellipsis;

  }

  .url-box__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .8rem;
    margin-bottom: 3.2rem;
  }

  @include breakpointMax(sm) {
    .url-box__desktop-only {
      display: none;
    }
  }

`;
