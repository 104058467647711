import React, { ReactNode, CSSProperties, useState, useRef } from "react";
import { useCss, k } from "kremling";
import toast from "react-hot-toast";

import { BackgroundCard } from "../../components/background-card";
import { InputField } from "../../common/input/input-field";
import { Button } from "../../common/button/button";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { authTypeKey, betaKey, emailKey, sessionEmailKey } from "./auth-utils";
import { AuthTitle } from "../../components/auth-title";
import { signUpUser } from "../../services/api";
import { useShareSearchParams } from "../../hooks/use-share-search-params";
import { ShareBanner } from "../../components/share-banner";
import { getAuthText, getAuthType } from "../../utils/validation";
import { Checkbox } from "../../common/checkbox/checkbox";
import { CheckboxField } from "../../common/checkbox/checkbox-field";
import { appState } from "../../app-state";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
};

const matchAllowedBetaCodes = (val: string) =>
  ["picme2024"].some((code) => code.toLowerCase() === val.toLowerCase());

export function CreateNewAccount(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const scope = useCss(css);
  const [topError, setTopError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { shareParamsStr, shareParamsObj } = useShareSearchParams();
  const [authType, setAuthType] = useState(localStorage.getItem(authTypeKey));

  const { handleSubmit, control } = useForm({
    defaultValues: {
      emailOrPhoneNumber: sessionStorage.getItem(sessionEmailKey) || "",
      allowSms: false,
      password: "",
      betaCode: localStorage.getItem(betaKey) || "",
    },
  });

  const submit = async ({
    emailOrPhoneNumber,
    password,
    betaCode,
  }: {
    emailOrPhoneNumber: string;
    password: string;
    betaCode: string;
  }) => {
    setIsLoading(true);
    appState.set({ temporaryAuth: password });
    localStorage.setItem(betaKey, betaCode || "picme2024");
    localStorage.setItem(emailKey, emailOrPhoneNumber);
    localStorage.setItem(authTypeKey, getAuthType(emailOrPhoneNumber));
    try {
      await signUpUser(
        emailOrPhoneNumber,
        password,
        true,
        null,
        shareParamsObj.referrerUserId
      );
      toast("Verification code sent.");
      navigate(`/sign-up/confirm-email${shareParamsStr}`);
    } catch (e) {
      const res = e?.response;
      if (res?.status === 401 || res?.status === 409) {
        navigate(`/login${shareParamsStr}`);
      }
    }
    setIsLoading(false);
  };

  return (
    <form {...scope} onSubmit={handleSubmit(submit)} noValidate>
      <BackgroundCard>
        <AuthTitle
          backCallback={() => navigate(`/login-or-signup${shareParamsStr}`)}
          disableBackCallback={isLoading}
        >
          Create new account?
        </AuthTitle>
        <ShareBanner />
        <div className="mb-24">
          {!!topError ? (
            <div className="text-error">{topError}</div>
          ) : (
            <div className="text-secondary">
              We couldn't find an account with that {getAuthText(authType)}. To
              create an account, enter a password and click Continue for a
              verification code.
            </div>
          )}
        </div>

        <div className="mb-16">
          <InputField
            label="Email or Phone Number"
            placeholder="Email or Phone Number"
            control={control}
            fieldName="emailOrPhoneNumber"
            type="emailOrPhoneNumber"
            required
            scale="lg"
            onChange={(text) => {
              const nextAuthType = getAuthType(text);
              if (authType !== nextAuthType) {
                setAuthType(getAuthType(text));
              }
            }}
          />
        </div>

        {authType === "phoneNumber" && (
          <div className="phone-tos">
            <CheckboxField
              control={control}
              fieldName="allowSms"
              required
              label="By clicking this box, I agree that PicMe may send me information about its services, offers, and my account by text message. Message frequency may vary and standard messaging rates may apply."
            />
          </div>
        )}

        <div className="mb-20">
          <InputField
            required
            control={control}
            fieldName="password"
            placeholder="Password"
            label="Password"
            type="password"
            validatePasswordRules
            seePassword
            scale="lg"
          />
        </div>

        {!shareParamsStr && (
          <div className="beta-code mb-24">
            <div>
              <InputField
                required
                control={control}
                fieldName="betaCode"
                label="Beta code"
                placeholder="Beta code"
                scale="lg"
                rules={[
                  {
                    errorMessage: "Invalid code",
                    validate: (v: any) => matchAllowedBetaCodes(v),
                  },
                ]}
              />
            </div>
          </div>
        )}

        <div className="text-secondary mb-20 text-center">
          By clicking Continue, I agree to the PicMe
          <br />
          <a href="https://www.picme.com/terms-of-use.html" target="_blank">
            Terms of Use
          </a>{" "}
          and{" "}
          <a href="http://www.picme.com/privacy-policy.html" target="_blank">
            Privacy Policy
          </a>{" "}
          .
        </div>

        <div className="button-group">
          <Button
            as={Link}
            to={`/login-or-signup${shareParamsStr}`}
            intent="tertiary"
            block
            disabled={isLoading}
            size="lg"
          >
            Cancel
          </Button>
          <Button
            intent="primary"
            block
            isLoading={isLoading}
            type="submit"
            size="lg"
          >
            Continue
          </Button>
        </div>
      </BackgroundCard>
    </form>
  );
}

const css = k`
  .beta-code {
    background-color: $violet-25;
    border: solid .1rem $violet-50;
    padding: 1.6rem;
    border-radius: $border-radius-md;
  }
  
  .phone-tos {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2.4rem;
    
    input {
      margin-top: .4rem;
      margin-right: 1.6rem;
    }
  }
`;
