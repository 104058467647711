import React, { useState } from "react";
import { ModalHeader } from "../../../common/modal/modal-header";
import { ModalBody } from "../../../common/modal/modal-body";
import { CoverIconColorChooser } from "../../../components/cover-icon-color-chooser";
import {
  collectionColors,
  collectionIcons,
} from "../../../components/create-collection-modal/collection-assets";
import { ModalFooter } from "../../../common/modal/modal-footer";
import { Button } from "../../../common/button/button";

type Props = {
  modalResolve: (next?: { coverIcon?: string; coverColor?: string }) => void;
  coverIcon?: string;
  coverColor?: string;
};

export function CollectionSettingsChooserModal(props: Props) {
  const { modalResolve, coverColor, coverIcon } = props;
  const [icon, setIcon] = useState(() => coverIcon || collectionIcons[0]);
  const [color, setColor] = useState(() => coverColor || collectionColors[0]);

  return (
    <>
      <ModalHeader />
      <ModalBody>
        <CoverIconColorChooser
          onChange={(next) => {
            setColor(next.coverColor);
            setIcon(next.coverIcon);
          }}
          coverColor={color}
          coverIcon={icon}
        />
      </ModalBody>
      <ModalFooter>
        <div className="button-group">
          <Button block intent="tertiary" onClick={() => modalResolve()}>
            Cancel
          </Button>
          <Button
            block
            intent="primary"
            onClick={() => modalResolve({ coverIcon: icon, coverColor: color })}
          >
            Save
          </Button>
        </div>
      </ModalFooter>
    </>
  );
}
