import React, {
  ReactNode,
  useState,
  useRef,
  useLayoutEffect,
  CSSProperties,
  useEffect,
} from "react";
import { useCss, k, a } from "kremling";
import { domAnimation, LazyMotion } from "framer-motion";

import { useBodyScrollBoolean } from "../../hooks/use-body-scroll-boolean";
import { Avatar } from "../../common/avatar/avatar";
import { useGetUserInfoQuery } from "../../queries";
import { Menu, MenuItem } from "../../common/menu";
import { AvatarBtn } from "../../common/avatar/avatar-btn";
import { Link, useNavigate } from "react-router-dom";
import { appState, useAppState } from "../../app-state";
import { Badge } from "../../common/badge/badge";
import { AppHeaderMenu } from "./app-header-menu";
import { Button } from "../../common/button/button";
import { CollectionCover } from "../collection-cover/collection-cover";
import { useImgForm } from "../../hooks/use-image-form";

type Props = {
  collection?: Collection;
  renderActionBar?: () => ReactNode;
  shared?: boolean;
};

export function AppHeader(props: Props) {
  const { renderActionBar, shared, collection } = props;
  const elRef = useRef<HTMLDivElement>();
  const scope = useCss(css);
  const navigate = useNavigate();
  const show = useBodyScrollBoolean();
  const userInfo = useGetUserInfoQuery();
  const [spacing, setSpacing] = useState<CSSProperties["height"]>("auto");
  const { signedIn } = useAppState(["signedIn"]);

  useLayoutEffect(() => {
    setSpacing(elRef.current.offsetHeight);
    const resizeObserver = new ResizeObserver(() => {
      setSpacing(elRef.current.offsetHeight);
    });
    resizeObserver.observe(elRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  useEffect(() => {
    if (!userInfo?.data?.userData?.name) {
      userInfo.refetch();
    }
  }, []);

  const logOut = () => {
    appState.logOut();
    navigate("/login-or-signup");
  };

  const { imgUrl, imgUrlLoading } = useImgForm(collection?.id, "cover");

  return (
    <LazyMotion features={domAnimation}>
      <div
        {...scope}
        className={a("app-header")}
        style={{ top: show ? 0 : -spacing - 1 }}
      >
        <div className="app-header-top" ref={elRef}>
          <div className="app-header-top__left">
            {signedIn ? (
              <AppHeaderMenu
                collectionId={collection?.id}
                coverIcon={collection?.metadata.coverIcon}
                coverColor={collection?.metadata.coverColor}
              />
            ) : (
              <CollectionCover
                coverUrl={imgUrl}
                loadingCoverUrl={imgUrlLoading}
                coverIcon={collection?.metadata.coverIcon}
                coverColor={collection?.metadata.coverColor}
                size="sm"
              />
            )}
            {!!collection && (
              <div className="app-header-title">
                <div>{collection?.metadata?.title}</div>
                {shared && <Badge icon="people" />}
              </div>
            )}
          </div>

          <div className="app-header-profile">
            {signedIn && userInfo.data ? (
              <Menu
                fancy
                renderTrigger={(refProps) => (
                  <AvatarBtn
                    onClick={() => {}}
                    user={userInfo.data}
                    {...refProps}
                  />
                )}
              >
                <div {...scope} className="account-dropdown">
                  <Avatar user={userInfo.data} size="lg" />
                  <div className="account-dropdown-name">
                    {userInfo.data.userData.name}
                  </div>
                </div>
                <MenuItem
                  label="Profile"
                  iconLeft="user-regular"
                  onClick={() => navigate("/account/profile")}
                />
                <MenuItem
                  label="Password & Security"
                  iconLeft="lock-regular"
                  onClick={() => navigate("/account/password-and-security")}
                />
                <MenuItem
                  label="Plan"
                  iconLeft="magnifying-glass-regular"
                  onClick={() => navigate("/account/plan")}
                />
                {window.showExtra && (
                  <MenuItem
                    label="Referrals"
                    iconLeft="party-horn-regular"
                    onClick={() => navigate("/account/referrals")}
                  />
                )}
                <MenuItem
                  label="Logout"
                  iconLeft="right-from-bracket"
                  onClick={logOut}
                />
              </Menu>
            ) : (
              <Button
                as={Link}
                to={`/login-or-signup?uploader-collection-id=${collection.id}`}
                intent="tertiary"
              >
                Login
              </Button>
            )}
          </div>
        </div>
        {renderActionBar?.()}
      </div>
    </LazyMotion>
  );
}

const css = k`
  .app-header {
    position: sticky;
    z-index: 300;
    transition: top 200ms ease;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,0.9) 100%);
    backdrop-filter: blur(.4rem);
    left: 0;
    right: 0;
    width: 100%;
  }
  
  .app-header-container {
    position: relative;
  }
  
  .app-header-top {
    padding: .4rem .8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 1.6rem;
    border-bottom: solid .1rem var(--app-border);
    
    @include breakpoint(lg) {
      &::after {
        display: none;
      }
    }
  }
  
  .app-header-top__left {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    overflow: hidden;
    padding: .8rem;
  }
  
  .app-header-collection-btn {
  }
  
  .app-header-bottom {
    display: flex;
    align-items: center;
    padding: 0 1.6rem;
    gap: 1.6rem;
    justify-content: center;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);

    &.app-header-bottom--alone {
      padding-bottom: 1.2rem;
    }
    
    @include breakpoint(lg) {
      &.app-header-bottom--alone {
        padding-bottom: 0;
      }
    }
  }

  .app-header-mobile-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .app-header-menu-button {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    justify-content: space-between;
    overflow: auto;
  }
  
  .app-header-title {
    font-size: 1.8rem;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    gap: .8rem;
    
    @include breakpoint(lg) {
      gap: 1.6rem;
    }
  }
  
  .app-header-profile {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding-right: .8rem;
  }

  @include breakpoint(lg) {
    .app-header-bottom {
      position: absolute;
      top: 0;
      left: 7.2rem;
      right: 7.2rem;
      height: 7.2rem;
      padding-bottom: 0;
    }
  }
  
  .account-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2.4rem;
    border-bottom: solid .1rem var(--app-border);
    margin-bottom: .4rem;
    gap: 1.2rem;
  }
  
  .account-dropdown-name {
    font-size: 2rem;
    font-weight: 700;
  }
  
  .collection-menu-title {
    padding: 0 1.6rem;
  }
`;
