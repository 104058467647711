import React from "react";
import { useImgForm } from "../../hooks/use-image-form";
import { CollectionCover, CollectionCoverProps } from "./collection-cover";

type Props = Omit<CollectionCoverProps, "coverUrl" | "loadingCoverUrl"> & {
  collectionId: string;
  coverColor?: string;
};

export function CollectionCoverDisplay(props: Props) {
  const { collectionId, ...rest } = props;

  const { imgUrl, imgUrlLoading } = useImgForm(collectionId, "cover");
  return (
    <CollectionCover
      {...rest}
      coverUrl={imgUrl as string}
      loadingCoverUrl={imgUrlLoading}
    />
  );
}
