import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "../../common/button/button";
import { setUserAttributes } from "../../services/api";
import { checkApiResponse } from "../../utils/utils";
import { BackgroundCard } from "../../components/background-card";
import { useForm } from "react-hook-form";
import { InputField } from "../../common/input/input-field";
import { useShareSearchParams } from "../../hooks/use-share-search-params";
import { ShareBanner } from "../../components/share-banner";

export const FinishAccount = () => {
  const navigate = useNavigate();
  const [topError, setTopError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { shareParamsStr, shareParamsObj } = useShareSearchParams();

  const { handleSubmit, control } = useForm({
    defaultValues: { name: "" },
  });

  const finish = async ({ name }) => {
    setTopError();
    setIsLoading(true);

    try {
      await setUserAttributes(name, true);
      if (shareParamsObj.uploaderCollectionId) {
        navigate(`/up/${shareParamsObj.uploaderCollectionId}`);
      } else {
        navigate(`/collection${shareParamsStr}`);
      }
    } catch (e) {
      setTopError(checkApiResponse(JSON.parse(error?.request?.response).code));
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(finish)} noValidate>
      <BackgroundCard>
        <div className="text-title text-center mb-24">
          Finish creating your account
        </div>
        <ShareBanner />
        <div className="mb-16">
          {!!topError && <div className="text-error">{topError}</div>}
        </div>
        <div className="mb-16">
          <InputField
            required
            control={control}
            fieldName="name"
            placeholder="Name"
            label="Name"
            scale="lg"
          />
        </div>

        <div className="button-group mb-16">
          <Button
            intent="tertiary"
            block
            size="lg"
            onClick={() => {
              if (shareParamsObj.uploaderCollectionId) {
                navigate(`/up/${shareParamsObj.uploaderCollectionId}`);
              } else {
                navigate(`/collection${shareParamsStr}`);
              }
            }}
          >
            Skip for now
          </Button>
          <Button
            intent="primary"
            block
            disabled={isLoading}
            type="submit"
            isLoading={isLoading}
            size="lg"
          >
            Continue
          </Button>
        </div>
      </BackgroundCard>
    </form>
  );
};
