import React, { ReactNode } from "react";
import { useCss, k, a } from "kremling";
import { Button } from "../../common/button/button";
import { useGoBack } from "../../hooks/use-go-back";
import { useNavigate } from "react-router-dom";

type Props = {
  children?: ReactNode;
  title?: string;
  renderMoreHeader?: () => ReactNode;
  renderFooterActions?: () => ReactNode;
  subTitle?: string;
  className?: string;
  fullScreen?: boolean;
};

export function OverlayPage(props: Props) {
  const {
    children,
    title,
    renderMoreHeader,
    renderFooterActions,
    subTitle,
    className,
    fullScreen,
  } = props;
  const scope = useCss(css);
  const navigate = useNavigate();
  const backUrl = useGoBack();

  const goBack = () => {
    navigate(backUrl);
  };

  return (
    <div {...scope}>
      <div
        className={a("overlay-page", className).m(
          "overlay-page--full-screen",
          fullScreen
        )}
      >
        <div className="overlay-page-header">
          <div className="overlay-page-header-inner">
            <div className="overlay-page-header-top">
              <Button
                iconOnly="arrow-left-regular"
                onClick={goBack}
                intent="tertiary-grey"
              />
              <div className="overlay-page-title">{title}</div>
              {subTitle && <div>{subTitle}</div>}
            </div>
            {!!renderMoreHeader && (
              <div className="overlay-page-header-bottom">
                {renderMoreHeader?.()}
              </div>
            )}
          </div>
        </div>
        <div className="ph-16">{children}</div>
        {!!renderFooterActions && (
          <div className="overlay-page-footer">{renderFooterActions?.()}</div>
        )}
      </div>
    </div>
  );
}

const css = k`
  .overlay-page {
    //padding-bottom: 9rem;
    margin: 0 auto;
    position: relative;
    max-width: 55rem;
  }
  
  .overlay-page--full-screen {
    max-width: none;
  }
  
  .overlay-page-header {
    //position: sticky;
    top: 0;
    z-index: 10;
    background-color: $grey-100;
    padding: 0 1.6rem;
  }
  
  .overlay-page-header-inner {
    padding: 1.6rem 0;
    margin: 0 auto;
  }
  
  .overlay-page-header-top {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    position: relative;
    
    button {
      z-index: 1;
    }
  }
  
  .overlay-page-title {
    font-size: 1.8rem;
    font-weight: 700;
    display: flex;
  }
  
  .overlay-page-header-bottom {
    padding-top: .8rem;
  }
  
  .overlay-page-footer {
    background-color: $grey-100;
    padding: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    gap: 1.6rem;
    z-index: 6;
  }

  .overlay-page-content {
    padding: 1.6rem;
  }

  @include breakpoint(lg) {
    .overlay-page-content {
      padding: 3.2rem;
    }
  }
`;
