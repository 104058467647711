import React, { CSSProperties } from "react";
import { useCss, k, a } from "kremling";
import ProfileImg from "../assets/profile.svg";

type Props = {
  className?: string;
  style?: CSSProperties;
};

export function DefaultProfileImg(props: Props) {
  const { className, style } = props;
  const scope = useCss(css);
  return (
    <div
      {...scope}
      className={a("default-profile-img", className)}
      style={style}
    >
      <ProfileImg />
    </div>
  );
}

const css = k`
  .default-profile-img {
    border-radius: 999rem;
    overflow: hidden;
    background-color: #d4d4dc;
    display: inline-block;
    height: 100%;
    width: 100%;

    svg {
      fill: #ffffff;
    }
  }
`;
