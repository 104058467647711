import React from "react";
import { zipKey, gridSizeKey } from "../pages/auth/auth-utils";
import toast from "react-hot-toast";

export const selectionLimit = 20;

export const getError = (value, fieldName, fieldErrors) => {
  return fieldErrors.reduce((error, rule) => {
    if (!error) {
      if (rule === "required" && (!value || value === "")) {
        error = "This field is required.";
      }
    }

    return error;
  }, false);
};

export const updateField = (setVal, setErrors, fieldName, fieldErrors) => {
  return (value) => {
    setVal(value);
  };
};

export const checkForm = (values, config) => {
  return Object.keys(values).reduce((errors, fieldName) => {
    if (!config[fieldName]) return errors;

    const error = getError(values[fieldName], fieldName, config[fieldName]);

    if (error) {
      if (!errors) errors = {};
      errors[fieldName] = error;
    }

    return errors;
  }, null);
};

export const checkHasErrors = (errors) => {
  return errors && Object.keys(errors).length > 0;
};

const API_MESSAGES = {
  AlreadyExists: "Email address already in use. Try resetting your password.",
  Conflict: "Email address already in use. Try resetting your password.",
  UsernameExists: "Email address already in use. Try resetting your password.",
  UsernameExistsException: "Email address already in use. Try resetting your password.",
  NotAuthorized:
    "Invalid email/password combination.  Check your password and try again.",
  NotAuthorizedException:
    "Invalid email/password combination.  Check your password and try again.",
  InvalidPassword:
    "Your password does not meet the security criteria. Please fix and try again.",
  InvalidPasswordException:
    "Your password does not meet the security criteria. Please fix and try again.",
};

export const checkApiResponse = (message) => {
  if (API_MESSAGES[message]) {
    return API_MESSAGES[message];
  }

  return "Unknown error occurred. Please try again.";
};

export const isVideo = (fileId) => {
  const mimetypeEncoded = fileId.split("__pmvf__")?.[1];
  if (mimetypeEncoded) {
    const mimetype = decodeURIComponent(mimetypeEncoded);
    return isVideoMimetype(mimetype);
  }
  const fName = fileId.toLowerCase();
  return (
    fName.includes(".mov") ||
    fName.includes(".mp4") ||
    fName.includes(".m4a") ||
    fName.includes(".webm")
  );
};

export const isVideoMimetype = (mimetype) => {
  return /video\/mp4|video\/x-msvideo|video\/mpeg|video\/ogg|video\/mp2t|video\/webm|video\/3gpp|video\/3gpp2|video\/quicktime/.test(
    mimetype
  );
};

export const getZipLS = () => {
  try {
    const zipRaw = localStorage.getItem(zipKey);
    if (zipRaw) {
      return JSON.parse(zipRaw) || {};
    }
    throw Error();
  } catch (e) {
    localStorage.setItem(zipKey, JSON.stringify({}));
  }
  return {};
};

export const updateZipLS = (nextZip) => {
  localStorage.setItem(zipKey, JSON.stringify(nextZip));
};

export const getGridSizeLS = () => {
  return localStorage.getItem(gridSizeKey) || "sm";
};

export const updateGridSizeLS = (gridSize) => {
  localStorage.setItem(gridSizeKey, gridSize);
};

export function niceToastError(message, error) {
  const code = error?.response?.data?.requestId;
  const sMessage = message?.toLowerCase() || "something went wrong";
  toast.error(
    <div>
      Whoops, {sMessage} - we're working on a fix!
      {code ? (
        <>
          <div style={{ fontSize: ".8em", opacity: 0.6, paddingTop: ".8rem" }}>
            [{code}]
          </div>
        </>
      ) : (
        ``
      )}
    </div>
  );
}
