import QrCodeStyling from "../qr-code-styling";

export const shareQrCode = async (qrCode: QrCodeStyling, title: string) => {
  const file = await qrCode.getFile();
  navigator
    .share({
      title: title,
      files: [file],
    })
    .then(() => {})
    .catch(() => {});
};
