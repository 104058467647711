import React from "react";
import isEmpty from "lodash/isEmpty";

import { useAppState } from "../../app-state";
import { PersistentToast } from "../persistent-toast";
import { ToastOptions } from "react-hot-toast";
import { ZipPersistentToastInner } from "./zip-persistent-toast-inner";

type ZipPersistentToastProps = {
  collectionId: string;
  toastProps?: ToastOptions;
};

export function ZipPersistentToast(props: ZipPersistentToastProps) {
  const { collectionId, toastProps } = props;
  const { zip } = useAppState(["zip"]);
  const { zipUrl } = zip[collectionId] || {};

  return (
    !isEmpty(zip) &&
    !!zipUrl && (
      <PersistentToast id="zip" {...toastProps}>
        <ZipPersistentToastInner collectionId={collectionId} />
      </PersistentToast>
    )
  );
}
