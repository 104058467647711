export const apiUrlMap = {
  "development": {
    "adHandler": "https://hbcpo2lu26lboi77dfpofk2una0oqssb.lambda-url.us-west-2.on.aws/",
    "authenticationHandler": "https://cxs4j7khkuzsbypy7efmlusy4i0afyyh.lambda-url.us-west-2.on.aws/",
    "collectionHandler2": "https://askgs7ib2fgvdm74jogfkvatty0evfov.lambda-url.us-west-2.on.aws/",
    "qrGenerationHandler": "https://lxztskfmmr35ae6ukyq6gnwkei0ehkxc.lambda-url.us-west-2.on.aws/"
  },
  "production": {
    "adHandler": "https://zvdqn223wfrxcbl2dixqjgx6ti0khnps.lambda-url.us-east-1.on.aws/",
    "authenticationHandler": "https://5ivdywg6des2gg7yfc37nsqnwy0wioim.lambda-url.us-east-1.on.aws/",
    "collectionHandler2": "https://byvpnlgqzkwlx3dhuogct3e7v40cngoa.lambda-url.us-east-1.on.aws/",
    "qrGenerationHandler": "https://ivgkhvagbnen7zc7eyefvx3mdu0ywcmq.lambda-url.us-east-1.on.aws/"
  },
  "local": {
    "authenticationHandler": "http://localhost:5000/func/AuthenticationHandler/",
    "collectionHandler": "http://localhost:5000/func/CollectionHandler/",
    "deviceHandler": "http://localhost:5000/func/DeviceHandler/",
    "listReferrals": "http://localhost:5000/func/ListReferrals/",
    "testNewArchitecture": "http://localhost:5000/func/TestNewArchitecture/",
    "qrGenerationHandler": "http://localhost:5000/func/QrGenerationHandler/"
  },
  "stage": {
    "adHandler": "https://723w7kodgwclp3fpd6jwsactvm0iqlpd.lambda-url.us-west-2.on.aws/",
    "authenticationHandler": "https://cwctfwiarortm2p4gaue425bzq0dobay.lambda-url.us-west-2.on.aws/",
    "collectionHandler2": "https://b2ymkqwv6fac3n5turhv35ro5q0ubukl.lambda-url.us-west-2.on.aws/",
    "qrGenerationHandler": "https://aox466gaweg7xvievk56ebpdzm0miofe.lambda-url.us-west-2.on.aws/"
  },
  "test": {
    "adHandler": "https://75sbtdooo7xudaclr7m5oaxkli0urqmg.lambda-url.us-west-2.on.aws/",
    "authenticationHandler": "https://cf7vx6jcykl5o6yaukkmk4wxmq0qliuo.lambda-url.us-west-2.on.aws/",
    "collectionHandler2": "https://qebecp66elb4qgmztjplb4jxry0vhrnf.lambda-url.us-west-2.on.aws/",
    "qrGenerationHandler": "https://7ckzdqwuz26gykridkcuidqumy0dwhym.lambda-url.us-west-2.on.aws/"
  }
};
