import React, { useRef, useState, useMemo, useEffect, memo } from "react";
import { useCss, k, a } from "kremling";
import { createPortal } from "react-dom";
import { motion, useSpring } from "framer-motion";
import { PhotoViewerSlideRef } from "./slide-image";
import { useGesture } from "@use-gesture/react";
import {
  getPageX,
  getNextIndex,
  springOpts,
  getSurroundingIndexes,
} from "./photo-viewer-utils";
import { Button } from "../../common/button/button";
import { Slide } from "./slide";
import { SlideType } from "./photo-viewer-types";
import {
  PhotoViewActions,
  PhotoViewerActionsProps,
} from "./photo-viewer-actions";
import { useDebounceValue } from "../../hooks/use-debounce-value";

type Props = Pick<PhotoViewerActionsProps, "actions"> & {
  onClose?: () => void;
  slides: SlideType[];
  initialIndex: number;
  fullAccess: boolean;
  collectionId: string;
};

export const PhotoViewer = memo(
  function PhotoViewer(props: Props) {
    const {
      onClose,
      slides,
      initialIndex,
      fullAccess,
      collectionId,
      actions = [],
    } = props;

    const scope = useCss(css);
    const springX = useSpring(0, springOpts);
    const controllerRef = useRef(null);
    const [isZoom, setIsZoom] = useState(false);
    const [isDrag, setIsDrag] = useState(false);
    const [index, setIndex] = useState(initialIndex || 0);
    const [disableAnimation, setDisableAnimation] = useState(false);
    const currentImgRef = useRef<PhotoViewerSlideRef>(null);
    const [[winWidth, winHeight], setWindowSize] = useState([
      window.innerWidth,
      window.innerHeight,
    ]);

    const [pauseGestures, setPauseGestures] = useState(false);

    const [dragStart, setDragStart] = useState(0);
    const [dragRunning, setDragRunning] = useState(0);
    const [dragEnd, setDragEnd] = useState(0);

    const forcePause = () => {
      setIsDrag(false);
      setPauseGestures(true);
      setTimeout(() => {
        setPauseGestures(false);
      }, 1000);
    };

    useEffect(() => {
      function sup() {
        forcePause();
      }
      document.addEventListener("contextmenu", sup);
      return () => {
        document.removeEventListener("contextmenu", sup);
      };
    }, []);

    const surroundingSlides = useMemo(() => {
      if (slides.length < 3) {
        return 0;
      }
      return 1;
    }, [slides.length]);

    const focusedSlides = useMemo(() => {
      if (!slides.length) return [];
      return getSurroundingIndexes(index, slides, surroundingSlides);
    }, [index, JSON.stringify(slides.map((s) => s.id))]);

    useEffect(() => {
      function resize() {
        setWindowSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", resize);
      return () => {
        window.removeEventListener("resize", resize);
      };
    }, []);

    useGesture(
      {
        onDragStart: ({ pinching, cancel }) => {
          setDragStart(dragStart + 1);
          if (pinching || isZoom) return cancel();
          setIsDrag(true);
        },
        onDrag: ({ offset: [offsetX], pinching, cancel }) => {
          setDragRunning(dragRunning + 1);
          if (pinching || isZoom) return cancel();
          springX.set(offsetX);
        },
        onDragEnd: ({ offset: [offsetX], swipe: [swipeX] }) => {
          setDragEnd(dragEnd + 1);
          let nextIncrement = 0;
          if (swipeX) {
            nextIncrement = swipeX === 1 ? -1 : 1;
          } else {
            const amount = Math.abs(offsetX);
            const percentageOfMovement = (amount * 100) / window.innerWidth;
            if (percentageOfMovement > 15) {
              nextIncrement = offsetX > 0 ? -1 : 1;
            }
          }

          if (nextIncrement) {
            const i = getNextIndex(index, nextIncrement, slides.length);
            setIndex(i);
            springX.jump(
              springX.get() + (window.innerWidth + 100) * nextIncrement
            );
          }
          springX.set(0);
          setIsDrag(false);
        },
      },
      {
        drag: {
          from: () => [springX.get(), 0],
          delay: 1000 * 60 * 60, // delay an hour
          threshold: [10, 1000],
        },
        target: controllerRef,
        enabled: !pauseGestures,
      }
    );

    useEffect(() => {
      const preventDefault = (e: Event) => e.preventDefault();
      document.addEventListener("gesturestart", preventDefault);
      document.addEventListener("gesturechange", preventDefault);
      document.addEventListener("gestureend", preventDefault);
      return () => {
        document.removeEventListener("gesturestart", preventDefault);
        document.removeEventListener("gesturechange", preventDefault);
        document.removeEventListener("gestureend", preventDefault);
      };
    }, []);

    const setNextIncrement = (
      nextIncrement: number,
      arrayLengthDiff: number = 0
    ) => {
      const i = getNextIndex(
        index,
        nextIncrement,
        slides.length + arrayLengthDiff
      );
      setIndex(i);
      springX.jump((window.innerWidth + 100) * nextIncrement);
      springX.set(0);
    };

    const isZoomDebounced = useDebounceValue(isZoom, 200);

    return createPortal(
      <div {...scope} className="photo-viewer">
        <div className="photo-viewer__toolbar">
          <Button
            iconOnly="arrow-left-regular"
            intent="tertiary-transparent"
            onClick={() => onClose()}
          />
          <div className="photo-viewer__controls">
            {isZoomDebounced && (
              <Button
                iconOnly="minimize-regular"
                intent="tertiary-transparent"
                onClick={() => currentImgRef.current?.resetZoom()}
              />
            )}
            <PhotoViewActions
              fullAccess={fullAccess}
              slide={slides[index]}
              collectionId={collectionId}
              actions={actions}
              setNextIncrement={setNextIncrement}
            />
          </div>
        </div>
        <motion.div
          className="photo-viewer__controller"
          ref={controllerRef}
          style={{ x: disableAnimation ? 0 : springX }}
        >
          {focusedSlides.map((slide, i) => {
            const active = i === surroundingSlides;
            return (
              <Slide
                key={slide.uid}
                slide={slide}
                style={{
                  transform: `translateX(${getPageX(i - surroundingSlides)}px)`,
                }}
                ref={active ? currentImgRef : null}
                active={active}
                isZoom={isZoom}
                setIsZoom={setIsZoom}
                isDrag={isDrag}
              />
            );
          })}
        </motion.div>

        <div
          className={a("photo-viewer__back-btn").m("--hide", isZoomDebounced)}
        >
          <Button
            iconOnly="angle-left-solid"
            intent="tertiary-transparent"
            onClick={() => setNextIncrement(-1)}
          />
        </div>
        <div
          className={a("photo-viewer__next-btn").m("--hide", isZoomDebounced)}
        >
          <Button
            iconOnly="angle-right-solid"
            intent="tertiary-transparent"
            onClick={() => setNextIncrement(1)}
          />
        </div>
      </div>,
      document.body
    );
  },
  (prevProps, nextProps) => {
    const { slides } = nextProps;
    return (
      JSON.stringify(slides.map((s) => s.id)) ===
      JSON.stringify(prevProps.slides.map((s) => s.id))
    );
  }
);

const css = k`
  .photo-viewer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: 900;
    touch-action: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .photo-viewer__toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .8rem 1.6rem;
    z-index: 1;
  }
  
  .photo-viewer__controller {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    touch-action: none;
  }
  
  .photo-is-zoom {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    z-index: 1;
    background-color: darkred;
    color: white;
    padding: .8rem 1.6rem;
    border-radius: .8rem;
  }

  .photo-viewer__back-btn {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 1.6rem;
    opacity: 1;
    transition: opacity 200ms ease;
  }

  .photo-viewer__next-btn {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-right: 1.6rem;
    opacity: 1;
    transition: opacity 200ms ease;
  }
  
  .--hide {
    opacity: 0;
  }

  .photo-viewer__controls {
    display: flex;
    align-items: center;
    gap: .8rem;
  }

  @include breakpoint(sm) {
    .photo-viewer__back-btn {
      padding-left: 3.2rem;
    }

    .photo-viewer__next-btn {
      padding-right: 3.2rem;
    }
  }
`;
