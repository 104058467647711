import React, { useEffect, useMemo, useState } from "react";
import { useCss, k, a, maybe } from "kremling";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { m, AnimatePresence } from "framer-motion";

import { Button } from "../../../common/button/button";
import { modalService } from "../../../common/modal-service/modal-service";
import { CreateCollectionModal } from "../../../components/create-collection-modal/create-collection-modal";
import { useCollectionListQuery } from "../../../queries";
import { Input } from "../../../common/input/input";
import { CollectionCoverDisplay } from "../../../components/collection-cover/collection-cover-display";
import { useAppState } from "../../../app-state";
import { from, forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { getCollectionInfo } from "../../../services/api";
import { isEmpty } from "lodash";

type CollectionMenuProps = {
  onChange?: () => void;
  bodyMaxHeight?: string;
  showCloseBtn?: boolean;
};

const transition = {
  duration: 0.2,
  ease: "easeOut",
};

export function CollectionMenu(props: CollectionMenuProps) {
  const { onChange, bodyMaxHeight, showCloseBtn } = props;
  const collectionListQuery = useCollectionListQuery();
  const { collectionId } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const [showSearch, setShowSearch] = useState(false);
  const [uploadHistory, setUploadHistory] = useState([]);
  const [collectionListMetadata, setCollectionListMetadata] = useState([]);
  const scope = useCss(css);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<
    "my-collections" | "upload-history"
  >("my-collections");

  const close = () => {
    onChange();
  };

  const openNewCollectionModal = async () => {
    close();
    await modalService.render(CreateCollectionModal);
  };

  useEffect(() => {
    if (!isEmpty(collectionListQuery?.data)) {
      const observables = collectionListQuery?.data.map(({ id }) =>
        from(getCollectionInfo(id)).pipe(
          map((res) => ({
            ...res,
            id,
          })),
          catchError((error) => {
            console.error("Error fetching collection info:", error);
            return of(null); // Replace errors with null (or some error value)
          })
        )
      );

      const subscription = forkJoin(observables).subscribe({
        next: (responses) => {
          const validResponses = responses.filter(
            (response) => response !== null
          );
          setCollectionListMetadata(validResponses);
        },
        error: (error) => {
          console.error("Error with forkJoin:", error);
        },
        complete: () => {},
      });

      return () => subscription.unsubscribe();
    }
  }, []);

  const myCollections = useMemo<CollectionListItem[]>(() => {
    return collectionListQuery?.data?.filter(
      (c) =>
        c.access === "Full" &&
        c.metadata.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [collectionListQuery?.data, searchText]);

  const sharedCollections = useMemo<CollectionListItem[]>(() => {
    return collectionListQuery?.data?.filter(
      (c) =>
        c.access === "Read" &&
        c.metadata.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [collectionListQuery?.data, searchText]);

  if (!collectionListQuery?.data?.length)
    return (
      <div {...scope} className="collection-menu-empty">
        <Button
          className="collection-menu-empty-close"
          iconOnly="arrow-left-regular"
          intent="secondary-grey"
          onClick={close}
        />
        <div className="text-sub-title">No collections yet 😢</div>
        <div>
          <Button
            intent="primary"
            onClick={openNewCollectionModal}
            iconLeft="plus-regular"
          >
            Create a collection
          </Button>
        </div>
      </div>
    );

  return (
    <div {...scope} className="collection-menu">
      <div className="p-20">
        <div className="menu-header">
          <m.div
            className="menu-app-title"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            <div className="menu-actions">
              <div className="menu-actions-inner" style={{ gap: "1.6rem" }}>
                <Button
                  className="collection-menu-back-btn"
                  iconOnly="arrow-left-regular"
                  intent="secondary-grey"
                  onClick={close}
                />
                <div className="text-page-title">Collections</div>
              </div>
              <div className="menu-actions-inner">
                <Button
                  intent="secondary-grey"
                  iconOnly="plus-regular"
                  onClick={openNewCollectionModal}
                />
                <Button
                  onClick={() => setShowSearch(true)}
                  intent="secondary-grey"
                  iconOnly="magnifying-glass-regular"
                  iconOnlyProps={{ size: 16 }}
                />
              </div>
            </div>
          </m.div>
          <AnimatePresence initial={false}>
            {showSearch && (
              <m.div
                className="search-container"
                initial={{ opacity: 0, left: "75%" }}
                animate={{ opacity: 1, left: 0 }}
                exit={{ opacity: 0, left: "75%" }}
                transition={{ duration: 0.1 }}
              >
                <Input
                  className="search-input"
                  placeholder="Search for a collection"
                  onClear={() => {
                    setSearchText("");
                    setShowSearch(false);
                  }}
                  autoFocus
                  value={searchText}
                  onChange={(text) => {
                    setSearchText(text);
                  }}
                />
              </m.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/*{!!uploadHistory.length && (*/}
      {/*  <div className="collection-tab__container">*/}
      {/*    <button*/}
      {/*      className={maybe("active", activeTab === "my-collections")}*/}
      {/*      onClick={() => {*/}
      {/*        setActiveTab("my-collections");*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      My Collections*/}
      {/*    </button>*/}
      {/*    <button*/}
      {/*      className={maybe("active", activeTab === "upload-history")}*/}
      {/*      onClick={() => {*/}
      {/*        setActiveTab("upload-history");*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Upload history*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="collections" style={{ maxHeight: bodyMaxHeight }}>
        {activeTab === "my-collections" && (
          <>
            {myCollections?.map((collection: any) => {
              const metadata = collectionListMetadata.find(
                (c) => c.id === collection.id
              );
              return (
                <button
                  onClick={() => {
                    close();
                    navigate(`/collection/${collection.id}${location.search}`);
                  }}
                  className={a("collection-item")
                    .m("active", collection.id === collectionId)
                    .m("paused", collection?.metadata?.pause)}
                  key={collection.id}
                >
                  <CollectionCoverDisplay
                    collectionId={collection.id}
                    coverColor={metadata?.coverColor}
                    coverIcon={metadata?.coverIcon}
                    size="xs"
                  />
                  <span className="collection-item__title">
                    {collection.metadata.title || "[No Title]"}
                  </span>
                  {collection?.metadata?.pause && (
                    <span className="paused-indicator" />
                  )}
                </button>
              );
            })}
            {sharedCollections?.map((collection: any) => (
              <button
                onClick={() => {
                  close();
                  navigate(`/collection/${collection.id}${location.search}`);
                }}
                className={a("collection-item").m(
                  "active",
                  collection.id === collectionId
                )}
                key={collection.id}
              >
                <CollectionCoverDisplay
                  collectionId={collection.id}
                  size="xs"
                />
                <div style={{ textAlign: "left" }}>
                  <div>{collection.metadata.title || "[No Title]"}</div>
                  <div className="collection-shared">Shared with you</div>
                </div>
              </button>
            ))}
          </>
        )}
        {activeTab === "upload-history" &&
          uploadHistory?.map((collection: any) => (
            <button
              onClick={() => {
                close();
                if (
                  collectionListQuery?.data?.find((c) => c.id === collection.id)
                ) {
                  return navigate(`/up/${collection.id}`);
                }
                navigate(`/up/${collection.id}`);
              }}
              className={a("collection-item").m(
                "active",
                collection.id === collectionId
              )}
              key={collection.id}
            >
              <CollectionCoverDisplay
                coverColor={collection.coverColor}
                collectionId={collection.id}
                size="xs"
              />
              <span>{collection.title || "[No Title]"}</span>
            </button>
          ))}
      </div>
    </div>
  );
}

const css = k`
  .collection-menu-empty {
    padding: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.6rem;
    height: 100%;
    position: relative;
  }

  .collection-menu-empty-close {
    position: absolute;
    top: 1.6rem;
    left: 1.6rem;

    @include breakpoint(lg) {
      display: none;
    }
  }

  .menu-overlay-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.6rem;
  }

  .collection-menu {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .menu-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-app-title {
    width: 100%;
    height: 4rem;
  }

  .menu-actions {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-actions-inner {
    display: flex;
    align-items: center;
    gap: .8rem;

  }

  .close-menu-btn {
    position: absolute;
  }

  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  .search-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
  }

  .search-input {
    width: 100%;  
  }

  .search-results {
    padding: 2.4rem;
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }

  .collection-tab__container {
    width: 100%;
    border-bottom: solid 1px $grey-600;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1.6rem;

    button {
      display: block;
      background: none;
      border: none;
      border-bottom: solid .3rem #fff;
      padding: 1.2rem .4rem;
      font-size: 1.6rem;
      font-weight: 800;

      &:focus {
        outline: none;
      }
    }

    button.active {
      color: $violet-500;
      border-color: $violet-500;
    }
  }

  .collections {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 2rem 2rem;
    gap: 1.4rem;
    overflow: auto;
  }

  .collection-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: .6rem;
    gap: 1.6rem;
    // min-height: 5rem;
    border-radius: $border-radius-md;
    cursor: pointer;
    overflow: hidden;
    border: none;
    background-color: transparent;
    transition: background-color 100ms ease;
    flex-shrink: 0;
    font-size: 1.6rem;
    font-weight: 600;

    @include focus-ring {
      border-radius: 1.2rem;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      background: rgba($raisin-900, .05);
    }

    &.active {
      background: linear-gradient(121deg, rgba(57, 65, 255, 0.15) 17.57%, rgba(214, 3, 247, 0.10) 102.39%);
    }

    &.paused {
      justify-content: space-between;
    }

    .collection-shared {
      font-weight: 100;
      color: $raisin-75;
      font-size: 1.4rem;
    }
  }
  
  .collection-item__title {
    flex-grow: 1;
    justify-content: flex-start;
    display: flex;
  }

  .paused-indicator {
    width: .8rem;
    height: .8rem;
    background-color: $vermillion;
    border-radius: $border-radius-full;
  }

  @include breakpoint(lg) {
    .collection-menu-back-btn {
      display: none;
    }
  }

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem 2rem 0 2rem;
  }
`;
