export function convertExtToMime(ext: string) {
  switch (ext) {
    case "png":
      return "image/png";
    case "avif":
      return "image/avif";
    case "webp":
      return "image/webp";
    case "svg":
    case "svgz":
      return "image/svg+xml";
    case "gif":
      return "image/gif";
    case "tif":
    case "tiff":
      return "image/tiff";
    case "jpg":
    case "jpeg":
    case "jfif":
    case "pjp":
    case "pjpeg":
    default:
      return "image/jpeg";
  }
}
