// If you add a color here, you also need to add one in app.picme.com
export const gradients = [
  "linear-gradient(135deg, rgba(0, 48, 219, 0.6), rgba(5, 0, 255, 0.14))", //dark blue
  "linear-gradient(135deg, rgba(101, 0, 203, 0.6), rgba(250, 0, 255, 0.21))", //purple
  "linear-gradient(135deg, rgba(255, 121, 111, 0.74), rgba(255, 189, 111, 0.13))", //orange
  "linear-gradient(135deg, rgba(0, 203, 179, 0.6), rgba(0, 71, 255, 0.21))", //light blue
];

export function getGradientByIndex(index: number) {
  return gradients[index % gradients.length]
}