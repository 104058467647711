import React from "react";
import toast from "react-hot-toast";

import { appState, UploadFileType } from "../app-state";
import { uploadImage } from "../services/api";
import { isVideoMimetype } from "./utils";

function getTotalProgress(files: UploadFileType[]) {
  return (
    files.reduce((acc, next) => {
      return acc + next.upload.progress;
    }, 0) / files.length
  );
}

function updateUploadFile(file: UploadFileType) {
  appState.set(({ uploadFiles }: { uploadFiles: UploadFileType[] }) => {
    const nextFiles = (files: UploadFileType[]) =>
      files.map((f) => (f.id === file.id ? file : f));
    const nextUploadFiles = nextFiles(uploadFiles);
    return {
      uploadFiles: nextUploadFiles,
      uploadTotalProgress: getTotalProgress(nextUploadFiles),
    };
  });
}

function getFile(fileId: string): UploadFileType {
  return appState
    .get("uploadFiles")
    .find((f: UploadFileType) => f.id === fileId);
}

class UploaderService {
  private currentUploads: string[] = [];
  private concurrentLimit: number = 1;
  private generateThumbnails: boolean = false;
  private keepInMemory: boolean = false;
  private successToast: boolean;

  upload = async (fileId: string) => {
    const file = getFile(fileId);
    if (!file) return;
    this.currentUploads.push(fileId);
    file.upload.status = "upload";
    updateUploadFile(file);
    try {
      let thumbPromise = Promise.resolve();
      if (this.generateThumbnails) {
        thumbPromise = this.generateThumbnail(file);
      }

      const [uploadResponse] = await Promise.all([
        uploadImage(file.collectionId, file.file, (e) => {
          updateUploadFile({
            ...file,
            upload: {
              ...file.upload,
              progress: e.progress,
            },
          });
        }),
        thumbPromise,
      ]);
      appState.set(({ uploadFiles }) => ({
        uploadTotalProgress: getTotalProgress(uploadFiles),
      }));
      this.currentUploads = this.currentUploads.filter((id) => id !== fileId);
      const finishedFile: UploadFileType = {
        ...file,
        id: uploadResponse.uploadId,
        ownershipToken: uploadResponse.anonymousOwnershipToken,
        upload: {
          error: null,
          status: "success",
          progress: 1,
        },
      };
      updateUploadFile(finishedFile);
      appState.set(
        ({
          uploadFilesInMemory,
        }: {
          uploadFilesInMemory: Record<string, UploadFileType[]>;
        }) => ({
          uploadFilesInMemory: {
            ...uploadFilesInMemory,
            [finishedFile.collectionId]: [
              ...(uploadFilesInMemory[finishedFile.collectionId] || []),
              finishedFile,
            ],
          },
        })
      );
    } catch (e) {
      updateUploadFile({
        ...file,
        upload: {
          error: "An error occurred",
          status: "error",
          progress: 0,
        },
      });
      // todo toast: file upload failed - we'll retry it
    }

    this.uploadNextImages();
  };

  addFiles = (
    files: UploadFileType[],
    collectionId: string,
    {
      generateThumbnails = false,
      keepInMemory = false,
      successToast = true,
    }: {
      generateThumbnails?: boolean;
      keepInMemory?: boolean;
      successToast?: boolean;
    } = {}
  ) => {
    appState.set(({ uploadFiles }: { uploadFiles: UploadFileType[] }) => {
      const nextFiles = [...uploadFiles, ...files];
      return {
        uploadFilesCollectionId: collectionId,
        uploadFiles: nextFiles,
        uploadTotalProgress: getTotalProgress(nextFiles),
      };
    });
    this.successToast = successToast;
    this.generateThumbnails = generateThumbnails;
    this.keepInMemory = keepInMemory;

    this.uploadNextImages();
  };

  uploadNextImages = () => {
    const uploaderStop = appState.get("uploaderStop");
    const files: UploadFileType[] = appState.get("uploadFiles");
    const nextFiles = [];
    const nextCount = this.concurrentLimit - this.currentUploads.length;
    if (nextCount === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      if (f.upload.status === "queue") {
        nextFiles.push(f);
      }
      if (nextFiles.length === nextCount) break;
    }
    if (nextFiles.length && !uploaderStop) {
      nextFiles.forEach((f) => this.upload(f.id));
    } else {
      appState.set({
        uploaderStop: false,
        uploadFiles: [],
        uploadTotalProgress: 0,
      });
      if (this.successToast) {
        const uploadedCount = uploaderStop
          ? files.reduce((acc, file) => {
              return file.upload.status === "success" ||
                file.upload.status === "upload"
                ? acc + 1
                : acc;
            }, 0)
          : files.length;
        toast.success(
          <div>
            <strong>
              Successfully uploaded {uploadedCount} file
              {uploadedCount === 1 ? "" : "s"}.
            </strong>
            <br />
            {!this.keepInMemory && <span>Files will be available soon.</span>}
          </div>
        );
      }
    }
  };

  generateThumbnail = async (file: UploadFileType) => {
    if (!isVideoMimetype(file.file.type)) {
      const thumbnail = await this.setImageThumbUrl(file.file);
      updateUploadFile({
        ...file,
        thumbnail,
      });
    }
  };

  private setImageThumbUrl = async (file: File): Promise<string> => {
    const image = await new Promise<HTMLImageElement>((resolve) => {
      const imgEl = document.createElement("img");
      const reader = new FileReader();
      reader.onload = (e) => {
        imgEl.src = e.target.result as string;
        resolve(imgEl);
      };
      reader.readAsDataURL(file);
    });

    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const maxWidth = 320;
    const maxHeight = 320;

    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = width * (maxHeight / height);
        height = maxHeight;
      }
    }

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);
    ctx.imageSmoothingEnabled = false;
    return canvas.toDataURL();
  };
}

export const uploaderService = new UploaderService();
