import React, { CSSProperties, useMemo, useState } from "react";
import { useCss, k, a } from "kremling";
import { Icon } from "../icon/icon";
import { DefaultProfileImg } from "../../components/default-profile-img";

export type AvatarProps = {
  className?: string;
  style?: CSSProperties;
  user: UserInfo;
  size?: "sm" | "md" | "lg" | "fit";
};

export function Avatar(props: AvatarProps) {
  const { className, style, user, size = "md" } = props;
  const scope = useCss(css);
  const [img, setImg] = useState(false);
  const [err, setErr] = useState(false);

  const onImgLoad = (loaded: boolean) => {
    if (loaded) {
      setImg(true);
    } else {
      setErr(true);
    }
  };

  const iconSize = useMemo(() => {
    if (size === "lg") return 30;
    if (size === "md") return 16;
    return 10;
  }, [size]);

  const empty = !img && !user.userData.name;

  return (
    <div
      {...scope}
      className={a("avatar", className)
        .m("--lg", size === "lg")
        .m("--sm", size === "sm")
        .m("--md", size === "md")
        .m("--fit", size === "fit")
        .m("--empty", empty)}
      style={style}
    >
      {empty && <DefaultProfileImg />}
      {!img && user.userData.name.slice(0, 1)}
      {!err && (
        <img
          src={user.profilePicture}
          onLoad={() => onImgLoad(true)}
          onError={() => onImgLoad(false)}
        />
      )}
    </div>
  );
}

const css = k`
  .avatar {
    position: relative;
    background: linear-gradient(135deg, rgba(0,20,203,1) 0%,rgba(250,0,255,1) 100%);
    height: 4rem;
    width: 4rem;
    border-radius: $border-radius-full;
    font-size: 1.6rem;
    font-weight: 700;
    color: $grey-100;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-shrink: 0;

    &.--lg {
      width: 7.2rem;
      height: 7.2rem;
      font-size: 3.6rem;
    }

    &.--md {
      width: 3.6rem;
      height: 3.6rem;
      font-size: 2.4rem;
    }

    &.--sm {
      width: 2.6rem;
      height: 2.6rem;
      font-size: 1.4rem;
    }

    &.--fit {
      width: 100%;
      height: 100%;
      font-size: 1.4em;
    }
    
    &.--empty {
      background: none;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
`;
