import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCss, k, a } from "kremling";
import kebabCase from "lodash/kebabCase";

import QrCodeStyling from "../qr-code-styling";
import { qrCodeOptions } from "./qr-colors";
import { MenuSingleSelect } from "../menu-single-select";
import { Button } from "../../common/button/button";
import { shareQrCode } from "./qr-utils";
import { Loader } from "../../common/loader/loader";
import { UrlBox } from "../url-box/url-box";
import { Link } from "react-router-dom";

type Props = {
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
  url?: string;
  loading?: boolean;
  qrType?: QrCodeTypes;
  onQrTypeChange?: (qrType: QrCodeTypes) => void;
  title?: string;
  collection?: Collection;
};

export function Qr(props: Props) {
  const {
    className,
    children,
    style,
    url,
    loading,
    qrType = window.showExtra ? "colorLogo" : "black",
    onQrTypeChange,
    title = "",
    collection,
  } = props;
  const scope = useCss(css);
  const qrInstanceRef = useRef<QrCodeStyling>(null);
  const [qrObjectUrl, setQrObjectUrl] = useState<string>(null);
  const titleQr = useMemo(() => kebabCase(title.toLowerCase()), [title]);

  useEffect(() => {
    if (!qrInstanceRef.current) {
      qrInstanceRef.current = new QrCodeStyling(qrCodeOptions(qrType));
    }
    qrInstanceRef.current.update({
      data: url,
      ...qrCodeOptions(qrType),
    });
    qrInstanceRef.current
      .getFile()
      .then((file) => {
        setQrObjectUrl(URL.createObjectURL(file));
      })
      .catch(() => {});

    return () => {
      if (qrObjectUrl) {
        URL.revokeObjectURL(qrObjectUrl);
      }
    };
  }, [url, qrType]);

  const avatarClass = useMemo(() => {
    if (qrType === "gradient") return "qr-avatar-gradient";
    if (qrType === "colorLogo") return "qr-avatar-color-logo";
    return "qr-avatar-black";
  }, [qrType]);

  const isLoading = !url || loading;

  return (
    <div {...scope} className="qr-wrapper">
      <div
        className={a("qr", className).m("qr--has-children", !!children)}
        style={style}
      >
        {!!children && <div className="qr-children">{children}</div>}
        <div className="qr-container">
          <div className="qr-square" />
          <div className="qr-content">
            {qrObjectUrl && url && <img src={qrObjectUrl} />}
            <div className={a("qr-loader").m("qr-loader--hide", !isLoading)}>
              <Loader />
            </div>
          </div>
        </div>
        {!!onQrTypeChange && (
          <div className="mb-16">
            <MenuSingleSelect
              disabled={isLoading}
              value={qrType}
              onChange={onQrTypeChange}
              icon={<div className={`qr-avatar ${avatarClass}`} />}
              options={[
                {
                  id: "black",
                  label: "Black",
                  icon: <div className="qr-avatar qr-avatar-black" />,
                },
                {
                  id: "gradient",
                  label: "Gradient",
                  icon: <div className="qr-avatar qr-avatar-gradient" />,
                },
                {
                  id: "colorLogo",
                  label: "Color Logo",
                  icon: <div className="qr-avatar qr-avatar-color-logo" />,
                },
              ]}
            />
          </div>
        )}
      </div>
      <div className="qr-actions">
        <Button
          intent="secondary-grey"
          size="md"
          disabled={isLoading}
          onClick={() => qrInstanceRef.current.download({ name: titleQr })}
          iconOnly="arrow-down-to-line-regular"
        />
        <Button
          as={Link}
          to={`/up/${collection.id}`}
          intent="secondary-grey"
          size="md"
          disabled={isLoading}
          onClick={() => {}}
          iconOnly="eye-solid"
          target="_blank"
        />
        {navigator?.share && (
          <Button
            intent="secondary-grey"
            size="md"
            disabled={isLoading}
            onClick={() => shareQrCode(qrInstanceRef.current, titleQr)}
            iconOnly="share-arrow-up"
          />
        )}
      </div>
      <UrlBox
        url={url}
        title={collection.metadata.title}
        text="Share your photos with me."
      />

      <div className="text-sm text-center" style={{ padding: "0 4.8rem" }}>
        Use this QR code or link to request photos from Guest uploaders
      </div>
    </div>
  );
}

const css = k`
  .qr-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 32rem;
    width: 100%;
  }

  .qr {
    border: solid .1rem var(--app-border);
    border-radius: $border-radius-lg;
    padding: 2rem 2rem 2rem 2rem;
    position: relative;
    width: 100%;
    margin-bottom: .8rem;

    &.qr--has-children {
      margin-top: 6rem;
    }
  }

  .qr-container {
    border-radius: $border-radius-md;
    position: relative;
  }

  .qr-children {
    //transform: translateY(-6rem);
    margin-top: -6rem;
  }

  .qr-square {
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    position: relative;
  }

  .qr-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      position: relative;
      width: 80%;
      z-index: 0;
    }
  }
  
  .qr-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .8rem;
    margin-bottom: 2.4rem;
  }

  .qr-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($grey-200, .97);
    z-index: 1;
    opacity: 1;
    transition: opacity 500ms ease;
  }

  .qr-loader--hide {
    opacity: 0;
  }
`;
