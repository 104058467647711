import React, { useEffect, useMemo } from "react";
import { useCss, k } from "kremling";

import { appState, useAppState } from "../../app-state";
import { useGetZipStatusQuery } from "../../queries";
import { Button } from "../../common/button/button";
import { Loader } from "../../common/loader/loader";
import toast from "react-hot-toast";

type Props = {
  collectionId: string;
};

export function ZipPersistentToastInner(props: Props) {
  const { collectionId } = props;
  const scope = useCss(css);
  const { zip } = useAppState(["zip"]);
  const { zipUrl, thumbnails } = zip[collectionId] || {};
  const zipStatusQuery = useGetZipStatusQuery(collectionId, zipUrl);

  // if there's an error getting the zip status, remove the zip
  useEffect(() => {
    if (zipStatusQuery.error) {
      toast.error(`Oops! We hit a snag. Please try again soon.`);
      appState.deleteZip(collectionId);
    }
  }, [zipStatusQuery.error]);

  const zipLoading = useMemo(() => {
    for (let z in zip) {
      if (!zip[z]) return true;
    }
    return false;
  }, [JSON.stringify(zip)]);

  const link = zipStatusQuery?.data?.zipLink;

  const dismissZip = () => {
    appState.deleteZip(collectionId);
  };

  // if (!zipLoading && !zipStatusQuery.data && !zipStatusQuery.isFetching)
  //   return null;

  return (
    <>
      {(zipLoading || zipStatusQuery.isFetching) && !zipStatusQuery.data && (
        <div {...scope} className="zip-loading">
          <Loader size="sm" />
        </div>
      )}

      {zipStatusQuery.data && (
        <div {...scope} className="zip">
          <div className="zip-images">
            <div>
              {thumbnails.length > 1 && <img src={thumbnails[1]} alt="" />}
            </div>
            <div>
              {thumbnails.length > 0 && <img src={thumbnails[0]} alt="" />}
            </div>
          </div>
          <div className="zip-container">
            <div className="zip-title">
              {link ? (
                <>{zipStatusQuery.data.count} files ready</>
              ) : (
                <>Preparing {zipStatusQuery.data.count} files...</>
              )}
            </div>
            {/*<div className="zip-secondary"></div>*/}
            <div className="zip-action">
              {!link ? (
                <Loader size="sm" />
              ) : (
                <div>
                  <Button
                    intent="primary"
                    block
                    size="sm"
                    as="a"
                    onClick={dismissZip}
                    href={zipStatusQuery.data.zipLink}
                  >
                    Download .zip file
                  </Button>
                </div>
              )}
            </div>
            <Button
              className="zip-close"
              iconOnly="xmark-regular"
              size="sm"
              intent="tertiary-grey"
              onClick={dismissZip}
            />
          </div>
        </div>
      )}
    </>
  );
}

const css = k`
  .zip {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .zip-close {
    position: absolute;
    top: .8rem;
    right: .8rem;
  }
  .zip-images {
    width: 7rem;
    height: 7rem;
    position: relative;
    margin-right: 1.6rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    div {
      height: 5rem;
      width: 5rem;
      border-radius: $border-radius-sm;
      overflow: hidden;
      position: absolute;
      border: solid .2rem $grey-100;
      background-color: $grey-400;
    }
    > div:first-child {
      bottom: 0;
      right: 0;
    }
    > div:last-child {
      top: 0;
      left: 0;
    }
  }
  .zip-container {
    flex-grow: 1;
    padding-right: 3.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-bottom: .3rem;
    gap: .8rem;
  }
  .zip-title {
    font-weight: 700;
  }

  .zip-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .8rem 0;
  }
`;
