import React, { useMemo } from "react";
import { useCss, k } from "kremling";
import { MediaPreview } from "../media-preview";
import { ProgressBar } from "../../common/progress-bar/progress-bar";
import { Button } from "../../common/button/button";
import { appState, useAppState } from "../../app-state";
import { useGetCollectionInfoQuery } from "../../queries";

export function UploadPersistentToastInner() {
  const scope = useCss(css);
  const {
    uploadFiles,
    uploadTotalProgress,
    uploadFilesCollectionId: collectionId,
    uploaderStop,
  } = useAppState([
    "uploadFilesCollectionId",
    "uploadFiles",
    "uploadTotalProgress",
    "uploaderStop",
  ]);
  const getCollectionInfoQuery = useGetCollectionInfoQuery(collectionId);

  const { upload, index, completedPhotosLength } = useMemo(() => {
    if (!uploadFiles.length) return {};
    const length = uploadFiles.filter(
      (f) => f.upload.status !== "queue"
    ).length;
    const index = length - 1;
    return { upload: uploadFiles[index], index, completedPhotosLength: length };
  }, [uploadFiles]);

  if (!upload) return null;

  return (
    <div {...scope} className="upload-box">
      <MediaPreview file={upload} className="upload-box__thumb" index={index} />
      <div className="upload-box-content">
        <div>
          <div className="uploading-to">
            {uploaderStop ? "Stopping..." : "Uploading to"}
          </div>
          <div className="collection-name">
            {getCollectionInfoQuery?.data?.title}
          </div>
        </div>
        <div className="upload-box__footer">
          <div className="upload-box__progress">
            <div>
              {completedPhotosLength} of {uploadFiles.length}
            </div>
            <ProgressBar progress={uploadTotalProgress} />
          </div>
          <Button
            size="sm"
            intent="secondary"
            onClick={() => {
              appState.set({
                uploaderStop: true,
              });
            }}
          >
            Stop
          </Button>
        </div>
      </div>
    </div>
  );
}

const css = k`
  .upload-box {
    display: flex;
    align-items: center;
    position: relative;
  }

  .upload-box__thumb {
    height: 8rem;
    width: 8rem;
    margin-right: 1.6rem;
    overflow: hidden;
    border-radius: $border-radius-md;
    background-color: $raisin-100;
  }

  .upload-box-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .uploading-to {
    font-size: 1.2rem;
    color: $text-secondary;
  }

  .collection-name {
    font-weight: 700;
    margin-bottom: .4rem;
  }

  .upload-box__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .8rem;
    font-size: 1.2rem;
  }

  .upload-box__progress {
    display: flex;
    gap: .6rem;
    flex-direction: column;
    flex-grow: 1;
  }
`;
