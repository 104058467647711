import React, { CSSProperties } from "react";
import { useCss, k, a } from "kremling";
import { Icon } from "../icon/icon";

type Props = {
  className?: string;
  style?: CSSProperties;
  checked?: boolean;
  disabled?: boolean;
};

export function ToggleCheckbox(props: Props) {
  const { className, style, checked, disabled } = props;
  const scope = useCss(css);
  return (
    <div
      {...scope}
      style={style}
      className={a("toggle-checkbox", className)
        .m("toggle-checkbox--checked", checked)
        .m("toggle-checkbox--disabled", disabled)}
    >
      <Icon className="toggle-checkbox__icon" name="check" size={14} />
    </div>
  );
}

const css = k`
  .toggle-checkbox {
    height: 2.4rem;
    width: 2.4rem;
    background-color: $grey-100;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-xs;
    border: solid .1rem var(--app-border);
    box-shadow: $shadow-btn;
    transition: background-color $form-transition-duration ease, border-color $form-transition-duration ease;
    
    .toggle-checkbox__icon {
      opacity: 0;
      transition: opacity $form-transition-duration ease;
    }
    
    &.toggle-checkbox--checked {
      background-color: $violet-500;
      color: $grey-100;
      box-shadow: none;
      border-color: transparent;
      
      .toggle-checkbox__icon {
        opacity: 1;
      }
    }
    
    &.toggle-checkbox--disabled {
      background-color: $grey-300;
      
      &.toggle-checkbox--checked {
        background-color: $grey-400;
        color: $grey-900;
      }
    }
  }
`;
