import React, { ReactNode, CSSProperties, useContext } from "react";
import { useCss, k, a } from "kremling";
import { ModalContext } from "./modal-context";
import { Button } from "../button/button";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  showCloseBtn?: boolean;
  onBack?: () => void;
};

export function ModalHeader(props: Props) {
  const { children, className, style, showCloseBtn = true, onBack } = props;
  const scope = useCss(css);
  const { onClose, disableModalExit } = useContext(ModalContext);
  return (
    <div {...scope} className={a("modal-header", className)} style={style}>
      <h2>{children}</h2>
      <div className="modal-header__actions">
        {showCloseBtn && (
          <Button
            size="sm"
            iconOnly="xmark-regular"
            onClick={onClose}
            disabled={disableModalExit}
          />
        )}
      </div>
      {!!onBack && (
        <div className="modal-header__back">
          <Button iconOnly="arrow-left-regular" onClick={onBack} />
        </div>
      )}
    </div>
  );
}

const css = k`
  .modal-header {
    padding: 2.4rem 0 2.4rem 0;
    position: relative;
    
    h2 {
      margin-bottom: 0;
      text-align: center;
    }
  }
  
  .modal-header__actions {
    position: absolute;
    right: 1.6rem;
    top: 2rem;
  }
  
  .modal-header__back {
    position: absolute;
    top: 2rem;
    left: 1.6rem;
  }
`;
