import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

export function useShareSearchParams(): {
  shareParamsStr?: string;
  shareParamsObj?: {
    collectionId: string;
    authorization: string;
    referrerUserId: string;
    uploaderCollectionId: string;
  };
} {
  const [searchParams] = useSearchParams();
  const searchParamsStr = searchParams.toString();

  const collectionId = searchParams.get("a");
  const authorization = searchParams.get("b");
  const referrerUserId = searchParams.get("c");
  const uploaderCollectionId = searchParams.get("uploader-collection-id");

  return useMemo(() => {
    return {
      shareParamsStr: searchParamsStr ? `?${searchParamsStr}` : "",
      shareParamsObj: {
        collectionId,
        authorization,
        referrerUserId,
        uploaderCollectionId,
      },
    };
  }, [searchParamsStr]);
}
