import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAppState } from "../../app-state";

export function ReferRedirect() {
  const { signedIn } = useAppState(["signedIn"]);
  const { referrerUserId } = useParams();
  let maybeSearchParams = "";
  let route = "";
  if (referrerUserId) {
    const sp = new URLSearchParams();
    sp.append("c", referrerUserId);
    maybeSearchParams = `?${sp.toString()}`;
  }
  if (signedIn) {
    // If we already have an account then just ignore the referer user ID
    route = `/collection`;
  } else {
    route = `/login-or-signup${maybeSearchParams}`;
  }
  return <Navigate to={route} replace />;
}
