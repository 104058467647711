import toast from "react-hot-toast";

import { queryClient } from "../query-client";
import { collectionListQueryKey, getCollectionInfoQueryKey } from "../queries";
import { getCollectionInfo } from "../services/api";
import urlcat, { ParamMap } from "urlcat";

export const urlCat = (
  baseUrlOrTemplate: string,
  pathTemplateOrParams: string | ParamMap,
  maybeParams?: ParamMap
) =>
  urlcat(baseUrlOrTemplate, pathTemplateOrParams, maybeParams, {
    objectFormat: {
      format: "RFC3986",
    },
  });

export function copyToClipboard(text: string) {
  if (navigator?.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      toast("Copied to clipboard");
    });
  }
}

export function generateUploaderCollectionURI(collectionId: string) {
  return (
    "https://app." +
    (!IS_PRODUCTION ? "dev." : "") +
    "picme.com/up/" +
    collectionId
  );
}

export function generateAppCollectionURI(collectionId: string) {
  return (
    "https://app." +
    (!IS_PRODUCTION ? "dev." : "") +
    "picme.com/" +
    collectionId
  );
}

export function sortAlphaNumeric(
  list: any[],
  propertyCallback: (property: any) => string
) {
  list.sort((a, b) => {
    return propertyCallback(a).localeCompare(propertyCallback(b), "en", {
      numeric: true,
    });
  });
}

export async function addCollectionToList(collectionId: string) {
  const details = await getCollectionInfo(collectionId);

  /**
   * @type {CollectionListItem}
   */
  const collectionListItem: CollectionListItem = {
    id: collectionId,
    access: "Full",
    metadataLocation: details.metadataUrl,
    metadata: {
      title: details.title,
    },
  };

  queryClient.setQueryData(
    collectionListQueryKey(),
    (old: CollectionListItem[]) => {
      const newCollectionList = [...old, collectionListItem];
      sortAlphaNumeric(
        newCollectionList,
        (item: CollectionListItem) => item.metadata.title
      );
      return newCollectionList;
    }
  );
}

export async function updateCollectionMeta(
  collectionId: string,
  next: CollectionMetadata
) {
  const metaPromise = queryClient.setQueryData(
    getCollectionInfoQueryKey(collectionId),
    (prevMetadata: CollectionMetadata) => ({
      ...prevMetadata,
      ...next,
    })
  );
  const collectionListPromise = queryClient.setQueryData(
    collectionListQueryKey(),
    (old: CollectionListItem[]) => {
      return old.map((c) => {
        if (c.id === collectionId) {
          return {
            ...c,
            metadata: {
              ...c.metadata,
              ...next,
            },
          };
        }
        return c;
      });
    }
  );
  return Promise.all([metaPromise, collectionListPromise]);
}

export async function removeCollectionFromList(collectionId: string) {
  return queryClient.setQueryData(
    collectionListQueryKey(),
    (old: CollectionListItem[]) => {
      return old.filter((c) => c.id !== collectionId);
    }
  );
}

export const shareUrl = (url: string, title: string = "", text?: string) => {
  if (navigator.canShare) {
    navigator
      .share({
        title,
        text,
        url,
      })
      .then(() => {})
      .catch(() => {});
  } else {
  }
};

export const updateCollectionMetadata = (
  collectionId: string,
  metadataDelta: CollectionMetadata
): [CollectionMetadata, CollectionMetadata] => {
  let nextMetaData = {};
  let prevMetaData = {};
  queryClient.setQueryData(
    collectionListQueryKey(),
    (old: CollectionListItem[]) => {
      return old.map((collectionListItem) => {
        if (collectionListItem.id === collectionId) {
          prevMetaData = collectionListItem.metadata;
          nextMetaData = {
            ...collectionListItem.metadata,
            ...metadataDelta,
          };
          return {
            ...collectionListItem,
            metadata: nextMetaData,
          };
        }
        return collectionListItem;
      });
    }
  );
  return [nextMetaData, prevMetaData];
};
