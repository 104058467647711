import { putUserStateData } from "../services/api";
import { queryClient } from "../query-client";
import { getUserStateKey } from "../queries";
import { appState } from "../app-state";

export async function addCollectionHistory(collectionId: string) {
  const signedIn = appState.get("signedIn");
  if (signedIn) {
    await queryClient.setQueryData(
      getUserStateKey(signedIn),
      (userState: UserState) => {
        const eventHistory = userState?.uploadHistory || [];
        const eventHistoryItemIndex = eventHistory.findIndex(
          (h) => h.collectionId === collectionId
        );

        if (eventHistoryItemIndex === -1) {
          // new
          const newUserState = {
            ...userState,
            uploadHistory: [{ collectionId }, ...eventHistory],
          };
          putUserStateData(newUserState, () => {});
          return newUserState;
        } else {
          // exists
          const newUserState = {
            ...userState,
            uploadHistory: [
              eventHistory[eventHistoryItemIndex],
              ...eventHistory.slice(0, eventHistoryItemIndex),
              ...eventHistory.slice(eventHistoryItemIndex + 1),
            ],
          };
          putUserStateData(newUserState, () => {});
          return newUserState;
        }
      }
    );
  }
}

export async function deleteCollectionHistory() {
  return putUserStateData({}, () => {});
}

export async function removeCollectionHistory(collectionId: string) {
  const signedIn = appState.get("signedIn");
  if (signedIn) {
    await queryClient.setQueryData(
      getUserStateKey(signedIn),
      (userState: UserState) => {
        if (
          userState.uploadHistory.find((h) => h.collectionId === collectionId)
        ) {
          const newUserState = {
            ...userState,
            uploadHistory: userState.uploadHistory.filter(
              (h) => h.collectionId !== collectionId
            ),
          };
          putUserStateData(newUserState, () => {});
          return newUserState;
        }
        return userState;
      }
    );
  }
}
