import { startAuthentication } from "../services/api";
import { saveRefreshToken } from "../pages/auth/auth-utils";
import { appState } from "../app-state";

export const loginUtil = (email: string, password: string) => {
  return startAuthentication(email, password).then((response) => {
    saveRefreshToken(
      response.successfulAuthentication.authenticated.refreshToken
    );
    appState.set({
      email,
      accessToken: response.successfulAuthentication.authenticated.accessToken,
      refreshToken:
        response.successfulAuthentication.authenticated.refreshToken,
      signedIn: true,
    });
    return response;
  });
};
