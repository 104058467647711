import React, { ReactNode, useEffect, memo } from "react";
import toast, { ToastPosition } from "react-hot-toast";

type PersistentToastProps = {
  children: ReactNode;
  id: string;
  position?: ToastPosition;
};

export const PersistentToast = memo(
  (props: PersistentToastProps): null => {
    const { children, id, position = "bottom-right" } = props;

    useEffect(() => {
      toast(<div style={{ width: "100%" }}>{children}</div>, {
        id,
        duration: Infinity,
        position,
        style: {
          backgroundColor: "#ffffff",
          color: "#000000",
          borderRadius: "1.2rem",
          fontSize: "1.4rem",
          padding: ".8rem .2rem",
          minWidth: 320,
        },
      });
      return () => {
        toast.dismiss(id);
      };
    }, [id, children]);

    return null;
  },
  () => {
    return true;
  }
);
