import React from "react";
import { useCss, k, a } from "kremling";
import { Icon } from "../icon/icon";

type Props = {
  icon?: Icons;
  label?: string;
};

export function Badge(props: Props) {
  const { icon, label } = props;
  const scope = useCss(css);
  return (
    <div {...scope} className={a("badge").m("--square", icon && !label)}>
      {!!icon && <Icon name={icon} size={14} />}
      {!!label && <div className="badge-label">{label}</div>}
    </div>
  );
}

const css = k`
  .badge {
    background-color: #fa00ff;
    border-radius: $border-radius-sm;
    text-align: center;
    padding: 0 .4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    display: inline-flex;
    align-items: center;
    gap: .4rem;
    color: $grey-100;
    font-size: 1.2rem;
    font-weight: 400;
    justify-content: center;
    
    &.--square {
      padding: 0;
    }
  }
  
  .badge-label {
    display: none;
    
    @include breakpoint(lg) {
      display: inline-flex;
    }
  }
`;
