import React, { forwardRef, useMemo } from "react";
import { Control, useController, UseControllerProps } from "react-hook-form";

import { Input } from "./input";
import type { InputProps } from "./input";
import {
  isEmailOrPhoneNumber,
  validateEmail,
  validatePassword,
} from "../../utils/validation";

type InputFieldProps = Omit<InputProps, "type"> & {
  control: Control<any>;
  fieldName: string;
  required?: boolean;
  passwordLength?: number;
  validatePasswordRules?: boolean;
  rules?: { errorMessage: string; validate: (v: any) => boolean }[];
  type?: InputProps["type"] | "emailOrPhoneNumber";
  onChange?: (value: string) => void;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const {
      fieldName,
      control,
      required,
      type = "text",
      minLength,
      validatePasswordRules,
      rules,
      onChange: _onChange,
      ...restOfProps
    } = props;

    const mergedRules = useMemo(() => {
      const mRules: UseControllerProps["rules"] = {};
      if (required) {
        mRules.required = "Field is required";
      }

      mRules.validate = (v) => {
        if (type === "password") {
          // password
          if (validatePasswordRules) {
            if (!validatePassword(v)) {
              return "Invalid password. Your password must be at least 8 characters and contain at least 1 number, 1 uppercase character, 1 lowercase character, and 1 special character.";
            }
          } else if (minLength) {
            if (v.length < minLength) {
              return `Password must have at least ${minLength} characters`;
            }
          }
        } else if (type === "email") {
          // email
          if (!validateEmail(v)) {
            return "Email must be valid";
          }
        } else if (type === "emailOrPhoneNumber") {
          // email or phone number
          if (!isEmailOrPhoneNumber(v)) {
            return "Must be a valid email or phone number";
          }
        }
        if (rules) {
          for (let i = 0; i < rules.length; i++) {
            const rule = rules[i];
            if (!rule.validate(v)) {
              return rule.errorMessage;
            }
          }
        }
        return true;
      };

      return mRules;
    }, [JSON.stringify(rules), required, type, minLength]);

    const {
      field: { value, onChange, onBlur, ref: fieldRef },
      fieldState: { error },
    } = useController({ name: fieldName, control, rules: mergedRules });

    return (
      <Input
        value={value}
        onChange={(e) => {
          onChange(e);
          if (_onChange) {
            _onChange(e);
          }
        }}
        onBlur={onBlur}
        error={error?.message}
        required={required}
        ref={ref}
        type={type}
        minLength={minLength}
        {...restOfProps}
      />
    );
  }
);
