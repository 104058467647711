import React from "react";
import { k, useCss } from "kremling";

import { useOutletContext } from "react-router-dom";
import { useGetCollectionInfoQuery } from "../../../queries";
import { OverlayPage } from "../../../components/overlay-page/overlay-page";
import { CollectionSettingsForm } from "./collection-settings-form";

export const CollectionSettings = () => {
  const scope = useCss(css);
  const { collection } = useOutletContext();
  const collectionInfoQuery = useGetCollectionInfoQuery(collection.id);

  return (
    <div {...scope} className="collection-settings">
      <OverlayPage title="Collection Settings">
        <div className="collection-edit-container">
          <div>
            {collectionInfoQuery.isFetched && (
              <CollectionSettingsForm
                title={collectionInfoQuery?.data?.title}
                message={collectionInfoQuery?.data?.message}
                coverIcon={collectionInfoQuery?.data?.coverIcon}
                coverColor={collectionInfoQuery?.data?.coverColor}
                pause={collectionInfoQuery?.data?.pause}
              />
            )}
          </div>
        </div>
      </OverlayPage>
    </div>
  );
};

const css = k`
  .collection-settings {
    margin: 0 auto 3.2rem auto;
    max-width: 55rem;
    
    @include breakpoint(lg) {
      max-width: 102.4rem;
    }
  }
  .collection-edit-container {
    display: flex;
    justify-content: center;
    padding: 3.2rem 1.6rem;
    margin: 0 auto;

    @include breakpoint(lg) {
      gap: 3.2rem;
    }
  }

  .collection-edit-left {
    width: 45rem;
  }

  .collection-edit-right {
    width: 47.2rem;
    background: linear-gradient(
        115.54deg,
        rgba(57, 124, 255, 0.15) 13.39%,
        rgba(187, 78, 253, 0.04) 81.35%
    );
    border-radius: 2.4rem;
    display: none;
    justify-content: center;
    height: 85rem;
    padding: 1.6rem 5rem 5rem 5rem;
    flex-direction: column;

    @include breakpoint(lg) {
      display: flex;
    }

    &.show-preview {
      display: flex;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: none;
      width: auto;

      .phone {
        position: absolute;
        border: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .phone {
    width: 100%;
    border: solid 1.5rem #202123;
    background-color: white;
    border-radius: 5rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 12.4rem;
    user-select: none;
    pointer-events: none;

    .phone-actions {
      display: flex;
      gap: .8rem;
      justify-content: center;
    }

    .phone-title {
      font-size: 2.4rem;
      font-weight: 700;
      margin-bottom: .8rem;
      text-align: center;
      padding: 0 2.4rem;
    }

    .phone-message {
      margin-bottom: 2.4rem;
      padding: 0 2.4rem;
      font-size: 1.8rem;
    }
  }

  .big-button {
    width: 15.1rem;
    background: #0014cc;
    padding: 1.8rem 0;
    box-shadow: 0 0 1.5rem rgba(45, 0, 221, 0.06),
      0 1rem 1.5rem -1rem rgba(45, 0, 221, 0.1);
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & svg {
      fill: #fff;
      width: 2rem;
      margin-bottom: .4rem;
    }

    & div {
      color: #fff;
      font-weight: 700;
      font-size: 1.6rem;
    }
  }

  .collection-img {
    width: 9.6rem;
    height: 9.6rem;
    border: solid .1rem #d3d3d3;
    border-radius: 1.2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.has-img {
      border: none;
    }
  }

  .upload-collection-img {
    display: flex;
    margin-bottom: 2.4rem;

    .collection-image-action-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: .4rem;
      text-align: left;
    }

    .collection-image-upload {
      display: none;
    }
  }

  .setting {
    display: flex;
    margin-bottom: 2.4rem;
    align-items: center;
    gap: 1.6rem;
    justify-content: space-between;

    .setting-content {
      width: 100%;
      max-width: 75%;
    }

    .setting-title {
      font-weight: 700;
      font-size: 1.6rem;
    }

    .setting-description {
      font-size: 1.4rem;
      font-weight: 500;
      color: #696969;
    }

    .setting-action {
      display: flex;
      justify-content: flex-end;
    }
  }

`;
