import React, { ReactNode } from "react";

import { MenuComponent, MenuComponentProps } from "./menu-component";
import { Icon } from "../icon/icon";
import { useCss, a, k } from "kremling";

type MenuItemProps = MenuComponentProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string | ReactNode;
    iconLeft?: Icons | ReactNode;
    active?: boolean;
    block?: boolean;
  };

export const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  ({ label, iconLeft, active, block, ...props }, ref) => {
    const scope = useCss(css);
    const renderItem = ({ isSubMenu, ...refProps }: any) => (
      <button
        {...scope}
        {...props}
        {...refProps}
        role="menuitem"
        disabled={props.disabled}
        className={a("menu-item")
          .m("--disabled", props.disabled)
          .m("--block", block)}
      >
        <div className="menu-item__inner">
          {!!iconLeft && (
            <div className="menu-item__icon">
              {typeof iconLeft === "string" && (
                <Icon
                  name={iconLeft as Icons}
                  className="menu-item-icon-left"
                />
              )}
              {typeof iconLeft !== "string" && iconLeft}
            </div>
          )}
          {label}
        </div>
        {active && <Icon name="circle-check-solid" className="sub-menu-icon" />}
        {isSubMenu && (
          <Icon className="sub-menu-icon" name="angle-right-solid" size={14} />
        )}
      </button>
    );

    if (props.children) {
      return <MenuComponent {...props} ref={ref} renderItem={renderItem} />;
    }
    return renderItem({ ref, isSubMenu: false });
  }
);

MenuItem.displayName = "MenuItem";

const css = k`
  .menu-item {
    
    &.--disabled {
      color: var(--button-tertiary-disabled-color);
      background: var(--button-tertiary-disabled-bg);
    }
  }
`;
