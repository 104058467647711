import React, { ReactNode, CSSProperties, useMemo } from "react";
import { Menu, MenuItem } from "../common/menu";
import { Button } from "../common/button/button";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  value: string;
  icon?: Icons | ReactNode;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  block?: boolean;
  options: {
    id: string;
    icon?: Icons | ReactNode;
    label: string;
  }[];
};

export function MenuSingleSelect(props: Props) {
  const {
    value,
    onChange,
    block = true,
    disabled,
    icon,
    options,
    placeholder,
  } = props;

  const labelMap = useMemo(() => {
    const m: Record<string, string> = {};
    options.forEach((item) => {
      m[item.id] = item.label;
    });
    return m;
  }, []);

  return (
    <Menu
      contentWidth={block ? "block" : "content"}
      renderTrigger={(refProps, controlProps) => (
        <Button
          {...refProps}
          intent="tertiary"
          block={block}
          alignText="left"
          dropdown
          active={controlProps.open}
          iconLeft={icon}
          disabled={disabled}
        >
          {labelMap?.[value] || placeholder}
        </Button>
      )}
    >
      {options.map((item) => (
        <MenuItem
          key={item.id}
          label={item.label}
          iconLeft={item.icon}
          block={block}
          onClick={() => onChange(item.id)}
        />
      ))}
    </Menu>
  );
}
