import React from "react";
import { useCss, k, a } from "kremling";
import { Icon } from "../common/icon/icon";

type Props = {
  className?: string;
};

export function VideoTag(props: Props) {
  const { className } = props;
  const scope = useCss(css);
  return (
    <div {...scope} className={a("video-tag", className)}>
      <Icon name="play-solid" size={14} className="mr-4" />
    </div>
  );
}

const css = k`
  .video-tag {
    border-radius: $border-radius-full;
    height: 3.2rem;
    width: 3.2rem;
    background-color: rgba($raisin-900, .5);
    color: $grey-100;
    position: absolute;
    bottom: .8rem;
    left: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: .4rem;
    z-index: 200;
  }
`;
