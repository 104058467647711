import React, { useEffect, useMemo, useState } from "react";
import { useCss, k } from "kremling";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import { Loader } from "../../common/loader/loader";
import { NoCollections } from "./components/no-collections";
import {
  useCollectionDetailsQuery,
  useCollectionListQuery,
  useGetUserInfoQuery,
} from "../../queries";
import { modalService } from "../../common/modal-service/modal-service";
import { InvitationModal } from "../../components/invitation-modal";
import { useShareSearchParams } from "../../hooks/use-share-search-params";
import { ZipPersistentToast } from "../../components/zip-persistent-toast/zip-persistent-toast";
import { collectionIdKey } from "../auth/auth-utils";
import { UploadPersistentToast } from "../../components/upload-persistent-toast/upload-persistent-toast";

export const Collection = () => {
  const scope = useCss(css);
  const { collectionId } = useParams();
  const collectionListQuery = useCollectionListQuery();
  const collectionDetailsQuery = useCollectionDetailsQuery(collectionId);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { shareParamsObj, shareParamsStr } = useShareSearchParams();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const userInfoQuery = useGetUserInfoQuery();

  useEffect(() => {
    if (collectionId && collectionListQuery?.data?.length) {
      if (!collectionListQuery?.data.some((item) => item.id === collectionId)) {
        navigate("/collection");
      }
    }
  }, [collectionId, collectionListQuery?.data?.length]);

  // handle share invite
  useEffect(() => {
    if (shareParamsStr && collectionListQuery?.data && userInfoQuery?.data) {
      if (
        !collectionListQuery?.data?.some(
          (c) => c.id === shareParamsObj.collectionId
        ) &&
        shareParamsObj.referrerUserId !== userInfoQuery.data.userData.userId
      ) {
        setShowInviteModal(true);
      } else {
        navigate(location.pathname, { replace: true });
      }
    }
  }, [collectionListQuery?.data, userInfoQuery?.data]);

  useEffect(() => {
    if (showInviteModal) {
      if (
        shareParamsObj.collectionId &&
        shareParamsObj.authorization &&
        shareParamsObj.referrerUserId
      ) {
        modalService
          .render(InvitationModal, null, {
            allowBackdropClose: false,
          })
          .then(async (collectionId) => {
            if (collectionId) {
              navigate(`/collection/${collectionId}`);
              collectionListQuery.refetch();
            }
          });
      }
    }
  }, [showInviteModal]);

  useEffect(() => {
    const collections = collectionListQuery?.data;
    // We are on the collection list page so pick the first collection or leave it on this page.
    if (
      (pathname === "/collection" || pathname === "/collection/") &&
      collections?.length > 0
    ) {
      const firstCollection = collections.find((c) => c.access === "Full");
      navigate(
        `/collection/${
          firstCollection ? firstCollection.id : collections[0].id
        }${shareParamsStr}`
      );
    }
  }, [pathname, collectionListQuery?.data?.length]);

  const collection = useMemo<CollectionAndUploads>(() => {
    let c = {};
    if (collectionListQuery.isSuccess) {
      const listItem = collectionListQuery?.data?.find(
        (c) => c.id === collectionId
      );
      c = { ...c, ...(listItem || {}) };
    }
    if (collectionDetailsQuery.isSuccess) {
      c = {
        ...c,
        ...({ locations: collectionDetailsQuery.data.collection } || {}),
      };
    }
    return c as CollectionAndUploads;
  }, [collectionListQuery, collectionDetailsQuery]);

  useEffect(() => {
    if (collection?.id) {
      localStorage.setItem(collectionIdKey, collection.id);
    }
  }, [collection?.id]);

  if (collectionListQuery.isLoading) {
    return (
      <div {...scope} className="loading">
        <Loader />
      </div>
    );
  }

  if (collectionListQuery.error) {
    return <Navigate to="/login-or-signup" />;
  }

  if (!collectionListQuery.data?.length) {
    return <NoCollections />;
  }

  return (
    <>
      <Outlet
        context={
          {
            collection,
          } satisfies CollectionAndUploadsContext
        }
      />
      <ZipPersistentToast collectionId={collectionId} />
      <UploadPersistentToast />
    </>
  );
};

export function useCollection() {
  return useOutletContext<CollectionAndUploadsContext>();
}

const css = k`
  .loading {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
`;
