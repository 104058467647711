import React, { useEffect, useState } from "react";
import { ModalHeader } from "../../../common/modal/modal-header";
import { ModalBody } from "../../../common/modal/modal-body";
import { ModalFooter } from "../../../common/modal/modal-footer";
import { Button } from "../../../common/button/button";
import { useCss, k } from "kremling";
import { Checkbox } from "../../../common/checkbox/checkbox";
import { isEmpty, isEqual } from "lodash";
import { useGetUserInfoQuery } from "../../../queries";
import { sortBy } from "lodash";
import { Loader } from "../../../common/loader/loader";
import { Modal, ModalProps } from "../../../common/modal/modal";

type FilterModalProps = ModalProps & FilterProps;

type FilterProps = {
  collection: Collection;
  modalResolve: (data?: any) => void;
  existingFilters: Filters;
  userNameMap: Record<string, { name: string }>;
  loadingFilters: boolean;
};

type Filters = {
  uploaders: string[];
};

export function FilterModal({
  collection,
  modalResolve,
  existingFilters,
  userNameMap,
  loadingFilters,
  onClose,
  ...modalProps
}: FilterModalProps) {
  const scope = useCss(css);
  const fullAccess = collection?.access === "Full";
  const [filters, setFilters] = useState<Filters>(existingFilters);
  const { data } = useGetUserInfoQuery();

  useEffect(() => {
    if (existingFilters) {
      setFilters(existingFilters);
    }
  }, [existingFilters]);

  const validateFilters = () => {
    if (loadingFilters) return true;
    const changesMade = !isEqual(existingFilters, filters);
    if (changesMade) return false;
    return isEmpty(filters.uploaders);
  };

  return (
    <Modal {...modalProps} onClose={onClose} style={{ height: "100%" }}>
      <div {...scope} className="as-modal" style={{ height: "100%" }}>
        <ModalHeader>Filter Photos</ModalHeader>
        {loadingFilters ? (
          <ModalBody>
            <Loader />
          </ModalBody>
        ) : (
          <ModalBody className="filters-body">
            {fullAccess ? (
              <div>
                <div className="filters-section_title">Uploaders</div>
                {sortBy(Object.entries(userNameMap), [
                  (entry) => entry[1].name,
                ]).map(([id, { name }]) => {
                  const boxId = `checkbox-${id}`;
                  return (
                    <div className="uploader-item" key={id}>
                      <Checkbox
                        checked={filters["uploaders"].some(
                          (uploaderId) => id === uploaderId
                        )}
                        onChange={(checked) => {
                          setFilters((prevState) => {
                            return {
                              ...prevState,
                              uploaders: checked
                                ? [...prevState.uploaders, id]
                                : prevState.uploaders.filter(
                                    (uploaderId) => uploaderId !== id
                                  ),
                            };
                          });
                        }}
                        id={boxId}
                      />
                      <label htmlFor={boxId}>{name}</label>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <Checkbox
                  label="View my uploads only"
                  checked={filters["uploaders"].some(
                    (uploaderId) => data?.userData?.userId === uploaderId
                  )}
                  onChange={(checked) => {
                    setFilters((prevState) => {
                      return {
                        ...prevState,
                        uploaders: checked ? [data?.userData?.userId] : [],
                      };
                    });
                  }}
                />
              </div>
            )}
          </ModalBody>
        )}
        <ModalFooter className="action-footer">
          <Button className="mr-8" intent="tertiary" onClick={onClose} block>
            Cancel
          </Button>
          <Button
            disabled={validateFilters()}
            onClick={() => modalResolve(filters)}
            intent="primary"
            block
          >
            Save
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

const css = k`
  .filters-body {
    flex: 1;
    height: 100%;
    overflow-y: auto;
  }
  
  .filters-section_title {
    margin-bottom: .8rem;
    font-weight: 600;
  }
  
  @include breakpoint(lg) {
    .filters-body {
      max-height: 30rem;
    }
  }
  
  .uploader-item {
    display: flex;
    align-items: center;
    margin-bottom: .8rem;
  }

  .action-footer {
    display: flex;
  }
`;
