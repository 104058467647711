import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "../../common/button/button";

import { checkApiResponse } from "../../utils/utils";
import { BackgroundCard } from "../../components/background-card";
import { useForm } from "react-hook-form";
import { InputField } from "../../common/input/input-field";
import { sessionEmailKey } from "./auth-utils";
import { AuthTitle } from "../../components/auth-title";
import { ShareBanner } from "../../components/share-banner";
import { useShareSearchParams } from "../../hooks/use-share-search-params";
import { loginUtil } from "../../utils/login-util";
import { queryClient } from "../../query-client";
import { collectionListQueryKey } from "../../queries";
import { requestPasswordReset } from "../../services/api";

export const LogIn = () => {
  const navigate = useNavigate();
  const [topError, setTopError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { shareParamsStr, shareParamsObj } = useShareSearchParams();

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: { email: "", password: "" },
  });

  useEffect(() => {
    const savedEmail = sessionStorage.getItem(sessionEmailKey);
    if (savedEmail) {
      setValue("email", savedEmail);
    } else {
      navigate(`/login-or-signup${shareParamsStr}`);
    }
  }, []);

  const login = ({ email, password }) => {
    setTopError();

    setIsLoading(true);
    loginUtil(email, password)
      .then(async () => {
        if (shareParamsObj.uploaderCollectionId) {
          const collectionId = shareParamsObj.uploaderCollectionId;
          try {
            const response = await queryClient.fetchQuery(
              collectionListQueryKey()
            );

            // if you have access to collection, go to main page
            if (response && response?.length) {
              if (response && response.some((c) => c?.id === collectionId)) {
                navigate(`/collection/${collectionId}`);
              } else {
                navigate(`/up/${collectionId}`);
              }
            } else {
              throw Error();
            }
          } catch (e) {
            navigate(`/up/${collectionId}`);
          }
        } else {
          navigate(`/collection${shareParamsStr}`);
        }
      })
      .catch(async (error) => {
        try {
          const res = JSON.parse(error.request?.response);
          if (res?.code === "PasswordResetRequired") {
            await requestPasswordReset(email);
            navigate("/reset-password");
          }
        } catch (e) {}
        setTopError(
          checkApiResponse(JSON.parse(error?.request?.response).code)
        );
        setIsLoading(false);
      });
  };

  const autoTos = () => {};

  return (
    <form onSubmit={handleSubmit(login)} noValidate>
      <BackgroundCard>
        <AuthTitle
          backCallback={() => navigate(`/login-or-signup${shareParamsStr}`)}
          disableBackCallback={isLoading}
        >
          Log in
        </AuthTitle>
        <ShareBanner />
        {!!topError && (
          <div className="text-error text-center mb-16">{topError}</div>
        )}

        <div className="mb-12">
          <InputField
            label="Email or Phone Number"
            placeholder="Email or Phone Number"
            required
            control={control}
            fieldName="email"
            type="emailOrPhoneNumber"
            scale="lg"
          />
        </div>
        <div className="mb-24 ">
          <InputField
            required
            autoFocus
            control={control}
            fieldName="password"
            type="password"
            placeholder="Password"
            label="Password"
            minLength={8}
            seePassword
            scale="lg"
          />
        </div>

        <div className="button-group mb-16">
          <Button
            as={Link}
            to={`/login-or-signup${shareParamsStr}`}
            block
            intent="tertiary"
            size="lg"
          >
            Cancel
          </Button>
          <Button
            intent="primary"
            isLoading={isLoading}
            type="submit"
            block
            alignText="center"
            size="lg"
          >
            Continue
          </Button>
        </div>
        <div className="text-center font-medium">
          <Link to="/forgot-password">Forgot password?</Link>
        </div>
      </BackgroundCard>
    </form>
  );
};
