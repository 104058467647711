import React from "react";

import { SlideType } from "./photo-viewer-types";
import { SlideDelete } from "./actions/slide-delete";
import { Case, Switch } from "../../common/switch/switch";
import { SlideShare } from "./actions/slide-share";
import { SlideDownload } from "./actions/slide-download";
import { SlideRestore } from "./actions/slide-restore";
import { SlidePurge } from "./actions/slide-purge";

export type PhotoViewerActionsProps = {
  fullAccess: boolean;
  collectionId: string;
  slide: SlideType;
  setNextIncrement: (index: number, arrayLengthDiff?: number) => void;
  actions: ("delete" | "share" | "download" | "restore" | "purge")[];
};

export function PhotoViewActions(props: PhotoViewerActionsProps) {
  const { fullAccess, collectionId, slide, actions, setNextIncrement } = props;

  return (
    <>
      {actions.map((action) => (
        <Switch expression={action} key={action}>
          <Case value="delete">
            <SlideDelete
              fullAccess={fullAccess}
              collectionId={collectionId}
              slide={slide}
              setNextIncrement={setNextIncrement}
            />
          </Case>
          <Case value="share">
            <SlideShare slide={slide} />
          </Case>
          <Case value="download">
            <SlideDownload slide={slide} />
          </Case>
          <Case value="restore">
            <SlideRestore
              collectionId={collectionId}
              slide={slide}
              setNextIncrement={setNextIncrement}
              fullAccess={fullAccess}
            />
          </Case>
          <Case value="purge">
            <SlidePurge
              collectionId={collectionId}
              slide={slide}
              setNextIncrement={setNextIncrement}
              fullAccess={fullAccess}
            />
          </Case>
        </Switch>
      ))}
    </>
  );
}
