import React, { ReactNode, CSSProperties } from "react";
import { useCss, k, a } from "kremling";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
};

export function Terms(props: Props) {
  const { children, className, style } = props;
  const scope = useCss(css);
  return (
    <div {...scope} className={a("terms", className)} style={style}>
      {children}, you agree to our
      <br />
      <a href="https://picme.com/terms-of-use.html" target="_blank">
        Terms of Use
      </a>{" "}
      and{" "}
      <a href="https://picme.com/privacy-policy.html" target="_blank">
        Privacy Policy
      </a>
      .
    </div>
  );
}

const css = k`
  .terms {
    color: $text-secondary;
    text-align: center;
    max-width: 40rem;
    margin: 0 auto;
    font-size: 1.4rem;
  }
`;
