import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Button } from "../../../common/button/button";
import { Menu, MenuItem } from "../../../common/menu";
import { useCss, k } from "kremling";
import {
  collectionTrashQueryKey,
  useCollectionTrashQuery,
} from "../../../queries";
import { CollectionGrid } from "../../../components/collection-grid";
import { PhotoViewer } from "../../../components/photo-viewer/photo-viewer";
import { isVideo, isVideoMimetype, selectionLimit } from "../../../utils/utils";
import { isEmpty } from "lodash";
import {
  restoreTrashedUploads,
  restoreAllTrashedUploads,
  getOriginalUploadUrls,
  deleteUpload,
} from "../../../services/api";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { appState } from "../../../app-state";
import { dialog } from "../../../common/dialog/dialog";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { OverlayPage } from "../../../components/overlay-page/overlay-page";
import { useMediaQuery } from "../../../hooks/use-media-query";

const imagesPerPage = 100;

export function CollectionTrashList() {
  const { collection } = useOutletContext();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const { collectionId } = useParams();
  const [endOfList, setEndOfList] = useState(false);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [photoViewer, setPhotoViewer] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const [pauseRefetchInterval, setPauseRefetchInterval] = useState(false);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const queryClient = useQueryClient();
  const scope = useCss(css);
  const fullAccess = collection?.access === "Full";
  const [gridSize, setGridSize] = useState(appState.get("gridSize"));

  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } =
    useCollectionTrashQuery(
      collectionId,
      imagesPerPage,
      endOfList && !pauseRefetchInterval ? 1000 * 10 : null
    );

  useEffect(() => {
    setEndOfList(!hasNextPage);
  }, [hasNextPage]);

  const openPhotoViewer = (index) => {
    setLightboxIndex(index);
    setPhotoViewer(true);
  };

  function handleRestoreAll() {
    queryClient.setQueryData(collectionTrashQueryKey(collectionId), (data) => ({
      pages: data.pages.map((next) => ({
        ...next,
        trashedUploads: [],
      })),
      pageParams: data.pageParams,
    }));

    restoreAllTrashedUploads(collectionId).then(() => {
      toast.success(`Successfully restored all files`);
    });
  }

  function handleRestoreSelected() {
    queryClient.setQueryData(collectionTrashQueryKey(collectionId), (data) => ({
      pages: data.pages.map((next) => {
        return {
          ...next,
          trashedUploads: next.trashedUploads.filter(
            (p) => !selectedFileIds.includes(p.uploadId)
          ),
        };
      }),
      pageParams: data.pageParams,
    }));

    setSelectedFileIds([]);
    restoreTrashedUploads(collectionId, selectedFileIds).then(() => {
      toast.success(`Successfully restored selected files`);
    });
  }

  const photos = useMemo(() => {
    return (
      data?.pages.reduce((acc, p, i) => {
        return [
          ...acc,
          ...p.trashedUploads.map((photo, j) => {
            if (isVideo(photo.uploadId)) {
              return {
                ...photo,
                src: photo.originalUrl,
                index: i * imagesPerPage + j,
                type: "video",
                poster: photo.thumbnailUrl,
                sources: [
                  {
                    src: photo.originalUrl,
                  },
                ],
              };
            } else {
              return {
                ...photo,
                src: photo.originalUrl,
                index: i * imagesPerPage + j,
              };
            }
          }),
        ];
      }, []) || []
    );
  }, [data]);

  const slides = useMemo(() => {
    return (
      data?.pages.reduce((acc, p) => {
        return [
          ...acc,
          ...p.trashedUploads.map((photo) => {
            return {
              id: photo.uploadId,
              uid: photo.uploadId,
              type: !isVideoMimetype(photo.mimeType) ? "image" : "video",
              src: () =>
                getOriginalUploadUrls(collectionId, photo.uploadId, true).then(
                  (res) => ({
                    url: res.originalUrl,
                    downloadUrl: res.originalDownloadUrl,
                  })
                ),
            };
          }),
        ];
      }, []) || []
    );
  }, [data]);

  const toggleGridSize = () => {
    setGridSize((gridSize) => {
      const newGridSize = gridSize === "sm" ? "lg" : "sm";

      // Update app state
      appState.updateGridSize(newGridSize);
      return newGridSize;
    });
  };

  const handleEmptyTrash = async () => {
    setPauseRefetchInterval(true);
    await dialog({
      title: "Empty trash bin",
      description: (
        <>
          <p>Are you sure you want to empty the trash bin?</p>
          <div className="text-error">This action cannot be undone.</div>
        </>
      ),
      submitText: "Empty",
      onSubmitCallback: async () => {
        await clearCollectionPhotos(collectionId);
        await refetch();
      },
    });
    setPauseRefetchInterval(false);
  };

  const clearSelectedPhotos = () => {
    const concurrentRequestsLimit = 10;

    setSelectedFileIds([]);
    queryClient.setQueryData(collectionTrashQueryKey(collectionId), (data) => ({
      pages: data.pages.map((next) => ({
        ...next,
        trashedUploads: next.trashedUploads.filter(
          (p) => !selectedFileIds.includes(p.uploadId)
        ),
      })),
      pageParams: data.pageParams,
    }));

    from(selectedFileIds)
      .pipe(
        mergeMap(
          (photoId) => from(deleteUpload(collectionId, photoId)),
          concurrentRequestsLimit
        )
      )
      .subscribe({
        complete: () => toast.success(`Successfully deleted permanently`),
        error: () => toast.error(`Failed delete permanently`),
      });
  };

  const handlePurgeSelected = async () => {
    setPauseRefetchInterval(true);
    await dialog({
      title: `Permanently remove ${selectedFileIds.length} item${
        selectedFileIds.length === 1 ? "" : "s"
      }`,
      description: (
        <>
          <p>
            Are you sure you want to permanently remove{" "}
            {`${selectedFileIds.length} item${
              selectedFileIds.length === 1 ? "" : "s"
            }`}
          </p>
          <div className="text-error">This action cannot be undone.</div>
        </>
      ),
      submitText: `Delete ${selectedFileIds.length} item${
        selectedFileIds.length > 1 ? "s" : ""
      }`,
      onSubmitCallback: async () => {
        await clearSelectedPhotos();
      },
    });
    setPauseRefetchInterval(false);
  };

  if (isLoading) {
    return (
      <div {...scope}>
        <div className="wrapper">
          <Button intent="secondary" isLoading={isFetching || isLoading}>
            Load more
          </Button>
        </div>
      </div>
    );
  }

  const hasPhotos = !isEmpty(slides);

  return (
    <OverlayPage title="Trash" fullScreen>
      <div {...scope}>
        {!isSelectionMode ? (
          <div className="photo-header">
            <div className="photo-header__actions">
              <Button
                intent="secondary-grey"
                iconOnly={!isDesktop ? "circle-check-regular" : null}
                iconLeft={isDesktop ? "circle-check-regular" : null}
                onClick={() => setIsSelectionMode((prevState) => !prevState)}
              >
                {isDesktop ? "Select" : null}
              </Button>
            </div>
            <div className="photo-header__actions">
              <Menu
                fancy
                placementSide="end"
                renderTrigger={(refProps, controlProps) => (
                  <Button
                    {...refProps}
                    intent="secondary-grey"
                    active={controlProps.open}
                    iconOnly="ellipsis-regular"
                    iconOnlyProps={{ size: 22 }}
                  />
                )}
              >
                <MenuItem
                  iconLeft={
                    gridSize === "sm"
                      ? "arrow-up-right-and-arrow-down-left-from-center-regular"
                      : "arrow-down-left-and-arrow-up-right-to-center-regular"
                  }
                  label={`${gridSize === "sm" ? "Large" : "Small"} grid size`}
                  onClick={toggleGridSize}
                />
                <MenuItem
                  iconLeft="trash-arrow-up-solid"
                  disabled={!hasPhotos}
                  onClick={handleRestoreAll}
                  label="Restore all"
                />
              </Menu>
            </div>
          </div>
        ) : (
          <div className="photo-header">
            <div>
              <div className="photo-selection__actions">
                <Button
                  className="mr-8"
                  onClick={() => {
                    setIsSelectionMode(false);
                    setSelectedFileIds([]);
                  }}
                  intent="secondary-grey"
                  iconOnly="xmark-regular"
                  iconOnlyProps={{ size: 28 }}
                />
                {selectedFileIds.length}/20
              </div>
            </div>
            <div className="photo-header__actions">
              <Button
                disabled={isEmpty(selectedFileIds)}
                intent="secondary-grey"
                iconOnly={!isDesktop ? "trash-arrow-up-solid" : null}
                iconLeft={isDesktop ? "trash-arrow-up-solid" : null}
                onClick={handleRestoreSelected}
              >
                {isDesktop ? "Restore" : null}
              </Button>
              <Button
                disabled={isEmpty(selectedFileIds)}
                intent="secondary-grey"
                iconOnly={!isDesktop ? "trash" : null}
                iconLeft={isDesktop ? "trash" : null}
                onClick={handlePurgeSelected}
              >
                {isDesktop ? "Remove" : null}
              </Button>
            </div>
          </div>
        )}
      </div>

      {!hasPhotos ? (
        <div className="wrapper">
          <div className="text-sub-title">Trash bin empty</div>
        </div>
      ) : (
        <div className="photos-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: ".4rem",
              fontSize: "1.2rem",
            }}
          >
            (Items expire after 30 days)
          </div>
          <CollectionGrid
            files={photos}
            setLightboxIndex={openPhotoViewer}
            selectedFileIds={selectedFileIds}
            setSelectedFileIds={setSelectedFileIds}
            isSelectionMode={isSelectionMode}
            gridSize={gridSize}
          />
          {hasNextPage && (
            <div className="text-center pv-32">
              <Button
                intent="secondary"
                isLoading={isFetching || isLoading}
                onClick={() => {
                  fetchNextPage();
                }}
              >
                Load more
              </Button>
            </div>
          )}
          {photoViewer && (
            <PhotoViewer
              actions={["restore", "purge"]}
              onClose={() => setPhotoViewer(false)}
              slides={slides}
              initialIndex={lightboxIndex}
              fullAccess={fullAccess}
              collectionId={collectionId}
            />
          )}
        </div>
      )}
    </OverlayPage>
  );
}

const css = k`
  .wrapper {
    display: flex;
    height: calc(100vh - 22.5rem);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 25rem;
    text-align: center;
    margin: 0 auto;
  }

  .photo-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .photos-list-title_container {
    display: flex;
    align-items: center;
  }
  
  .title {
    font-size: 1.6rem;
    font-weight: 700;
  }
  
  .subtitle {
    font-size: 1.4rem;
    font-weight: 500;
    color: $text-secondary;
  }
  
  .photo-selection__actions {
    display: flex;
    align-items: center;
  }
  
  .photo-header__actions {
    display: flex;
    gap: .8rem;
    align-items: center;
    position: sticky;
  }
`;
