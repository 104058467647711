import React, { useEffect, useState } from "react";
import { useCss, k } from "kremling";

import { OverlayPage } from "../../components/overlay-page/overlay-page";
import { getUserInfoQueryKey, useGetUserInfoQuery } from "../../queries";
import { useDebounceValue } from "../../hooks/use-debounce-value";
import {
  deleteUserProfilePicture,
  setUserAttributes,
  uploadUserProfilePicture,
} from "../../services/api";
import { useWillUnmount } from "../../hooks/use-will-unmount";
import { queryClient } from "../../query-client";
import { Saving } from "../../components/saving/saving";
import { ImageForm } from "../../components/image-form/image-form";
import { Input } from "../../common/input/input";

export function AccountProfile() {
  const scope = useCss(css);

  const userInfo = useGetUserInfoQuery();
  const [profileSaving, setProfileSaving] = useState(false);
  const [deletingProfileImg, setDeletingProfileImg] = useState(false);
  const [nameSaving, setNameSaving] = useState(false);
  const [name, setName] = useState("");
  const [nameUserInteraction, setNameUserInteraction] = useState(false);
  const debouncedName = useDebounceValue(name, 2000);

  useEffect(() => {
    if (userInfo?.isSuccess) {
      setName(userInfo?.data?.userData?.name || "");
    }
  }, [userInfo?.isSuccess]);

  useEffect(() => {
    if (!nameUserInteraction) return;
    updateName();
  }, [debouncedName]);

  const updateProfileImg = async (image: File) => {
    setProfileSaving(true);
    try {
      await uploadUserProfilePicture(image, (data) => {
        data.progress;
      });
    } catch (e) {
    } finally {
      setProfileSaving(false);
    }
  };

  useWillUnmount(() => {
    if (name !== debouncedName) {
      updateName();
    }
  });

  const updateName = async () => {
    if (!name) return;
    setNameSaving(true);
    await setUserAttributes(name);
    queryClient.setQueryData(getUserInfoQueryKey(), (prevVal: UserInfo) => {
      return {
        ...prevVal,
        userData: {
          ...prevVal.userData,
          name,
        },
      };
    });
    setNameSaving(false);
  };

  const handleRemoveProfileImage = async () => {
    setDeletingProfileImg(true);
    await deleteUserProfilePicture();
    setDeletingProfileImg(false);
  };

  return (
    <div {...scope} className="security">
      <OverlayPage title="Profile">
        <Saving
          saving={profileSaving || nameSaving || deletingProfileImg}
          loading={userInfo.isLoading}
        />
        <ImageForm
          label="Profile image"
          onRemove={handleRemoveProfileImage}
          imageUrl={userInfo?.data?.profilePicture}
          onChange={updateProfileImg}
          roundImg
        />
        <div className="mb-16">
          <Input
            label="Name"
            placeholder="Name"
            value={name}
            onBlur={() => {}}
            onChange={(nextName) => {
              setNameUserInteraction(true);
              setName(nextName);
            }}
            error={!name && !userInfo?.isLoading ? "Name is required" : false}
            disabled={userInfo?.isLoading}
          />
        </div>
        <div className="mb-16">
          <Input
            label="Email"
            placeholder="Email"
            readOnly
            value={
              userInfo.data?.userData.email ||
              userInfo.data?.userData.phoneNumber
            }
          />
        </div>
        <div className="mb-32 text-read">
          <label className="text-label mb-8">Agreements</label>
          <div>
            <a href="https://www.picme.com/terms-of-use.html" target="_blank">
              Terms of Use
            </a>
          </div>
          <div>
            <a href="http://www.picme.com/privacy-policy.html" target="_blank">
              Privacy Policy
            </a>
          </div>
        </div>
      </OverlayPage>
    </div>
  );
}

const css = k`
  .security {
    max-width: 55rem;
    margin: 0 auto 3.2rem auto;
  }

  .user-info-name {
    display: flex;
    align-items: center;
    gap: .8rem;
  }
`;
