import React from "react";

import { Button } from "../../../common/button/button";
import { deleteUpload } from "../../../services/api";
import { dialog } from "../../../common/dialog/dialog";
import { queryClient } from "../../../query-client";
import { collectionPhotosQueryKey } from "../../../queries";
import { SlideType } from "../photo-viewer-types";

type Props = {
  fullAccess: boolean;
  collectionId: string;
  slide: SlideType;
  setNextIncrement: (index: number, arrayLengthDiff?: number) => void;
};

export function SlideDelete(props: Props) {
  const { fullAccess, collectionId, slide, setNextIncrement } = props;

  const handleDeletePhoto = async () => {
    await dialog({
      title: "Move to trash",
      submitText: "Move",
      description: (
        <p>Are you sure you want to move this item to trash?</p>
      ),
      onSubmitCallback: async () => {
        try {
          await deleteUpload(collectionId, slide.id);
          setNextIncrement(-1, -1);
          await queryClient.setQueryData(
            collectionPhotosQueryKey(collectionId),
            (data: any) => ({
              pages: data.pages.map((next: any) => ({
                ...next,
                uploads: next.uploads.filter(
                  (p: any) => p.uploadId !== slide.id
                ),
              })),
              pageParams: data.pageParams,
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  return fullAccess ? (
    <Button
      intent="tertiary-transparent"
      iconOnly="trash"
      onClick={() => handleDeletePhoto()}
    />
  ) : null;
}
