import axios from "axios";
import { useInfiniteQuery, useQueries, useQuery } from "@tanstack/react-query";

import {
  getMyUserInfo,
  listUserCollections,
  getSharingInfo,
  listCollectionTrashedUploads,
  listCollectionUploads,
  getCollectionInfo,
  listReferrals,
  getUserProfileInfo,
  getUserStateData,
} from "./services/api";
import { useAppState } from "./app-state";
import { sortAlphaNumeric } from "./utils/url";
import { queryClient } from "./query-client";

// collectionListQuery ---------------------------------
export const collectionListQueryKey = () => {
  return ["collectionList"];
};

export const useCollectionListQuery = () => {
  const { signedIn } = useAppState(["signedIn"]);
  return useQuery({
    queryKey: collectionListQueryKey(),
    queryFn: () =>
      listUserCollections().then((collections) => {
        sortAlphaNumeric(
          collections,
          (item: CollectionListItem) => item.metadata.title
        );
        return collections;
      }),
    retry: 1,
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    enabled: signedIn,
    refetchOnWindowFocus: false,
  });
};

// collectionPhotosQuery ---------------------------------
export const collectionPhotosQueryKey = (
  collectionId: string,
  dependencies: any[] = []
) => {
  return ["collectionPhoto", collectionId, ...dependencies];
};

export const useCollectionPhotosQuery = (
  collectionId: string,
  imagesPerPage: number = 100,
  refetchInterval: number | false = false,
  dependencies?: any[]
) => {
  return useInfiniteQuery({
    queryKey: collectionPhotosQueryKey(collectionId, dependencies),
    queryFn: async ({ pageParam }) => {
      const continuation = pageParam === "_empty" ? "" : pageParam;
      const res = await listCollectionUploads(
        collectionId,
        continuation,
        imagesPerPage
      );
      return res;
    },
    initialPageParam: "_empty",
    getNextPageParam: (lastPage) => lastPage.continuation,
    enabled: !!collectionId,
    retry: 1,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval,
  });
};

// collectionTrashQuery ---------------------------------
export const collectionTrashQueryKey = (
  collectionId: string,
  dependencies: unknown[] = []
) => {
  return ["collectionTrash", collectionId, ...dependencies];
};

export const collectionTrashQuery = (
  continuationToken: string = null,
  collectionId: string,
  imagesPerPage: number = 100
) =>
  listCollectionTrashedUploads(collectionId, continuationToken, imagesPerPage);

export const useCollectionTrashQuery = (
  collectionId: string,
  imagesPerPage: number,
  refetchInterval: number | false = false,
  dependencies?: any[]
) => {
  return useInfiniteQuery({
    queryKey: collectionTrashQueryKey(collectionId, dependencies),
    queryFn: ({ pageParam }) => {
      const continuation = pageParam === "_empty" ? "" : pageParam;
      return collectionTrashQuery(continuation, collectionId, imagesPerPage);
    },
    getNextPageParam: (lastPage) => lastPage.continuation,
    retry: 1,
    initialPageParam: "_empty",
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval,
  });
};

// collectionDetailsQuery ---------------------------------
export const collectionDetailsQuery = (collectionId: string) => {
  return ["collectionDetails", collectionId];
};

export const useCollectionDetailsQuery = (collectionId: string) => {
  return useQuery({
    queryKey: collectionDetailsQuery(collectionId),
    queryFn: () =>
      listCollectionUploads(collectionId, null, 1).then((res) => {
        const { uploads, ...rest } = res;
        return rest as CollectionResource;
      }),
    enabled: !!collectionId,
    retry: 1,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
};

// collectionShareAuthQuery ---------------------------------
export const collectionShareAuthQuery = (collectionId: string) => {
  return ["collectionShareAuth", collectionId];
};

export const useCollectionShareAuthQuery = (
  collectionId: string,
  enabled: boolean
) => {
  return useQuery({
    queryKey: collectionShareAuthQuery(collectionId),
    queryFn: () => getSharingInfo(collectionId),
    retry: 1,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: enabled && !!collectionId,
  });
};

// getCollectionQuery ---------------------------------
export const getCollectionQuery = (collectionId: string, ...rest: any[]) => {
  return ["getCollection", collectionId, ...rest];
};

export const useGetCollectionQuery = (collectionId: string, ...rest: any[]) => {
  return useQuery({
    queryKey: getCollectionQuery(collectionId, ...rest),
    queryFn: () => getCollectionInfo(collectionId),
    enabled: !!collectionId,
    retry: 1,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const getCollectionSharingMetadataQuery = (collectionId: string) => {
  return ["getCollectionSharingMetadata", collectionId];
};

export const useGetCollectionSharingMetadataQuery = (
  collectionId: string,
  collectionSharingMetadataLocation: string
) => {
  return useQuery({
    queryKey: getCollectionSharingMetadataQuery(collectionId),
    queryFn: async () => {
      try {
        const { data } = await axios.get(collectionSharingMetadataLocation);
        return !!data?.sharingEnabled;
      } catch (e) {
        return false;
      }
    },
    retry: 1,
    enabled: !!(collectionId && collectionSharingMetadataLocation),
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
};

// userInfoQuery ---------------------------------
export const getUserInfoQueryKey = () => {
  return ["getUserInfo"];
};

export const useGetUserInfoQuery = (enabled: boolean = true) => {
  return useQuery({
    queryKey: getUserInfoQueryKey(),
    queryFn: () => getMyUserInfo(),
    enabled,
    retry: 1,
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

// listReferredUsersQuery ---------------------------------
export const listReferredUsersQueryKey = () => {
  return ["listReferredUsers"];
};

export const useListReferredUsersQuery = () => {
  const { signedIn } = useAppState(["signedIn"]);
  return useQuery({
    queryKey: listReferredUsersQueryKey(),
    queryFn: () =>
      listReferrals()
        .then((data) => {
          return data.referrals;
        })
        .catch(() => []),
    retry: 1,
    retryOnMount: false,
    enabled: signedIn,
    refetchOnWindowFocus: false,
  });
};

// getZipStatusQuery ---------------------------------
export const getZipStatusQueryKey = (collectionId: string, zipUrl: string) => {
  return ["getZipStatus", collectionId, zipUrl];
};

export const useGetZipStatusQuery = (
  collectionId?: string,
  zipUrl?: string
) => {
  return useQuery({
    queryKey: getZipStatusQueryKey(collectionId, zipUrl),
    queryFn: () =>
      axios.get<any, { data: Zip }>(zipUrl).then(({ data }) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      }),
    retry: 1,
    enabled: !!(collectionId && zipUrl),
    refetchInterval: 1000 * 5,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
};

// getSlideQuery ---------------------------------
export const getSlideQueryKey = (slideId: string) => {
  return ["slide", slideId];
};

export const useGetSlideQuery = (
  slideId: string,
  srcFn: () => Promise<{ url: string; downloadUrl: string }>
) => {
  return useQuery({
    queryKey: getSlideQueryKey(slideId),
    queryFn: srcFn,
    retry: 1,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: Infinity,
    enabled: !!slideId,
  });
};

// getCollectionInfoQuery ---------------------------------
export const getCollectionInfoQueryKey = (collectionId: string) => {
  return ["getCollectionInfoQuery", collectionId];
};

export const useGetCollectionInfoQuery = (collectionId: string) => {
  return useQuery({
    queryKey: getCollectionInfoQueryKey(collectionId),
    queryFn: () => getCollectionInfo(collectionId),
    enabled: !!collectionId,
    retry: 1,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
};

// getUserProfileInfoQuery ---------------------------------
export const getUserProfileInfoQueryKey = () => {
  return ["getUserProfileInfoQuery"];
};

export const useGetUserProfileInfoQuery = () => {
  return useQuery({
    queryKey: getUserProfileInfoQueryKey(),
    queryFn: async () => {
      const userInfo = (await queryClient.getQueryData(
        getUserInfoQueryKey()
      )) as UserInfo;
      return getUserProfileInfo(userInfo?.userData.userId);
    },
    retry: 1,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
};

// getUserState
export const getUserStateKey = (signedIn: boolean) => {
  return ["getUserState", signedIn];
};

export const useGetUserState = () => {
  const { signedIn } = useAppState(["signedIn"]);
  return useQuery({
    queryKey: getUserStateKey(signedIn),
    queryFn: () => getUserStateData(),
    enabled: signedIn,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
};

// getEventHistory
export const getEventHistoryKey = (...collectionIds: string[]) => {
  return ["getEventHistory", ...collectionIds];
};

// todo:
// need to think about how to cache each "GetCollectionInfo" call individually instead of grouped...
// it'll always cause a UI Flash when something changes otherwise

const collectionCache: Record<
  string,
  GetCollectionInfoResponse & CollectionMetadata
> = {};

export const useGetEventHistory = () => {
  const { data: userState } = useGetUserState();
  return useQuery({
    queryKey: getEventHistoryKey(
      ...(userState?.uploadHistory?.map((h) => h.collectionId) || [])
    ),
    queryFn: () =>
      Promise.all(
        (userState?.uploadHistory || []).map(({ collectionId }) => {
          if (collectionCache[collectionId]) {
            return Promise.resolve(collectionCache[collectionId]);
          } else {
            return getCollectionInfo(collectionId).then((res) => {
              collectionCache[collectionId] = res;
              return res;
            });
          }
        })
      ).then((res) => res.filter(Boolean)),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
};

export const getEventHistoriesKey = (collectionId: string) => {
  return ["eventHistories", collectionId];
};

export const useGetEventHistories = () => {
  const { data: userState } = useGetUserState();
  const eventHistories = userState?.uploadHistory || [];
  return useQueries({
    queries: eventHistories.map((eh) => ({
      queryKey: getEventHistoriesKey(eh.collectionId),
      queryFn: () => getCollectionInfo(eh.collectionId),
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    })),
    combine: (result) => {
      const filteredResults = result.filter((r) => r.data);
      return {
        data: filteredResults.map((r) => r.data),
        isLoading: filteredResults.some((r) => r.isLoading),
        isSuccess: filteredResults.every((r) => r.isSuccess),
      };
    },
  });
};
