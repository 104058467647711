import React, { ReactNode, CSSProperties } from "react";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { InputField } from "../../common/input/input-field";
import { Button } from "../../common/button/button";
import { lsDeviceIdKey, lsOwnershipTokenKey } from "../auth/auth-utils";
import { useForm } from "react-hook-form";
import { updateDevice } from "../../services/api";
import { modalService } from "../../common/modal-service/modal-service";
import { ModalHeader } from "../../common/modal/modal-header";
import { ModalBody } from "../../common/modal/modal-body";
import { ModalFooter } from "../../common/modal/modal-footer";

type Props = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  modalResolve?: (userName: string) => void;
};

export function AskNameModal(props: Props) {
  const { modalResolve } = props;
  useCssModules(css);

  const { control, handleSubmit } = useForm({
    defaultValues: { userName: "" },
  });

  const onNameSubmit = async ({ userName }: { userName: string }) => {
    modalService.disableModalExit(true);
    const ownershipToken = localStorage.getItem(lsOwnershipTokenKey);
    const deviceId = localStorage.getItem(lsDeviceIdKey);
    updateDevice(deviceId, ownershipToken, userName)
      .then(() => {
        modalResolve(userName);
      })
      .catch(() => {});
  };

  return (
    <form
      className="as-modal"
      onSubmit={handleSubmit(onNameSubmit)}
      style={{ width: "100%" }}
    >
      <ModalHeader showCloseBtn={false}>What's your name?</ModalHeader>
      <ModalBody>
        <div className="text-secondary text-center mb-24">
          This helps the organizer know
          <br />
          who sent the photos
        </div>
        <InputField
          control={control}
          fieldName="userName"
          placeholder="Name"
          required
          className="mb-16"
        />

        <div className="button-group mb-32">
          <Button
            block
            intent="secondary-grey"
            onClick={() => {
              modalResolve(null);
            }}
          >
            Not now
          </Button>
          <Button block intent="primary-disco" type="submit">
            Submit
          </Button>
        </div>
        <div className={s.orLogin}>
          <div>or</div>
          <div>
            <Button intent="secondary">Login</Button>
          </div>
        </div>
      </ModalBody>
    </form>
  );
}

const { css, s } = cssModules`
  .orLogin {
    display: flex;
    gap: 1.2rem;
    align-items: center;
    justify-content: center;
  }
`;
