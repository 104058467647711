import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCss, k } from "kremling";
import "yet-another-react-lightbox/styles.css";
import { useQueryClient } from "@tanstack/react-query";

import { Button } from "../../../common/button/button";
import {
  collectionPhotosQueryKey,
  useCollectionPhotosQuery,
  useGetUserInfoQuery,
} from "../../../queries";
import {
  clearCollectionPhotos,
  deleteUpload,
  disconnectSharedCollection,
  getOriginalUploadUrls,
  getOrStartFullZipOfOriginals,
  getOrStartPartialZipOfOriginals,
  getUserProfileInfo,
} from "../../../services/api";
import { CollectionGrid } from "../../../components/collection-grid";
import { Menu, MenuDivider, MenuItem } from "../../../common/menu";
import { dialog } from "../../../common/dialog/dialog";
import toast from "react-hot-toast";
import { isVideo, isVideoMimetype, niceToastError } from "../../../utils/utils";
import { appState } from "../../../app-state";
import { isEmpty } from "lodash";
import { from } from "rxjs";
import { mergeMap, map } from "rxjs/operators";
import { removeCollectionFromList } from "../../../utils/url";
import { PhotoViewer } from "../../../components/photo-viewer/photo-viewer";
import { FilterModal } from "./filter-modal";
import { AppHeader } from "../../../components/app-header/app-header";
import { useMediaQuery } from "../../../hooks/use-media-query";
import { Logo } from "../../../components/logo";
import { PlusMenu } from "../plus-menu";
import { uploaderService } from "../../../utils/uploader-service";
import { ulid } from "ulid";
import { useCollection } from "../collection";
import { MenuItemLink } from "../../../common/menu/menu-item-link";

const imagesPerPage = 100;

const getUploaderFilter = (params) => {
  let uploaderFilter = params.get("uploaders");
  const decoded = !!uploaderFilter ? decodeURIComponent(uploaderFilter) : "";

  if (decoded) {
    return decoded.split("-");
  }
  return [];
};

function extractFilterTitle(userNameMap, existingFilters) {
  const user = userNameMap[existingFilters.uploaders[0]];
  if (!user || !user.name) return "Unknown User";

  const nameParts = user.name.split(" ");
  const firstName = nameParts[0] || "";
  const lastNameInitial = (nameParts[1] && nameParts[1][0]) || "";

  return `${firstName} ${lastNameInitial} ${
    existingFilters.uploaders.length > 1
      ? `+${existingFilters.uploaders.length - 1}`
      : ""
  }`;
}

export const CollectionPhotosList = () => {
  const scope = useCss(css);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const { collectionId } = useParams();
  const { data: loggedInUser } = useGetUserInfoQuery();
  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const { collection } = useCollection();
  const queryClient = useQueryClient();
  const [endOfList, setEndOfList] = useState(false);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [pauseRefetchInterval, setPauseRefetchInterval] = useState(false);
  const uploadInputRef = useRef();
  const navigate = useNavigate();
  const [photoViewer, setPhotoViewer] = useState(false);
  const [gridSize, setGridSize] = useState(appState.get("gridSize"));
  const [searchParams, setSearchParams] = useSearchParams();
  const [userNameMap, setUserNameMap] = useState({});
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const existingFilters = useMemo(() => {
    const uploaderFilter = getUploaderFilter(searchParams);
    return {
      uploaders: uploaderFilter,
    };
  }, [searchParams]);

  const {
    data,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
    isSuccess,
    isFetchingNextPage,
    isError,
  } = useCollectionPhotosQuery(
    collectionId,
    imagesPerPage,
    endOfList && !pauseRefetchInterval ? 1000 * 20 : null
  );

  // function invalidatePages() {
  //   queryClient.setQueryData(
  //     collectionPhotosQueryKey(collectionId),
  //     (existingData) =>
  //       existingData
  //         ? {
  //             pageParams: [existingData.pageParams[0]],
  //             pages: [existingData.pages[0]],
  //           }
  //         : undefined
  //   );
  // }

  useEffect(() => {
    if (isError) {
      navigate("/collection");
    }
  }, [isError]);

  useEffect(() => {
    //Fetch all pages on load for filtering
    if (isSuccess && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isSuccess, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (!data) return;
    let containsAnonymousUsers = false;

    const uploaderIds = data.pages.reduce((accum, page) => {
      if (!containsAnonymousUsers)
        page.uploads.some(({ uploaderUserId }) => {
          if (uploaderUserId === null) {
            containsAnonymousUsers = true;
            return true;
          }
          return false;
        });
      return [
        ...accum,
        ...page.uploads
          .map(({ uploaderUserId }) => uploaderUserId)
          .filter(Boolean),
      ];
    }, []);
    const uniqueUploaderIds = [...new Set(uploaderIds)];

    if (containsAnonymousUsers) {
      //Group all anonymous users together
      setUserNameMap((prevState) => ({
        ...prevState,
        ["anonymous"]: {
          name: "Anonymous",
          userId: "anonymous",
        },
      }));
    }

    //If there are no new unique IDs then don't go through with request
    const newUniqueUploaderIds = uniqueUploaderIds.filter(
      (id) => !(id in userNameMap)
    );
    if (newUniqueUploaderIds.length === 0) return;

    setLoadingFilters(true);

    from(newUniqueUploaderIds)
      .pipe(
        mergeMap((uploaderId) =>
          from(getUserProfileInfo(uploaderId, collectionId)).pipe(
            //The response does not contain the user's ID, so we need to match it up here
            map((res) => ({
              ...res,
              userId: uploaderId,
            }))
          )
        )
      )
      .subscribe({
        next: (res) => {
          setUserNameMap((userNameMap) => ({
            ...userNameMap,
            [res.userId]: res,
          }));
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => setLoadingFilters(false),
      });
  }, [data]);

  useEffect(() => {
    setPhotoViewer(false);
    setUserNameMap({});
  }, [collectionId]);

  useEffect(() => {
    setEndOfList(!hasNextPage);
  }, [hasNextPage]);

  const filteredData = useMemo(() => {
    if (!data) return data;
    const uploaderFilterArray = getUploaderFilter(searchParams);
    if (isEmpty(uploaderFilterArray)) return data;
    return {
      ...data,
      pages: data.pages.reduce((accum, page) => {
        const uploads = page.uploads.filter(({ uploaderUserId }) => {
          if (uploaderUserId === null)
            return uploaderFilterArray.includes("anonymous");
          return uploaderFilterArray.includes(String(uploaderUserId));
        });
        return [...accum, { ...page, uploads }];
      }, []),
    };
  }, [searchParams, data]);

  // useEffect(() => {
  //   document.body.addEventListener("scroll", handleScroll);
  //   document.body.addEventListener("touchmove", handleScroll);
  //   return () => {
  //     document.body.removeEventListener("scroll", handleScroll);
  //     document.body.removeEventListener("touchmove", handleScroll);
  //   };
  // }, [hasNextPage, fetchNextPage]);

  // const handleScroll = (e) => {
  //   const b = e.target;
  //   if (b.scrollTop + window.innerHeight >= b.scrollHeight - 1) {
  //     if (hasNextPage) {
  //       fetchNextPage();
  //     }
  //   }
  // };

  const slides = useMemo(() => {
    return (
      filteredData?.pages.reduce((acc, p) => {
        return [
          ...acc,
          ...p.uploads.map((photo) => {
            /**
             * @type {SlideType}
             */
            return {
              id: photo.uploadId,
              uid: photo.uploadId,
              type: !isVideoMimetype(photo.mimeType) ? "image" : "video",
              thumbnailSrc: photo.thumbnailUrl,
              src: () =>
                getOriginalUploadUrls(collectionId, photo.uploadId).then(
                  (res) => ({
                    url: res.originalUrl,
                    downloadUrl: res.originalDownloadUrl,
                  })
                ),
            };
          }),
        ];
      }, []) || []
    );
  }, [filteredData]);

  const photos = useMemo(() => {
    return (
      filteredData?.pages.reduce((acc, p, i) => {
        return [
          ...acc,
          ...p.uploads.map((photo, j) => {
            if (isVideo(photo.uploadId)) {
              return {
                ...photo,
                src: photo.originalUrl,
                index: i * imagesPerPage + j,
                type: "video",
                poster: photo.thumbnailUrl,
                sources: [
                  {
                    src: photo.originalUrl,
                  },
                ],
              };
            } else {
              return {
                ...photo,
                src: photo.originalUrl,
                index: i * imagesPerPage + j,
              };
            }
          }),
        ];
      }, []) || []
    );
  }, [filteredData]);

  const clearSelectedPhotos = () => {
    const concurrentRequestsLimit = 10;

    setSelectedFileIds([]);
    queryClient.setQueryData(
      collectionPhotosQueryKey(collectionId),
      (data) => ({
        pages: data.pages.map((next) => ({
          ...next,
          uploads: next.uploads.filter(
            (p) => !selectedFileIds.includes(p.uploadId)
          ),
        })),
        pageParams: data.pageParams,
      })
    );

    from(selectedFileIds)
      .pipe(
        mergeMap(
          (photoId) => from(deleteUpload(collectionId, photoId)),
          concurrentRequestsLimit
        )
      )
      .subscribe({
        complete: () => toast.success(`Successfully moved to trash`),
        error: () => toast.error(`Failed to move to trash`),
      });
  };

  const handleDeleteSelected = async () => {
    setPauseRefetchInterval(true);
    await dialog({
      title: "Move selected to trash",
      description: (
        <p>Are you sure you want to move all selected items to trash?</p>
      ),
      submitText: `Move ${selectedFileIds.length} item${
        selectedFileIds.length > 1 ? "s" : ""
      }`,
      onSubmitCallback: async () => {
        await clearSelectedPhotos();
      },
    });
    setPauseRefetchInterval(false);
  };

  const handleDeleteAll = async () => {
    setPauseRefetchInterval(true);
    await dialog({
      title: "Move all items to trash",
      description: (
        <p>
          Are you sure you want to move all items within this collection to
          trash?
        </p>
      ),
      submitText: "Move",
      onSubmitCallback: async () => {
        try {
          await clearCollectionPhotos(collectionId);
        } catch (e) {
          niceToastError(null, e);
        }
        await refetch();
      },
    });
    setPauseRefetchInterval(false);
  };

  const handleUploadPhoto = (e) => {
    const files = [...e.target.files];
    e.target.value = "";
    const filteredFiles = files.filter(({ name }) =>
      name.match(
        /\.(jpg|jpeg|png|gif|bmp|tiff|webp|heif|heic|mov|mp4|m4a|webm)$/i
      )
    );
    if (!files.length) return;
    if (files.length !== filteredFiles.length) {
      toast("Only images and videos are allowed to be uploaded");
    }
    files.reverse();
    uploaderService.addFiles(
      files.map((file) => ({
        id: ulid(),
        collectionId,
        file,
        upload: {
          progress: 0,
          error: null,
          status: "queue",
        },
      })),
      collectionId
    );
  };

  const handleDisconnectSharedCollection = async () => {
    await dialog({
      title: "Disconnect Collection",
      submitText: "Disconnect",
      description: (
        <>
          <p>Are you sure you want to disconnect this shared collection?</p>
          <p className="text-error">This action cannot be undone.</p>
        </>
      ),
      onSubmitCallback: async () => {
        try {
          await disconnectSharedCollection(collectionId);
          toast.success("Successfully disconnected shared collection");
          await removeCollectionFromList(collectionId);
          navigate("/collection");
        } catch (e) {
          niceToastError("Unable to disconnect shared collection", e);
        }
      },
    });
  };

  const toggleGridSize = () => {
    setGridSize((gridSize) => {
      const newGridSize = gridSize === "sm" ? "lg" : "sm";

      // Update app state
      appState.updateGridSize(newGridSize);

      return newGridSize;
    });
  };

  const handleExportAllAsZip = async () => {
    appState.updateZip(collectionId, null, null);
    const { zipStatusUrl } = await getOrStartFullZipOfOriginals(collectionId);
    const thumbnails = photos.slice(0, 2).map((photo) => photo.thumbnailUrl);
    appState.updateZip(collectionId, zipStatusUrl, thumbnails);
  };

  const handleExportAsZip = async () => {
    appState.updateZip(collectionId, null, null);
    const { zipStatusUrl } = await getOrStartPartialZipOfOriginals(
      collectionId,
      selectedFileIds
    );

    const thumbnails = photos
      .filter((photo) => selectedFileIds.indexOf(photo.id) !== -1)
      .slice(0, 2)
      .map((photo) => photo.thumbnailUrl);
    appState.updateZip(collectionId, zipStatusUrl, thumbnails);
  };

  const openPhotoViewer = (index) => {
    setLightboxIndex(index);
    setPhotoViewer(true);
  };

  const showFilterDialog = async () => {
    setShowFilters(true);
  };

  const reconcileFiltersWithParams = (filters) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    for (let [key] of newSearchParams) {
      if (!(key in filters)) {
        newSearchParams.delete(key);
      }
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        newSearchParams.set(key, value.join("-"));
      } else {
        newSearchParams.set(key, value);
      }
    });

    setSearchParams(newSearchParams);
  };

  const clearFilters = () => {
    setSearchParams(new URLSearchParams());
  };

  const hasPhotos = !!photos.length;
  const fullAccess = collection?.access === "Full";
  const shared = collection?.access === "Read";

  let canUpload = true;

  if (isLoading) {
    return (
      <div {...scope}>
        <AppHeader shared={shared} collection={collection} />
        <div className="wrapper">
          <Button intent="secondary" isLoading={isFetching || isLoading}>
            Load more
          </Button>
        </div>
      </div>
    );
  }

  if (
    !fullAccess &&
    (collection?.pause === true || collection.metadata?.pauseShareeUpload)
  ) {
    canUpload = false;
  }

  const filterIncludesLoggedInUser = existingFilters.uploaders.includes(
    loggedInUser?.userData.userId
  );

  return (
    <div {...scope} className="collection-photos-list">
      <AppHeader
        collection={collection}
        shared={shared}
        renderActionBar={() => (
          <>
            {!isSelectionMode ? (
              <div className="photo-header">
                <div className="photo-header__interations">
                  {window.showExtra && (
                    <>
                      {existingFilters.uploaders?.length > 0 &&
                      userNameMap[existingFilters.uploaders[0]] ? (
                        <div className="filters-btn">
                          <button onClick={showFilterDialog}>
                            {existingFilters.uploaders?.length} Filter
                            {existingFilters.uploaders?.length > 1 && "s"}
                          </button>
                          <Button
                            size="sm"
                            onClick={clearFilters}
                            intent="secondary"
                            iconOnly="xmark-regular"
                            style={{ marginRight: 2 }}
                          />
                        </div>
                      ) : (
                        <Button
                          onClick={showFilterDialog}
                          intent="secondary-grey"
                          iconOnly={!isDesktop ? "filter-regular" : null}
                          iconLeft={isDesktop ? "filter-regular" : null}
                          disabled={isEmpty(photos)}
                        >
                          {isDesktop ? "Filter" : null}
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    intent="secondary-grey"
                    iconOnly={!isDesktop ? "circle-check-regular" : null}
                    iconLeft={isDesktop ? "circle-check-regular" : null}
                    onClick={() =>
                      setIsSelectionMode((prevState) => !prevState)
                    }
                    disabled={isEmpty(photos)}
                  >
                    {isDesktop ? "Select" : null}
                  </Button>
                  {!window.showExtra && (
                    <Button
                      className="ml-8"
                      intent="secondary-grey"
                      iconOnly={!isDesktop ? "qrcode-regular" : null}
                      iconLeft={isDesktop ? "qrcode-regular" : null}
                      as={Link}
                      to={`/collection/${collectionId}/request`}
                    >
                      {isDesktop ? "Request photos" : null}
                    </Button>
                  )}
                  <Button
                    className="ml-8"
                    intent="secondary-grey"
                    iconOnly={!isDesktop ? "circle-arrow-up-regular" : null}
                    iconLeft={isDesktop ? "circle-arrow-up-regular" : null}
                    onClick={() => uploadInputRef?.current?.click()}
                  >
                    Upload photos
                  </Button>
                  {/*{fullAccess && (*/}
                  {/*  <Button*/}
                  {/*    className="ml-8"*/}
                  {/*    intent="secondary-grey"*/}
                  {/*    iconOnly={!isDesktop ? "qrcode-regular" : null}*/}
                  {/*    iconLeft={isDesktop ? "qrcode-regular" : null}*/}
                  {/*    onClick={() =>*/}
                  {/*      navigate(`/collection/${collection.id}/request`)*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    {isDesktop ? "Request" : null}*/}
                  {/*  </Button>*/}
                  {/*)}*/}
                  {fullAccess && (
                    <Button
                      className="ml-8"
                      intent="secondary-grey"
                      iconOnly={!isDesktop ? "people" : null}
                      iconLeft={isDesktop ? "people" : null}
                      onClick={() =>
                        navigate(`/collection/${collection.id}/share`)
                      }
                    >
                      {isDesktop ? "Share" : null}
                    </Button>
                  )}
                </div>
                <div className="photo-header__actions">
                  <span className="photo-unlock-big">
                    <Button intent="primary">Unlock photos</Button>
                  </span>
                  {/*{canUpload && (*/}
                  {/*  <Button*/}
                  {/*    iconLeft="circle-arrow-up-regular"*/}
                  {/*    onClick={() => {*/}
                  {/*      uploadInputRef?.current?.click();*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    Upload*/}
                  {/*  </Button>*/}
                  {/*)}*/}
                  {/*<Button*/}
                  {/*  iconOnly="arrows-rotate-regular"*/}
                  {/*  onClick={() => {*/}
                  {/*    toast("Refreshing photos...");*/}
                  {/*    refetch();*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <Menu
                    placementSide="end"
                    fancy
                    renderTrigger={(refProps, controlProps) => (
                      <Button
                        {...refProps}
                        intent="secondary-grey"
                        active={controlProps.open}
                        iconOnly="ellipsis-regular"
                        iconOnlyProps={{ size: 22 }}
                      />
                    )}
                  >
                    {fullAccess && (
                      <MenuItem
                        label="Collection settings"
                        iconLeft="gear-regular"
                        onClick={() =>
                          navigate(`/collection/${collectionId}/settings`)
                        }
                      />
                    )}

                    <MenuItemLink
                      href={`/up/${collectionId}`}
                      label="View Uploader Page"
                      iconLeft="eye-solid"
                      target="_blank"
                      rel="noopener"
                    />

                    {fullAccess && <MenuDivider />}

                    {!!hasPhotos && (
                      <MenuItem
                        label="Export all as zip"
                        iconLeft="arrow-down-to-line-regular"
                        onClick={handleExportAllAsZip}
                      />
                    )}

                    <MenuItem
                      iconLeft={
                        gridSize === "sm"
                          ? "arrow-up-right-and-arrow-down-left-from-center-regular"
                          : "arrow-down-left-and-arrow-up-right-to-center-regular"
                      }
                      label={`${
                        gridSize === "sm" ? "Large" : "Small"
                      } grid size`}
                      onClick={toggleGridSize}
                    />
                    <MenuItem
                      label="Refresh"
                      iconLeft="arrows-rotate-regular"
                      onClick={() => {
                        toast("Refreshing photos...");
                        refetch();
                      }}
                    />

                    {!fullAccess && <MenuDivider />}

                    {!fullAccess && (
                      <MenuItem
                        label="Disconnect Collection"
                        onClick={handleDisconnectSharedCollection}
                        iconLeft="circle-xmark-solid"
                      />
                    )}

                    {fullAccess && <MenuDivider />}
                    {fullAccess && (
                      <MenuItem
                        label="Move all to trash"
                        iconLeft="trash-remove"
                        onClick={handleDeleteAll}
                      />
                    )}
                    {fullAccess && (
                      <MenuItem
                        label="View trash"
                        iconLeft="trash"
                        onClick={() => {
                          navigate("trash");
                        }}
                      />
                    )}
                  </Menu>
                </div>
              </div>
            ) : (
              <div className="photo-header">
                <div>
                  <div className="photo-selection__actions">
                    <Button
                      className="mr-8"
                      onClick={() => {
                        setIsSelectionMode(false);
                        setSelectedFileIds([]);
                      }}
                      intent="secondary-grey"
                      iconOnly="xmark-regular"
                      iconOnlyProps={{ size: 28 }}
                    />
                    {selectedFileIds.length}/20
                  </div>
                </div>
                {selectedFileIds.length > 0 && (
                  <div className="photo-header__actions">
                    <Button
                      onClick={handleExportAsZip}
                      iconOnly="arrow-down-to-line-regular"
                      iconOnlyProps={{ size: 20 }}
                    />
                    {(fullAccess || filterIncludesLoggedInUser) && (
                      <Button
                        onClick={handleDeleteSelected}
                        iconOnly="trash"
                        iconOnlyProps={{ size: 20 }}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      />

      {!hasPhotos && (
        <div className="wrapper">
          <div className="text-secondary mb-16">No photos uploaded</div>
          {fullAccess && (
            <>
              <Button
                as={Link}
                alignText="left"
                to={`/collection/${collectionId}/request`}
                intent="secondary-grey"
                block
                className="mb-8"
                iconLeft="qrcode-regular"
              >
                Request photos
              </Button>
            </>
          )}
          {canUpload && (
            <Button
              block
              alignText="left"
              intent="secondary-grey"
              iconLeft="circle-arrow-up-regular"
              onClick={() => {
                uploadInputRef?.current?.click();
              }}
            >
              Upload photos
            </Button>
          )}
        </div>
      )}

      <div className="photos-container">
        <CollectionGrid
          files={photos}
          isSelectionMode={isSelectionMode}
          setLightboxIndex={openPhotoViewer}
          selectedFileIds={selectedFileIds}
          setSelectedFileIds={setSelectedFileIds}
          gridSize={gridSize}
        />
        {hasNextPage && (
          <div className="text-center pv-32">
            <Button
              intent="secondary"
              isLoading={isFetching || isLoading}
              onClick={() => {
                fetchNextPage();
              }}
            >
              Load more
            </Button>
          </div>
        )}
        <div
          style={{ width: "100%", textAlign: "center" }}
          className="mt-28 mb-28"
        >
          <Logo bw style={{ opacity: 0.2 }} size={40} />
        </div>
      </div>
      {photoViewer && (
        <PhotoViewer
          onClose={() => setPhotoViewer(false)}
          slides={slides}
          initialIndex={lightboxIndex}
          fullAccess={fullAccess}
          collectionId={collectionId}
          actions={["delete", "download", "share"]}
        />
      )}

      <FilterModal
        show={showFilters}
        collection={collection}
        existingFilters={existingFilters}
        userNameMap={userNameMap}
        loadingFilters={loadingFilters}
        modalResolve={(_filters) => {
          if (_filters) {
            reconcileFiltersWithParams(_filters);
          }
          setShowFilters(false);
        }}
        onClose={() => setShowFilters(false)}
        overlayOnMobile
      />

      {/*<div style={{ position: "relative" }}>*/}
      {/*  <PlusMenu*/}
      {/*    collectionId={collectionId}*/}
      {/*    onUpload={() => uploadInputRef?.current?.click()}*/}
      {/*  />*/}
      {/*</div>*/}
      <input
        type="file"
        className="upload-input"
        ref={uploadInputRef}
        onChange={handleUploadPhoto}
        multiple
        accept="image/*,video/*"
      />
    </div>
  );
};

const css = k`
  .collection-photos-list {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .wrapper {
    display: flex;
    height: calc(100vh - 22.5rem);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 25rem;
    text-align: center;
    margin: 0 auto;
    padding: 0 1.6rem;
  }
  
  .photo-header {
    padding: 1.2rem 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6.9rem;
  }
  
  .photos-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include breakpoint(sm) {
      padding: 0 1.6rem;
    }
  }
  
  .photo-header__interations {
    display: flex;
  }

  .photo-selection__actions {
    display: flex;
    align-items: center;
  }

  .upload-input {
    display: none;
  }

  .photo-header__actions {
    display: flex;
    gap: .8rem;
    align-items: center;
    position: sticky;
  }

  .photo-unlock-big {
    display: none;
  }

  @include breakpoint(md) {
    .photo-unlock-big {
      display: none;
    }
  }
  
  .filters-btn {
    border: solid .1rem var(--button-secondary-color);
    display: flex;
    align-items: center;
    background: var(--button-secondary-bg);
    transition: background 100ms ease;
    border-radius: 100vh;
    
    &:hover {
      background: var(--button-secondary-hover-bg);
    } 
  
    & > button {
      background: none;
      border: none;
      padding: 0;
      padding-left: 1.6rem;
      padding-right: .4rem;
      font-size: 1.4rem;
      font-weight: 700;
      color: var(--button-secondary-color);
      cursor: pointer;
      height: 3.8rem;
      display: flex;
      align-items: center;
      border-top-left-radius: 100vh;
      border-bottom-left-radius: 100vh;
    }
    
    & > button:focus {
      outline: none;
      background: var(--button-secondary-hover-bg);
    }
  }
  
  .photos {
    display: grid;
    column-gap: .4rem;
    row-gap: .4rem;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));

    > * {
      width: 15rem;
      height: 15rem;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;
