import axios, { AxiosInstance, AxiosProgressEvent } from "axios";

import { appState } from "../app-state";
import { apiUrlMap } from "./apiUrlMap";
import { urlCat } from "../utils/url";

const env = IS_LOCAL ? "local" : IS_PRODUCTION ? "production" : "development";
console.log(`Running in ${env} environment.`);
const envMap = apiUrlMap[env] ? apiUrlMap[env] : apiUrlMap["development"];

export const api = axios.create({
  withCredentials: false,
});

api.interceptors.request.use(async (config) => {
  const accessToken = appState.get("accessToken");
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  } else if (location.pathname.startsWith("/up/")) {
  } else {
    appState.logOut();
    if (location.pathname !== "/login-or-signup") {
      location.href = "/login-or-signup";
    }
    throw Error("no access token");
  }
  return config;
});

export const noAuthApi = axios.create({
  withCredentials: false,
});

let refreshPromise: Promise<any>;
let tosPromise: Promise<any>;
// returns boolean
export const refreshAccessToken = async () => {
  try {
    if (!refreshPromise) {
      refreshPromise = refreshUserAuthentication(appState.get("refreshToken"));
    }
    const response = await refreshPromise;
    const accessToken =
      response?.successfulAuthentication?.authenticated?.accessToken;
    refreshPromise = null;
    if (accessToken) {
      appState.set({ accessToken, signedIn: true });
      return true;
    }
    appState.logOut();
    return false;
  } catch (e) {
    if (e?.response?.status !== 401) {
      appState.set({ outage: true });
    } else {
      appState.logOut();
      location.href = "/";
    }
    return false;
  }
};

const setTos = async () => {
  try {
    if (!tosPromise) {
      tosPromise = setUserAttributes(null, true);
    }
    await tosPromise;
    tosPromise = null;
  } catch (e) {}
};

const errCallback = (axiosInstance: AxiosInstance) => async (err: any) => {
  const code = err?.response?.data?.code;
  const originalRequest = err.config;
  const refreshToken = appState.get("refreshToken");

  // set terms of service in for older accounts that don't have it
  if (refreshToken && code === "TosNotRead" && !originalRequest.retry) {
    originalRequest.retry = true;
    await setTos();
    return axiosInstance(originalRequest);
  }

  // retry
  if (
    refreshToken &&
    (code === "NoAuthenticationToken" ||
      code === "NotAuthorizedException" ||
      code === "NotAuthorized" ||
      code === "Unauthorized" ||
      code === "Forbidden" ||
      code === "TokenExpired" ||
      code === "Tampering" ||
      code === "ExpiredAuthorization") &&
    !originalRequest.retry
  ) {
    originalRequest.retry = true;
    const hasAccessToken = await refreshAccessToken();
    if (hasAccessToken) {
      originalRequest.headers["Authorization"] = `Bearer ${appState.get(
        "accessToken"
      )}`;
      return axiosInstance(originalRequest);
    }
  }
  return Promise.reject(err);
};

api.interceptors.response.use((response) => response, errCallback(api));
noAuthApi.interceptors.response.use(
  (response) => response,
  errCallback(noAuthApi)
);

type CheckUserAuthenticationResponse = { code: string; user: UserData };
/**
 * Checks to see if a user is currently authenticated.
 */
export const checkUserAuthentication =
  async (): Promise<CheckUserAuthenticationResponse> => {
    const url = envMap.authenticationHandler;
    const { data }: { data: CheckUserAuthenticationResponse } = await api
      .post(`${url}session`)
      .then(({ data }) => {
        return data;
      })
      .catch(function (error) {
        if (error.response && error.response.status === 403) return false;
        throw error;
      });
    return data;
  };

type CheckUsernameResponse = {
  code: "UserExists" | "UserNotConfirmed" | "TosNotRead" | "Conflict" | "NotFound";
  status: "UNCONFIRMED" | "CONFIRMED" | "FORCE_CHANGE_PASSWORD"; // UNCONFIRMED indicates that the account has been created but continueSignUpUser has not been called yet.  CONFIRMED indicates that continueSignUpUser has been called.
  verified: boolean; // whether the email or phone number has been confirmed.  this happens automatically in continueSignUpUser, but can get reset in certain situations.
};
/**
 * Checks to see if there is an existing user account with the specified email address or phone number.
 * @param {string} emailOrPhoneNumber - The email address or phone number to search for.
 */
export const checkUsername = async (
  emailOrPhoneNumber: string
): Promise<CheckUsernameResponse> => {
  const url = envMap.authenticationHandler;
  const { data }: { data: CheckUsernameResponse } = await axios.get(
    `${url}user/username/${encodeURIComponent(emailOrPhoneNumber)}`
  );
  const userExists = !(data.code == "NotFound") && !!data?.verified;
  if (!userExists) {
    await resendAccountVerification(emailOrPhoneNumber);
  }
  return data;
};

/**
 * Resends the account verification code to the account using the specified email address or phone number.
 * @param {string} emailOrPhoneNumber - The email address or phone number to which account verification information will be sent.
 */
export const resendAccountVerification = async (emailOrPhoneNumber: string) => {
  const url = envMap.authenticationHandler;
  await axios.post(
    `${url}user/username/${encodeURIComponent(
      emailOrPhoneNumber
    )}/verification?`
  );
  return true;
};

type AuthenticationComplete = {
  authenticated: UserAuthenticated;
  authenticatedUser: UserData;
};
type MoreAuthenticationRequired = { newPasswordRequired?: CollectNewPassword }; // we'll eventually add more types here, each with a corresponding "ContinueWith" function
type UserAuthenticated = {
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  expiresInSeconds: number;
};

type StartAuthenticationResponse = {
  successfulAuthentication?: AuthenticationComplete;
  moreAuthenticationRequired?: MoreAuthenticationRequired;
};
/**
 * Starts authentication for an email/password user.
 * @param {string} emailOrPhoneNumber - The email or phone number associated with the account.
 * @param {string} password - The password associated with the account.
 */
/*
 * returns 400 UserNotConfirmed if the account has not been confirmed yet
 * returns 200 OK with indication in the UserData if the terms of service haven't been marked as read yet.  Subsequent calls will fail if the terms of service isn't marked as read as the next step in the process.
 */
export const startAuthentication = async (
  emailOrPhoneNumber: string,
  password: string
): Promise<StartAuthenticationResponse> => {
  const url = envMap.authenticationHandler;
  const { data }: { data: StartAuthenticationResponse } = await noAuthApi.post(
    `${url}session/username/${encodeURIComponent(
      emailOrPhoneNumber
    )}?password=${encodeURIComponent(password)}`
  );
  return data;
};

/**
 * Changes the authenticated user's account password.
 * @param {string} oldPassword - The account's old password.
 * @param {string} newPassword - The new password ot use for the account.
 */
export const changeUserPassword = async (
  oldPassword: string,
  newPassword: string
) => {
  const url = envMap.authenticationHandler;
  const { data } = await api.put(
    `${url}session/user/password?oldPassword=${encodeURIComponent(
      oldPassword
    )}&newPassword=${encodeURIComponent(newPassword)}`
  );
  return data;
};

/**
 * Creates an image collection.
 */
type CreateCollectionResponse = { collectionId: string };

export const createCollection = async (collectionData: {
  title: string;
  message?: string;
  coverIcon?: string;
  coverColor?: string;
}): Promise<CreateCollectionResponse> => {
  const url = envMap.collectionHandler;
  const { data }: { data: CreateCollectionResponse } = await api.post(
    `${url}collection?collectionMetadata=${encodeURIComponent(
      JSON.stringify(collectionData)
    )}`
  );
  return data;
};

/**
 * Updates public metadata for the specified image collection.
 * @param {object} collectionId - The ID of the collection to update.
 * @param {object} publicData - An object with the updated public metadata the client wants to associate with the collection.
 */
export const updateCollection = async (
  collectionId: string,
  publicData: any
) => {
  const url = envMap.collectionHandler;
  const { data } = await api.put(
    `${url}collection/${encodeURIComponent(
      collectionId
    )}/metadata?collectionMetadata=${encodeURIComponent(
      JSON.stringify(publicData)
    )}`
  );
  return data;
};

/**
 * Informs the system that the user with the specified email address has forgotten their password, initiating an account recovery.
 * @param {string} emailOrPhoneNumber - The email address or phone number for the account to be recovered.
 */
export const requestPasswordReset = async (emailOrPhoneNumber: string) => {
  const url = envMap.authenticationHandler;
  const { data } = await axios.post(
    `${url}user/username/${encodeURIComponent(emailOrPhoneNumber)}/password?`
  );
  return data;
};

/**
 * Continues with the password reset process by verifying ownership of the user's email account and resetting the password for that account.
 * @param {string} emailOrPhoneNumber - The email address for the account to be recovered.
 * @param {string} confirmationCode - The confirmation code from the email the user was sent.
 * @param {string} newPassword - The new password to use for this user.
 */
export const continuePasswordReset = async (
  emailOrPhoneNumber: string,
  confirmationCode: string,
  newPassword: string
) => {
  const url = envMap.authenticationHandler;
  const { data } = await axios.post(
    `${url}user/username/${encodeURIComponent(
      emailOrPhoneNumber
    )}/continue-password?confirmationCode=${encodeURIComponent(
      confirmationCode
    )}&newPassword=${encodeURIComponent(newPassword)}`
  );
  return data;
};

type ListCollectionsResponse = {
  collections: CollectionDataWithAccess[];
  continuation?: string;
};
type CollectionDataWithAccess = {
  id: string;
  metadataLocation: string;
  access: string;
};
/**
 * Lists the collections accessible to the currently-logged-in user.
 * @param {number} itemsPerPage - An optional count of the maximum number of items to return.
 * @param {string} continuation - An optional continuation token that came from a previous call to this function.
 */
export const listUserCollections = async (
  itemsPerPage = 500,
  continuation = ""
): Promise<CollectionListItem[]> => {
  const url = envMap.collectionHandler;
  const urlWithContinuation = continuation
    ? `${url}collection?itemsPerPage=${encodeURIComponent(
        itemsPerPage
      )}&continuation=${encodeURIComponent(continuation)}`
    : `${url}collection?itemsPerPage=${encodeURIComponent(itemsPerPage)}`;
  const { data }: { data: CollectionListResource } = await api.get(
    `${urlWithContinuation}`
  );
  let collections = data.collections as unknown as CollectionListItem[];
  if (data && data.collections) {
    // load the metadata for all the collections in parallel
    collections = (
      await Promise.all(data.collections.map(loadMetadata))
    ).filter(Boolean);
  }
  return collections;
};

const loadMetadata = async (
  collection: CollectionListItemResource
): Promise<CollectionListItem> => {
  try {
    const { data }: { data: CollectionMetadata & { name: string } } =
      await axios.get(collection.metadataLocation);
    return {
      ...collection,
      metadata: {
        ...data,
        title: data.name || data.title || "",
      },
    } as CollectionListItem;
  } catch (e) {
    return null;
  }
};

/**
 * Deletes the currently-authenticated user.
 */
export const deleteUser = async () => {
  const url = envMap.authenticationHandler;
  const { data } = await api.delete(`${url}session/user?`);
  return data;
};

/**
 * Invalidates the current authentication tokens.
 */
export const invalidateTokens = async () => {
  const url = envMap.authenticationHandler;
  const { data } = await api.delete(`${url}session?`);
  return data;
};

/**
 * Uploads a collection metadata cover image.  The collection must be owned by the currently authenticated user.
 * @param {string} collectionId - The ID of the collection being uploaded to.
 * @param {any} file - The file data to upload.
 * @param {function} onUploadProgress callback for progress ticks
 */
export const uploadCollectionMetadataCoverImage = (
  collectionId: string,
  file: File,
  onUploadProgress: (e: AxiosProgressEvent) => void
) => {
  const url = envMap.collectionHandler;
  return api
    .get(
      `${url}collection/${encodeURIComponent(
        collectionId
      )}/metadataImage/urls?contentType=${file.type}`
    )
    .then(({ data }) => {
      return axios.put(data.coverLocation, file, {
        headers: { "Content-Type": file.type },
        onUploadProgress,
      });
    });
};

/**
 * Deletes the all versions of the specified upload (original, watermarked, thumbnail).
 * The logged-in user must be the owner of the collection.
 * @param {string} collectionId - The ID of the collection.
 * @param {string} uploadId - The ID of the upload to delete.
 * @param {string} anonymousOwnershipToken - An optional ownership token from getNewUploadUrl granting access to delete the upload even if no user is authenticated.
 */
export const deleteUpload = async (
  collectionId: string,
  uploadId: string,
  anonymousOwnershipToken?: string
) => {
  const url = envMap.collectionHandler;
  const anonymousOwnershipTokenParam = anonymousOwnershipToken
    ? `&anonymousOwnershipToken=${encodeURIComponent(anonymousOwnershipToken)}`
    : "";
  const caller = anonymousOwnershipToken ? noAuthApi : api;
  const { data } = await caller.delete(
    `${url}collection/${encodeURIComponent(
      collectionId
    )}/upload/${encodeURIComponent(uploadId)}?${anonymousOwnershipTokenParam}`
  );
  return data;
};

/**
 * Deletes the specified collection metadata photo (thumbnail, wallpaper, background).
 * The logged-in user must be the owner of the collection.
 * @param {string} collectionId - The ID of the collection.
 * @param {string} photoId - The ID of the photo to be deleted (thumbnail, wallpaper, background).
 */
export const deleteCollectionMetadataImage = async (
  collectionId: string,
  photoId: string
) => {
  const url = envMap.collectionHandler;
  const { data } = await api.delete(
    `${url}collection/${encodeURIComponent(
      collectionId
    )}/metadataImage/photoId=${encodeURIComponent(photoId)}`
  );
};

type RefreshUserAuthenticationResponse = {
  successfulAuthentication?: AuthenticationComplete;
  moreAuthenticationRequired: MoreAuthenticationRequired;
};
type CollectNewPassword = { token: string };
/**
 * Refreshes the access token by using the refresh token.
 * @param {string} refreshToken - The refresh token originally retrieved when successfully authenticated.
 */
export const refreshUserAuthentication = async (
  refreshToken: string
): Promise<RefreshUserAuthenticationResponse> => {
  const url = envMap.authenticationHandler;
  const { data }: { data: RefreshUserAuthenticationResponse } =
    await axios.post(
      `${url}session-refresh?refreshToken=${encodeURIComponent(refreshToken)}`
    );
  return data;
};

/**
 * Clears all photos from the specified collection, returning a list of the photos that were deleted.
 * The logged-in user must be the owner of the collection.
 * @param {string} collectionId - The ID of the collection.
 */
export const clearCollectionPhotos = async (collectionId: string) => {
  const url = envMap.collectionHandler;
  const { data } = await api.delete(
    `${url}collection/${encodeURIComponent(collectionId)}/upload`
  );
  return data;
};

/**
 * Clears all photos from the specified collection, removes any collection metadata images, removes the collection from the user's collection list, and lastly removes the collection metadata to remove all traces of the collection.
 * The logged-in user must be the owner of the collection.
 * @param {string} collectionId - The ID of the collection.
 */
export const deleteCollection = async (collectionId: string) => {
  const url = envMap.collectionHandler;
  const { data } = await api.delete(
    `${url}collection/${encodeURIComponent(collectionId)}`
  );
  return data;
};

type GetNewUploadResponse = {
  uploadId: string;
  uploadUrl: string;
  metadataUploadUrl: string;
  anonymousOwnershipToken: string;
};

export const getNewUploadUrl = async (
  collectionId: string,
  filename: string,
  contentType: string,
  deviceId: string = null,
  anonymous: boolean = false
): Promise<GetNewUploadResponse> => {
  const url = envMap.collectionHandler ?? null;
  const { data } = await axios.get(
    `${url}collection/${encodeURIComponent(
      collectionId
    )}/uploadurls?filename=${encodeURIComponent(
      filename
    )}&contentType=${contentType}&deviceId=${encodeURIComponent(
      deviceId ?? ""
    )}&anonymous=${anonymous}`
  );
  return data;
};

/**
 * Uploads the specified file to the specified collection.
 * @param {string} collectionId - The ID of the collection to be uploaded to.
 * @param {any} file - The file to be uploaded.
 * @param {any} onUploadProgress - An upload progress callback.
 * @param {boolean} anonymous - Whether the server should ignore *all* information about the upload.
 * @param {string} deviceId - A deviceId to associate with the upload.
 */
export const uploadImage = async (
  collectionId: string,
  file: File,
  onUploadProgress: (event: AxiosProgressEvent) => void,
  anonymous: boolean = false,
  deviceId: string = null
): Promise<GetNewUploadResponse> => {
  try {
    const newUploadResponse = await getNewUploadUrl(
      collectionId,
      file.name,
      file.type,
      deviceId,
      anonymous
    );
    const uploadResponse = await axios.put(newUploadResponse.uploadUrl, file, {
      headers: { "Content-Type": file.type },
      onUploadProgress,
    });
    return newUploadResponse;
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

/**
 * Connects a shared collection to a user's account using the externally-communicated authorization code.
 * @param {string} authorization - The authorization string constructed using getSharingInfo.
 */
export const connectSharedCollection = async (authorization: string) => {
  const url = envMap.collectionHandler ?? null;
  return await api.post(
    `${url}collection/any/sharees?authorization=${encodeURIComponent(
      authorization
    )}`
  );
};

/**
 * Disconnects a user from a shared collection.
 * @param {string} collectionId - The ID of the shared collection to disconnect from.
 * @param {string} userId - The ID of the user to disconnect from the collection.  If specified, the collection must be owned by the authenticated user.  If not specified, the authenticated user is the one disconnecting themselves from the collection.
 */
export const disconnectSharedCollection = async (
  collectionId: string,
  userId: string
) => {
  const url = envMap.collectionHandler ?? null;
  if (userId) {
    return await api.delete(
      `${url}collection/${encodeURIComponent(
        collectionId
      )}/sharees/${encodeURIComponent(userId)}`
    );
  } else {
    return await api.delete(
      `${url}collection/${encodeURIComponent(collectionId)}/sharees`
    );
  }
};

/**
 * @typedef {Object} ShareeData
 * @property {string} userId - The ID of the user that has shared access to this collection.
 * @property {string} name - The name of the user that has shared accedd to this collection.
 */
/**
 * @typedef {Object} GetSharingInfoResponse
 * @property {string} authorization - The authorization code which can be passed into connectSharedCollection to grant a user access to this collection.
 * @property {ShareeData[]} sharees - An array of users with shared accedd to the collection.
 * @property {string} collectionId - The ID of the collection.
 * @property {string} expiration - The expiration time for the authorization code.
 * @property {string} referrerUserId - The ID of the referring user, which should be passed into the user creation if the user doesn't already have an account.
 */
/**
 * Makes a sharing authorization code for the specified collection.  Must be the collection owner.
 * @param {string} collectionId - The ID of the shared collection to disconnect from.
 * @param {number=} secondsToLive - The number of seconds the code should be valid for (defaults to a week).
 * @return {GetSharingInfoResponse}
 */
export const getSharingInfo = async (
  collectionId: string,
  secondsToLive: number = 604800 // 1 week
): Promise<SharingInfo> => {
  const url = envMap.collectionHandler ?? null;
  const { data }: { data: SharingInfo } = await api.get(
    `${url}collection/${encodeURIComponent(
      collectionId
    )}/sharing?secondsToLive=${secondsToLive}`
  );
  return data;
};

/**
 * @typedef {Object} GetOrStartFullZipOfOriginalsResponse
 * @param {string} zipStatusUrl - The URL to the zip status.
 */
/**
 * Triggers creation of a zip file containing all the photos and videos in a collection.
 * @param {object} collectionId - The ID of the collection to be zipped.
 * @returns {GetOrStartFullZipOfOriginalsResponse} - A response with the ID of the zip being generated.
 */
export const getOrStartFullZipOfOriginals = async (collectionId: string) => {
  const url = envMap.collectionHandler;
  const { data } = await api.post(
    `${url}collection/${encodeURIComponent(collectionId)}/zip/full`
  );
  return data;
};

/**
 * @typedef {Object} GetOrStartPartialZipOfOriginalsResponse
 * @param {string} zipStatusUrl - The URL to the zip status.
 */
/**
 * Triggers creation of a zip file containing the original versions of the specified uploads in a collection.
 * @param {object} collectionId - The ID of the collection to be zipped.
 * @param {string[]} uploadIds - The ID's of the photos to be zipped.
 * @returns {GetOrStartPartialZipOfOriginalsResponse} - A response with the ID of the zip being generated.
 */
export const getOrStartPartialZipOfOriginals = async (
  collectionId: string,
  uploadIds: string[]
) => {
  const url = envMap.collectionHandler;
  const { data } = await api.post(
    `${url}collection/${encodeURIComponent(
      collectionId
    )}/zip/partial?uploadIds=${encodeURIComponent(JSON.stringify(uploadIds))}`
  );
  return data;
};

// Gets the user data for the currently-authenticated user.
export const getMyUserInfo = async (): Promise<UserInfo> => {
  const url = envMap.authenticationHandler;
  const { data }: { data: UserInfo } = await api.get(`${url}session/user/info`);
  return data;
};

/**
 * Puts state data for the currently-authenticated user.
 * @param {object} stateData - A state data object which will be attached to the user account.
 * @param {function} onUploadProgress callback for progress ticks
 */
export const putUserStateData = async (
  stateData: object,
  onUploadProgress: () => void
) => {
  const url = envMap.authenticationHandler;
  api.post(`${url}session/user/state/urls`).then(({ data }) => {
    var blob = new Blob([JSON.stringify(stateData)], { type: "text/plain" });
    var file = new File([blob], "state.json", { type: "application/json" });
    const promise = noAuthApi.put(data.putUserStateData, file, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      onUploadProgress,
    });
    // in this case, the caller passed in the filename, so we can just return the promise
    return promise;
  });
};

type GetUserStateDataUrlsResponse = {
  getUserStateData: string; // a URL which can be used to get the user's state data JSON
  putUserStateData: string; // a URL which can be used to put the user's state data JSON
};

/**
 * Gets state data for the currently-authenticated user.
 * @returns {GetUserStateDataUrlsResponse} - A response containing the URLs for the user's state data.
 */
export const getUserStateData = async (): Promise<UserState> => {
  const url = envMap.authenticationHandler;
  const { data } = await api.post(`${url}session/user/state/urls`);
  const userStateData = await noAuthApi.get(data.getUserStateData);
  return userStateData?.data;
};

/**
 * @typedef {Object} TrashedUpload
 * @property {string} uploadId - The ID of the upload within the collection.
 * @property {string} mimeType - The MIME-type of the upload.
 * @property {string} thumbnailUrl - The URL for the upload's thumbnail.
 */
/**
 * @typedef {Object} ListCollectionTrashedUploadsResponse
 * @property {string} continuation - An optional continuation token that may be subsequently passed to this function to get the next set of trashed uploads.  If null, this set is the last one.
 * @property {string} imagesPerPage - The number of images per page returned for these results.
 * @property {TrashedUpload[]} trashedUploads - An array containing information about some of the trashed uploads in the collection.
 */
/**
 * Gets a list of uploads that were previously deleted by the user and put into the trash.
 * @returns {ListCollectionTrashedUploadsResponse} - A response containing information about trashed uploads.
 * @param {string} collectionId - The ID of the collection the uploads are in.
 * @param {string} continuation - An optional continuation from a previous call.
 * @param {number} imagesPerPage - The number of images to return.
 */
export const listCollectionTrashedUploads = async (
  collectionId: string,
  continuation?: string,
  imagesPerPage?: number
) => {
  const url = envMap.collectionHandler;
  const { data }: { data: CollectionResource } = await api.get(
    `${url}collection/${encodeURIComponent(collectionId)}/trash?${
      continuation ? `&continuation=${encodeURIComponent(continuation)}` : ""
    }${imagesPerPage ? `&imagesPerPage=${imagesPerPage}` : ""}`
  );
  return data;
};

/* Restores specific uploads that were previously deleted and whose ID was retrieved using listCollectionTrashedUploads.
 * @param {string} collectionId - The ID of the collection the uploads are in.
 * @param {string[]} uploadIds - An array of upload IDs within that collection that should be restored.
 */
export const restoreTrashedUploads = async (
  collectionId: string,
  uploadIds: string[]
) => {
  const url = envMap.collectionHandler;
  await api.post(
    `${url}collection/${encodeURIComponent(
      collectionId
    )}/trash/restore?uploadIds=${encodeURIComponent(JSON.stringify(uploadIds))}`
  );
};

/**
 * Restores all uploads that were previously deleted in the specified collection.
 * @param {string} collectionId - The ID of the collection the uploads are in.
 */
export const restoreAllTrashedUploads = async (collectionId: string) => {
  const url = envMap.collectionHandler;
  await api.post(
    `${url}collection/${encodeURIComponent(collectionId)}/trash/restoreall`
  );
};

type GetOriginalUploadUrlsResponse = {
  uploadId: string; // The id of the upload.
  mimeType: string; // The mime type of the upload.
  utcUploadTime: string; // The date-time when the upload was uploaded.
  uploadingUserId: string; // The ID of the user that uploaded the upload.
  originalUrl: string; // The URL to display the original upload inline.
  originalDownloadUrl: string; // The URL to download the original upload.
};
export const getOriginalUploadUrls = async (
  collectionId: string, // The ID of the collection.
  uploadId: string, // The ID of the upload whose original version is desired.
  trashed?: boolean // Whether the upload ID is for an item in the trash.  Defaults to false.  If specified, is much slower and may not work with uploads that have not been deleted.
): Promise<GetOriginalUploadUrlsResponse> => {
  const url = envMap.collectionHandler;
  const { data }: { data: GetOriginalUploadUrlsResponse } = await api.get(
    `${url}collection/${encodeURIComponent(
      collectionId
    )}/uploadoriginalurls/${encodeURIComponent(uploadId)}?trashed=${encodeURIComponent(
      trashed ?? false
    )}`
  );
  return data;
};

export const listCollectionUploads = async (
  collectionId: string, // The ID of the collection
  continuation?: string, // An optional continuation token that came from a previous call to this function
  imagesPerPage?: number // An optional number of images per page
): Promise<CollectionUploadListResponse> => {
  const url = urlCat(
    envMap.collectionHandler,
    "collection/:collectionId/upload",
    {
      collectionId,
      continuation,
      imagesPerPage,
    }
  );
  const { data }: { data: CollectionUploadListResponse } = await api.get(url);
  return data;
};

type SignUpUserResponse = { userId: string; assignedPassword?: string };
/**
 * Signs up a user for PicMe.  Must be followed by a call to continueSignUpUser.  If a user hasn't finished that step, checkUser will return a status of UNCONFIRMED and this function will behave as is the account had not been created yet, except that it will not automatically send a new verification code.
 * @param {string} emailOrPhoneNumber - The email address or phone number to attach to the account.
 * @param {string} password - The password for the user.  If not specified, the system will make up a password and return it in the response.  If the account already exists but hasn't been confirmed, the specified password will be set, or a new one will be assigned and the existing user account will be returned without automatically sending a new verification code.
 * @param {boolean} setTosRead - Whether to set the terms of service as read, defaults to false.
 * @param {string=} deviceId - The optional device id being used (generated by the uploader).
 * @param {string=} referrerUserId - The optional id of the referring user.
 * @returns {SignUpUserResponse}
 */
/*
 * @return HTTP 409 Conflict with body object containing code:"UsernameExists" if the user already exists and has been verified.
 */
/*
   * If checkUsername or startAuthentication are called after this call, but before continueSignUpUser, they will return the following:
  {
    "code": "UserNotConfirmed",
  }
  */
export const signUpUser = async (
  emailOrPhoneNumber: string,
  password: string,
  setTosRead?: boolean,
  deviceId?: string,
  referrerUserId?: string
) => {
  const url = envMap.authenticationHandler;
  const { data }: { data: SignUpUserResponse } = await noAuthApi.post(
    `${url}user/username/${encodeURIComponent(
      emailOrPhoneNumber
    )}/signup?password=${encodeURIComponent(
      password
    )}&setTosRead=${encodeURIComponent(
      setTosRead
    )}&deviceId=${encodeURIComponent(
      deviceId ?? ""
    )}&referrerUserId=${encodeURIComponent(referrerUserId)}`
  );
  return data;
};

type ContinueSignUpUserResponse = {};
/**
 * Continues the sign-up process by verifying the communication medium used to sign up.  Must be preceded by signUpUser or resendVerificationCode.  Should be followed by startAuthentication.
 * @param {string} emailOrPhoneNumber - The email address or phone number to attach to the account.
 * @param {string} verificationCode - The verification code sent to the user's email or phone number.
 * @param {boolean} setTosRead - Whether to set the terms of service as read.
 * @param {string} name - The optional name, which can be set either here, or later using setUserAttributes (after authenticating).
 * @returns {ContinueSignUpUserResponse}
 */
export const continueSignUpUser = async (
  emailOrPhoneNumber: string,
  verificationCode: string,
  setTosRead?: boolean,
  name?: string
) => {
  const url = envMap.authenticationHandler;
  const { data }: { data: SignUpUserResponse } = await noAuthApi.post(
    `${url}user/username/${encodeURIComponent(
      emailOrPhoneNumber
    )}/continue-signup?verificationCode=${encodeURIComponent(
      verificationCode
    )}&setTosRead=${encodeURIComponent(setTosRead)}&name=${encodeURIComponent(
      name ? name : ""
    )}`
  );
  return data;
};

type SetUserAttributesResponse = {};
/**
 * Sets user account attributes for the currently-authenticated account.
 * This is the only authenticated API call that can be made if the user account is confirmed, but the terms of service have not been marked as read.
 * @param {string} name - The name to associate with the user account.
 * @param {boolean} setTosRead - Whether to set the terms of service as read.
 * @returns {SetUserAttributesResponse}
 */
export const setUserAttributes = async (
  name: string,
  setTosRead: boolean = true
) => {
  const url = envMap.authenticationHandler;
  const { data }: { data: SignUpUserResponse } = await api.post(
    `${url}session/user/attributes?${
      name ? `name=${encodeURIComponent(name)}&` : ""
    }setTosRead=${encodeURIComponent(setTosRead)}`
  );
  return data;
};

/**
 * Gets information about the specified collection.
 * @param {string} collectionId - The ID of the collection.
 */
export const getCollectionInfo = async (
  collectionId: string
): Promise<GetCollectionInfoResponse & CollectionMetadata> => {
  const url = envMap.collectionHandler;
  const { data }: { data: GetCollectionInfoResponse } = await noAuthApi.get(
    `${url}collection/${encodeURIComponent(collectionId)}`
  );
  const { data: metaData }: { data: CollectionMetadata } = await axios.get(
    data.metadataUrl
  );

  return { id: collectionId, ...data, ...metaData };
};

type Referral = {
  userId: string;
  emailOrPhoneNumber: string;
  name: string;
};

type ListReferralsResponse = { referrals: Referral[] };
/**
 * Lists users referred by the currently logged-in user.  No parameters needed.
 */
export const listReferrals = async () => {
  const url = envMap.listReferrals ?? null;
  const { data }: { data: ListReferralsResponse } = await api.get(`${url}`);
  return data;
};

type UpdateCollectionSharingResponse = { collectionId: string };
/**
 * Sets the sharing metadata for a collection.  Must be the collection owner.
 * @param {string} collectionId - The ID of the shared collection to disconnect from.
 * @param {string} sharingMetadata - A metadata object. Currently, defined properties are 'sharingEnabled', a boolean indicating whether or not viewing photos is allowed by people who have an authorization code generated by makeAuthorizationCode.
 */
export const updateCollectionSharing = async (
  collectionId: string,
  sharingMetadata: any
) => {
  const url = envMap.collectionHandler ?? null;
  return await api.put(
    `${url}collection/${encodeURIComponent(
      collectionId
    )}/sharing/metadata?sharingMetadata=${encodeURIComponent(
      JSON.stringify(sharingMetadata)
    )}`
  );
};

// Uploads a user profile picture for the currently authenticated user.
export const uploadUserProfilePicture = (
  file: File,
  onUploadProgress: (progress: AxiosProgressEvent) => void
) => {
  const url = envMap.authenticationHandler;
  return api
    .post(`${url}session/user/profile-picture/urls?contentType=${file.type}`)
    .then(({ data }) => {
      const promise = axios.put(data.profilePictureLocation, file, {
        headers: { "Content-Type": file.type },
        onUploadProgress,
      });
      // in this case, the caller passed in the filename, so we can just return the promise
      return promise;
    });
};

/**
 * Deletes the profile picture for the authenticated user.
 */
export const deleteUserProfilePicture = async () => {
  const url = envMap.authenticationHandler;
  return await api.delete(`${url}session/user/profile-picture?`);
};

type GetUserProfileInfoResponse = { name: string; profilePicture: string };

/**
 * Gets user profile information for the specified user, which must be the authenticated user or a user the authenticated user referred, or must be a user associated with the specified collection which the authenticated user has access to.
 * @param {string} userId - The ID of the user whose profile information is desired.
 * @param {string} collectionId - The ID of the collection, if the users share access to that collection.  If not specified, the specified user must be the authenticated user, or the authenticated user must have referred the specified user.
 */
export const getUserProfileInfo = async (
  userId: string,
  collectionId?: string
): Promise<GetUserProfileInfoResponse> => {
  const url = envMap.authenticationHandler;
  const { data }: { data: GetUserProfileInfoResponse } = await api.get(
    `${url}user/id/${userId}?${
      collectionId ? `collectionId=${encodeURIComponent(collectionId)}` : ""
    }`
  );
  return data;
};

type GetMultipleOriginalUploadUrlsResponse = {
  uploads: GetOriginalUploadUrlsResponse[]; // Details about listed uploads
  continuation: string; // The continuation token to get more in case the list was limited
};
export const getMultipleOriginalUploadUrls = async (
  collectionId: string, // The ID of the collection.
  uploadIds?: string[], // Optional array of upload IDs to be listed.  If null, all originals will be listed.
  imagesPerPage?: Number, // The number of uploads to list (100 by default).
  continuation?: string, // An optional continuation token to continue listing from where the last call left off.
  trashed?: boolean // Whether the upload IDs are for items in the trash.  Defaults to false.  If specified, is much slower and may not work with uploads that have not been deleted.
): Promise<GetMultipleOriginalUploadUrlsResponse> => {
  const url = envMap.collectionHandler;
  const { data }: { data: GetMultipleOriginalUploadUrlsResponse } =
    await api.get(
      `${url}collection/${encodeURIComponent(
        collectionId
      )}/uploadoriginalurls?${
        uploadIds ? `&uploadIds=${JSON.stringify(uploadIds)}` : ""
      }${imagesPerPage ? `&imagesPerPage=${imagesPerPage}` : ""}${
        continuation ? `&continuation=${continuation}` : ""
      }${trashed ? `&trashed=${encodeURIComponent(trashed ?? false)}` : ""}`
    );
  return data;
};

type CreateDeviceResponse = {
  deviceId: string;
  ownershipToken: string;
};
/**
 * Creates a device.
 */
export const createDevice = async (
  userName?: string,
  userId?: string
): Promise<CreateDeviceResponse> => {
  const url = urlCat(envMap.deviceHandler, "device", { userName, userId });
  const response = await noAuthApi.post(url);
  return response.data;
};

type ReadDeviceResponse = {
  clientIpAddress?: string;
  userAgent?: string;
  userName?: string;
  userId?: string;
};
/**
 * Reads a device.
 */
export const readDevice = async (
  deviceId: string
): Promise<ReadDeviceResponse> => {
  const url = urlCat(envMap.deviceHandler, "device/:deviceId", { deviceId });
  const response = await noAuthApi.get(url);
  return response.data;
};

type PatchDeviceResponse = {
  deviceOwnershipToken: string;
};
/**
 * Updates a device.
 */
export const updateDevice = async (
  deviceId: string,
  ownershipToken: string,
  userName?: string,
  userId?: string
): Promise<PatchDeviceResponse> => {
  const url = urlCat(envMap.deviceHandler, "device/:deviceId", {
    deviceId,
    ownershipToken,
    userName,
    userId,
  });
  return await noAuthApi.patch(url);
};

/**
 * Deletes the specified device information.
 */
export const deleteDevice = async (
  deviceId: string,
  ownershipToken: string
) => {
  const url = urlCat(envMap.deviceHandler, "device/:deviceId", {
    deviceId,
    ownershipToken,
  });
  return await noAuthApi.delete(url);
};
