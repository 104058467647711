import React, { ReactNode, CSSProperties } from "react";
import { useCss, k, a } from "kremling";
import { Logo } from "./logo";
import { useShareSearchParams } from "../hooks/use-share-search-params";
import { Link } from "react-router-dom";
import { Icon } from "../common/icon/icon";
import { useGetCollectionQuery } from "../queries";
import { Button } from "../common/button/button";

type Props = {
  children?: ReactNode;
  showLogoMobile?: boolean;
};

export function BackgroundCard(props: Props) {
  const { children, showLogoMobile, ...rest } = props;
  const { shareParamsObj } = useShareSearchParams();

  const scope = useCss(css);
  return (
    <div {...scope} className="background" {...rest}>
      {shareParamsObj.uploaderCollectionId && (
        <Button
          className="go-back-to-collection"
          as={Link}
          to={`/up/${shareParamsObj.uploaderCollectionId}`}
          iconRight="xmark-regular"
        >
          Close
        </Button>
      )}
      <div className="card">
        <div className={a("background-logo")}>
          <Logo />
        </div>
        {children}
      </div>
    </div>
  );
}

const css = k`
  .background {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 3.2rem 3.2rem 3.2rem;
    padding-bottom: 10rem;
  }
  
  .background-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1.6rem;
    
    svg {
      width: 4rem;
    }
  }

  .card {
    background-color: $grey-100;
    max-width: 45rem;
    flex-shrink: 0;
    width: 100%;
  }
  
  @include breakpoint(lg) {
    .background {
      height: var(--app-height);
      background-color: $side-bar;
      padding-top: 0;
      justify-content: center;
      margin: 0;
    }

    .background-logo {
      display: block;
    }

    .card {
      border: solid .1rem var(--app-border);
      padding: 2.8rem;
      border-radius: $border-radius-xxl;
    }
  }
  
  .go-back-to-collection {
    position: fixed;
    top: 1.6rem;
    right: 1.6rem;
  }
`;
