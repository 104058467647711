import React from "react";
import { useCss, k } from "kremling";
import { ModalHeader } from "../../../common/modal/modal-header";
import { Button } from "../../../common/button/button";

type Props = {
  modalResolve?: (data: any) => void;
};

export function RetryModal(props: Props) {
  const { modalResolve } = props;
  const scope = useCss(css);
  return (
    <div {...scope}>
      <ModalHeader>Uploading error</ModalHeader>
      <p>Looks like an error occurred while trying to upload this image :(</p>
      <p>Would you like to retry?</p>
      <div className="button-group">
        <Button intent="tertiary" block onClick={() => modalResolve(false)}>
          Cancel
        </Button>
        <Button
          intent="primary"
          block
          type="submit"
          onClick={() => modalResolve(true)}
        >
          Retry Upload
        </Button>
      </div>
    </div>
  );
}

const css = k`
  
`;
