import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import isNull from "lodash/isNull";

import { useAppState } from "../app-state";

export function CheckAuth(): any {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const { accessToken, refreshToken } = useAppState([
    "accessToken",
    "refreshToken",
  ]);

  useEffect(() => {
    if (refreshToken) {
      if (accessToken) {
        setIsLoggedIn(true);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [accessToken, refreshToken]);

  if (!isNull(isLoggedIn)) {
    if (isLoggedIn) {
      return <Navigate to="/collection" replace />;
    } else {
      return <Navigate to="/login-or-signup" replace />;
    }
  }
  return null;
}
