import { createDevice, getMyUserInfo, readDevice } from "../../../services/api";
import { appState, UploadFileType } from "../../../app-state";
import { lsDeviceIdKey, lsOwnershipTokenKey } from "../../auth/auth-utils";

export const getError = (value, fieldName, fieldErrors) => {
  return fieldErrors.reduce((error, rule) => {
    if (!error) {
      if (rule === "required" && (!value || value === "")) {
        error = "This field is required.";
      }
    }

    return error;
  }, false);
};

export const updateField = (setVal, setErrors, fieldName, fieldErrors) => {
  return (value) => {
    setVal(value);
  };
};

export const checkForm = (values, config) => {
  return Object.keys(values).reduce((errors, fieldName) => {
    if (!config[fieldName]) return errors;

    const error = getError(values[fieldName], fieldName, config[fieldName]);

    if (error) {
      if (!errors) errors = {};
      errors[fieldName] = error;
    }

    return errors;
  }, null);
};

export const checkHasErrors = (errors) => {
  return errors && Object.keys(errors).length > 0;
};

const API_MESSAGES = {
  AlreadyExists:
    "Email address already in use. Try resetting your password.",
  UsernameExistsException:
    "Email address already in use. Try resetting your password.",
  NotAuthorizedException:
    "Invalid email/password combination.  Check your password and try again.",
  AuthenticationFailed:
    "Invalid email/password combination.  Check your password and try again.",
  InvalidPasswordException:
    "Your password does not meet the security criteria. Please fix and try again.",
};

export const checkApiResponse = (message) => {
  if (API_MESSAGES[message]) {
    return API_MESSAGES[message];
  }

  return "Unknown error occured. Please try again.";
};

export const isVideo = (fileName) => {
  const fName = fileName.toLowerCase();
  return (
    fName.includes(".mov") ||
    fName.includes(".mp4") ||
    fName.includes(".m4a") ||
    fName.includes(".webm")
  );
};

async function createNewDevice(userName, userId) {
  const { deviceId, ownershipToken } = await createDevice(userName, userId);
  localStorage.setItem(lsDeviceIdKey, deviceId);
  localStorage.setItem(lsOwnershipTokenKey, ownershipToken);
  return { deviceId, ownershipToken };
}

export async function uploaderInit() {
  appState.set({ toastBottomOffset: 96 });
  const signedIn = appState.get("signedIn");
  let name, id;
  let deviceId = localStorage.getItem(lsDeviceIdKey);
  let ownershipToken = localStorage.getItem(lsOwnershipTokenKey);

  if (signedIn) {
    try {
      const userInfo = await getMyUserInfo();
      name = userInfo?.userData?.name;
      id = userInfo?.userData?.userId;
    } catch (e) {}
  }

  let runDeviceCreationFn;

  if (deviceId) {
    try {
      // we have a deviceId - is it valid?
      const deviceResponse = await readDevice(deviceId);
      if (!deviceResponse?.userId) throw new Error();
      name = deviceResponse?.userName;
      id = deviceResponse?.userId;
    } catch (e) {
      // if (e.response.status === 404) {
      // not valid - create one
      runDeviceCreationFn = createNewDevice;
      // }
    }
  } else {
    // no deviceId - create one
    runDeviceCreationFn = createNewDevice;
  }

  if (runDeviceCreationFn) {
    const res = await runDeviceCreationFn?.(name, id);
    deviceId = res.deviceId;
    ownershipToken = res.ownershipToken;
  }

  return {
    name,
    id,
    deviceId,
    ownershipToken,
  };
}

export function uploadFilesInMemory_patch(upload, delta) {
  appState.set(({ uploadFilesInMemory }) => ({
    uploadFilesInMemory: {
      [upload.collectionId]: uploadFilesInMemory[upload.collectionId].map(
        (f) => {
          if (f.id === upload.id) {
            return {
              ...f,
              ...delta,
            };
          }
          return f;
        }
      ),
    },
  }));
}

export function uploadFilesInMemory_delete(upload) {
  appState.set(({ uploadFilesInMemory }) => {
    return {
      uploadFilesInMemory: {
        [upload.collectionId]: uploadFilesInMemory[upload.collectionId].filter(
          (f) => f.id !== upload.id
        ),
      },
    };
  });
}
