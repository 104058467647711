import React from "react";
import { ModalBody } from "../common/modal/modal-body";
import { Qr } from "./qr/qr";
import { ModalHeader } from "../common/modal/modal-header";

type Props = {
  url: string;
  title: string;
  collection: Collection;
};

export function QrModal(props: Props) {
  const { url, title, collection } = props;
  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div
          style={{
            paddingBottom: "3.2rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Qr url={url} title={title} collection={collection} />
        </div>
      </ModalBody>
    </>
  );
}
