import React, { useState } from "react";
import { ModalHeader } from "../common/modal/modal-header";
import { ModalBody } from "../common/modal/modal-body";
import { ModalFooter } from "../common/modal/modal-footer";
import { Button } from "../common/button/button";
import { useForm } from "react-hook-form";
import { modalService } from "../common/modal-service/modal-service";
import { InputField } from "../common/input/input-field";
import { changeUserPassword } from "../services/api";
import toast from "react-hot-toast";

type Props = {
  modalResolve: () => void;
};

export function ChangePasswordModal(props: Props) {
  const { modalResolve } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, getValues } = useForm({
    defaultValues: { oldPassword: "", newPassword: "" },
  });

  const submit = ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => {
    modalService.disableModalExit(true);
    changeUserPassword(oldPassword, newPassword)
      .then(() => {
        modalService.disableModalExit(false);
        toast.success("Password successfully changed.");
        modalResolve();
      })
      .catch(() => {
        setIsLoading(false);
        toast.error(
          "Unable to change your password. You can double check your old password and try again."
        );
      });
    setIsLoading(true);
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className="as-modal">
      <ModalHeader>Change password</ModalHeader>
      <ModalBody>
        <div className="text-secondary mb-24">
          To change your password, enter your current password and the new
          password for your account.
        </div>

        <div className="mb-16">
          <InputField
            required
            control={control}
            fieldName="oldPassword"
            placeholder="Old password"
            label="Old password"
            type="password"
            seePassword={!isLoading}
            disabled={isLoading}
          />
        </div>
        <div className="mb-8">
          <InputField
            required
            control={control}
            fieldName="newPassword"
            placeholder="New password"
            label="New password"
            type="password"
            autoComplete="new-password"
            validatePasswordRules
            rules={[
              {
                errorMessage: "Old and new password should not be the same.",
                validate: (v) => {
                  const { oldPassword } = getValues();
                  return v !== oldPassword;
                },
              },
            ]}
            seePassword={!isLoading}
            disabled={isLoading}
          />
        </div>
        <div className="text-secondary text-sm">
          Your password must be at least 8 characters and contain at least 1
          number, 1 uppercase character, 1 lowercase character, and 1 special
          character
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="button-group">
          <Button
            intent="tertiary"
            block
            disabled={isLoading}
            onClick={() => modalResolve()}
          >
            Cancel
          </Button>
          <Button intent="primary" block type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </div>
      </ModalFooter>
    </form>
  );
}
