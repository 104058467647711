import React, { useEffect, useRef, useState } from "react";
import { useGetCollectionInfoQuery } from "../queries";
import { uploadCollectionMetadataCoverImage } from "../services/api";
import { deleteCollectionMetadataImage } from "../services/api";
import { niceToastError } from "../utils/utils";

export function useImgForm(
  collectionId: string,
  type: "cover" | "collection",
  opts?: { saveOnChange?: boolean }
) {
  const collectionInfoQuery = useGetCollectionInfoQuery(collectionId);
  const [isImgSaving, setIsImgSaving] = useState(false);
  const imgElRef = useRef<HTMLInputElement>();
  const fileReaderRef = useRef(new FileReader());

  const [imgUrl, setImgUrl] = useState<string>();
  const [localFile, setLocalFile] = useState<File>();
  const [doSave, setDoSave] = useState(false);

  useEffect(() => {
    setImgUrl(collectionInfoQuery?.data?.coverUrl);
  }, [collectionInfoQuery?.data?.coverUrl]);

  useEffect(() => {
    if (doSave) {
      setDoSave(false);
      imgSave();
    }
  }, [doSave]);

  const handleNextImg = (nextImg: File) => {
    fileReaderRef.current.abort();
    fileReaderRef.current.readAsDataURL(nextImg);
    fileReaderRef.current.onloadend = () => {
      setImgUrl(fileReaderRef.current.result as string);
      setLocalFile(nextImg);

      if (opts?.saveOnChange) {
        setDoSave(true);
      }
    };
  };

  const imgSave = async (passInCollectionId: string = collectionId) => {
    setIsImgSaving(true);
    try {
      await uploadCollectionMetadataCoverImage(
        passInCollectionId,
        localFile,
        ({ progress }) => {}
      );
    } catch (e) {
      niceToastError("unable to save cover image", e);
    }
    setIsImgSaving(false);
  };

  const imgDelete = async () => {
    setIsImgSaving(true);
    try {
      setImgUrl(undefined);
      await deleteCollectionMetadataImage(collectionId, "cover");
    } catch (e) {
      niceToastError("unable to delete cover image", e);
    }
    setIsImgSaving(false);
  };

  const imgChoose = () => {
    if (imgElRef?.current) {
      imgElRef.current.click();
    }
  };

  const imgClearLocal = () => {
    setLocalFile(undefined);
    setImgUrl(undefined);
  };

  return {
    imgUrl,
    imgUrlLoading: !collectionInfoQuery.isFetched,
    imgLocalFile: localFile,
    imgDelete,
    imgClearLocal,
    imgSave,
    isImgSaving,
    imgChoose,
    imgRenderInput: (
      <input
        style={{ display: "none" }}
        ref={imgElRef}
        type="file"
        onChange={(e) => handleNextImg(e.target.files[0])}
      />
    ),
  };
}
