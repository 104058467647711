import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { useCss, k } from "kremling";
import {
  generateUploaderCollectionURI,
  updateCollectionMetadata,
} from "../../utils/url";
import { Qr } from "../../components/qr/qr";
import { updateCollection } from "../../services/api";
import toast from "react-hot-toast";
import { UrlBox } from "../../components/url-box/url-box";
import { OverlayPage } from "../../components/overlay-page/overlay-page";
import { CollectionCover } from "../../components/collection-cover/collection-cover";
import { useImgForm } from "../../hooks/use-image-form";

export const CollectionRequest = () => {
  const scope = useCss(css);
  const { collection } = useOutletContext();
  const { collectionId } = useParams();
  const qrType = collection?.metadata?.qrType;
  const [savingQrType, setSavingQrType] = useState(false);

  const updateQrCode = async (nextQrType) => {
    setSavingQrType(true);

    const [nextMetadata, prevMetadata] = updateCollectionMetadata(
      collectionId,
      {
        qrType: nextQrType,
      }
    );

    try {
      await updateCollection(collectionId, nextMetadata);
    } catch (e) {
      toast.error("Unable to save QR color - we're working on it!");
      updateCollectionMetadata(collectionId, prevMetadata);
    } finally {
      setSavingQrType(false);
    }
  };

  const { imgUrl } = useImgForm(collection.id, "cover");

  return (
    <div {...scope} className="container">
      <OverlayPage title="Request Photos" className="request-overlay">
        {/*<div className="text-secondary mb-12 qr-instructions">*/}
        {/*  Use this QR Code and link to request others to upload photos to this*/}
        {/*  collection.*/}
        {/*</div>*/}

        <div className="qr-code">
          <Qr
            url={generateUploaderCollectionURI(collectionId)}
            qrType={qrType}
            title={`${collection.metadata.title} qr`}
            loading={savingQrType}
            collection={collection}
          >
            {window.showExtra && (
              <div className="cr">
                <CollectionCover
                  coverIcon={collection.metadata.coverIcon}
                  coverColor={collection.metadata.coverColor}
                  coverUrl={imgUrl}
                  size={74}
                />
                <div className="cr-title">{collection.metadata.title}</div>
              </div>
            )}
          </Qr>
        </div>
      </OverlayPage>
    </div>
  );
};

const css = k`
  .container {
    max-width: 55rem;
    width: 100%;
    margin: 0 auto 6rem auto;
  }

  .request-overlay {
    display: flex;
    flex-direction: column;
    height: 100dvh;
  }

  .qr-code {
    display: flex;
    justify-content: center;
  }

  .cr {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: center;
    justify-content: center;
  }
  
  .cr-title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }
`;
