import React, { ComponentProps, forwardRef } from "react";
import { useCss, k, a } from "kremling";
import { Avatar, AvatarProps } from "./avatar";

type Props = AvatarProps & ComponentProps<"button">;

export const AvatarBtn = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { user, size, ...rest } = props;
  const scope = useCss(css);
  return (
    <button
      ref={ref}
      {...scope}
      {...rest}
      className={a("avatar-btn").m("--lg", size === "lg")}
    >
      <Avatar user={user} size="fit" />
    </button>
  );
});

const css = k`
  .avatar-btn {
    position: relative;
    height: 4rem;
    width: 4rem;
    border-radius: $border-radius-full;
    border: none;
    margin: 0;
    padding: 0;
    flex-shrink: 0;

    @include focus-ring {
      border-radius: $border-radius-full;
    }
  }
  
  .--lg {
    height: $form-size-lg;
    width: $form-size-lg;
  }
`;
