import React from "react";
import { useCss, k } from "kremling";
import { useListReferredUsersQuery, useGetUserInfoQuery } from "../../queries";
import { Loader } from "../../common/loader/loader";
import { Input } from "../../common/input/input";
import { Button } from "../../common/button/button";
import { copyToClipboard } from "../../utils/url";
import { modalService } from "../../common/modal-service/modal-service";
import { QrModal } from "../../components/qr-modal";
import { OverlayPage } from "../../components/overlay-page/overlay-page";

export function AccountReferrals() {
  const listReferredUsersQuery = useListReferredUsersQuery();
  const getUserInfoQuery = useGetUserInfoQuery();
  const isLoading =
    listReferredUsersQuery?.isLoading || getUserInfoQuery?.isLoading;
  const numReferrals = (listReferredUsersQuery?.data || []).length;
  const canShare = !!navigator.share;
  const shareUrl = `${location.origin}/refer/${getUserInfoQuery?.data?.userData.userId}`;

  const handleShare = async () => {
    if (navigator.share) {
      navigator
        .share({
          title: `PicMe Invitation`,
          text: `You're invited to join PicMe`,
          url: shareUrl,
        })
        .then(() => {})
        .catch(() => {});
    }
  };

  const openQrModal = () => {
    modalService.render(QrModal, {
      url: shareUrl,
      title: "Referral Link QR Code",
    });
  };

  const scope = useCss(css);
  return (
    <div {...scope} className="account-referrals">
      <OverlayPage title="Referrals">
        <div className="text-secondary text-center mb-12 referral-instructions">
          Use this link to invite your friends to join PicMe
        </div>

        <div className="referrals card">
          <div>Your referrals</div>
          <div className="count">
            {isLoading ? <Loader size="sm" /> : numReferrals}
          </div>
        </div>

        <div className="referral-link card">
          <div>Referral link</div>
          <div className="share-on">
            <Input
              value={isLoading ? "" : shareUrl}
              onClick={(e) => (e.target as HTMLInputElement).select()}
              readOnly
              style={{ paddingRight: canShare ? 122 : 86 }}
              actions={
                isLoading ? null : (
                  <>
                    {canShare && (
                      <Button
                        size="sm"
                        iconOnly="arrow-up-from-bracket-regular"
                        intent="tertiary-flat"
                        onClick={handleShare}
                      />
                    )}
                    <Button
                      size="sm"
                      iconOnly="qrcode-regular"
                      intent="tertiary-flat"
                      onClick={openQrModal}
                    />
                    <Button
                      size="sm"
                      iconOnly="copy"
                      intent="tertiary-flat"
                      onClick={() => copyToClipboard(shareUrl)}
                    />
                  </>
                )
              }
            />
            {isLoading && (
              <div className="code-loading">
                Generating referral link
                <Loader size="sm" />
              </div>
            )}
          </div>
        </div>
      </OverlayPage>
    </div>
  );
}

const css = k`
  .account-referrals {
    max-width: 55rem;
    margin: 0 auto 3.2rem auto;
  }

  .referrals {
    margin-top: 2.3rem;
    color: #000;
    font-size: 1.4rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-item: center;
  }

  .referral-link {
    color: var(--primary-text, #303134);
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 700;
  }

  .card {
    width: 100%;
    border-radius: 1.0925rem;
    border: 1px solid var(--transparent-gray-borders, rgba(25, 25, 27, 0.15));
    background: var(--picme-white-btn-text, #FFF);
    padding: 2.4rem;
  }

  .referrals .count {
    font-size: 1.2rem;
    color: var(--secondary-text, #72737B);
  }

  .share-on {
    position: relative;
    overflow: hidden;
    margin-top: 1.2rem;
  }

  .code-loading {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    position: absolute;
    height: 4.4rem;
    top: 0;
    left: 4.8rem;
    right: 4.8rem;
  }
`;
